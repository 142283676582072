import React, { useEffect, useState } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import "./App.css";
import AppRoutes from "./AppRoutes";
import { SnackbarProvider } from "notistack";
import { AuthProvider } from "./app/services/context/InactiveContext";
import GlobalSnackbar from "./app/main/pages/GlobalSnackbar/SnackbarAlert";
import DraftModal from "./app/main/pages/emailModule/GlobalDraftModal/DraftModal";
import { BgCallPopupProvider } from "./app/services/context/BgCallPopupContext";
import BackgroundCallPopup from "./app/main/components/BackgroundCallPOPUP/BackgroundCallPopup";
import { TwilioProvider } from "./app/services/context/TwilioContext";
import NoDeviceModal from "./app/main/pages/chats/chatList/modal/NoDeviceModal";
import { Notifications } from "react-push-notification";
import { LastSeenMessage } from "./app/services/signalRService/signalRService";
import { useDispatch, useSelector } from "react-redux";
import maxChatLOGO from "./assets/icons/apple-touch-icon.png";
import { setUnreadEmailCounts } from "./app/services/store/slice/EmailSlice";
import { addChatMessage } from "./app/services/api/apiManager";
import {
  setDraftAudioUrl,
  setDraftFiles,
  setDraftInput,
} from "./app/services/store/slice/ChatSlice";
// import GlobalSnackbar from "./app/main/pages/GlobalSnackbar";

function App() {
  const { all_chats } = useSelector((state) => state.chat);
  const dispatch = useDispatch();
  const reduxInput = useSelector((state) => state?.chat?.draftInput);
  const draftFiles = useSelector((state) => state?.chat?.draftFiles);
  const draftAudioUrl = useSelector((state) => state?.chat?.draftAudioUrl);
  const draftAudioBlob = useSelector((state) => state?.chat?.draftAudioBlob);
  const chat_Id = useSelector((state) => state?.chat?.chat_Id);
  const user_id = localStorage.getItem("user_id");
  const unreadEmailCountLocal = localStorage.getItem("unreadEmailCount");

  const EmailCounts = unreadEmailCountLocal
    ? parseInt(unreadEmailCountLocal)
    : unreadEmailCountLocal;

  const allUnreadCounts =
    all_chats?.filter((items) => items?.unreadCount > 0)?.length + EmailCounts;
  const userId = localStorage.getItem("user_id");
  const chatId = localStorage.getItem("chatId");
  // region close browser hit function
  useEffect(() => {
    const handleBeforeUnload = () => {
      LastSeenMessage(userId);
      if (draftAudioUrl != null || reduxInput != "" || draftFiles?.length > 0) {
        AddDraftMessage(chatId);
      }
    };
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  function updateFavicon(count) {
    const favicon = document.querySelector("link[rel~='icon']");
    debugger;
    if (!favicon) return;

    const canvas = document.createElement("canvas");
    const size = 25;

    canvas.width = size;
    canvas.height = size;

    const context = canvas.getContext("2d");
    const img = document.createElement("img");
    img.src = favicon.href;

    img.onload = () => {
      context.clearRect(0, 0, size, size);
      context.drawImage(img, 0, 0, size, size);

      if (count > 0) {
        context.beginPath();
        context.arc(size - 8, 8, 8, 0, 2 * Math.PI);
        context.fillStyle = "red";
        context.fill();

        context.font = "bold 12px Arial";
        context.fillStyle = "white";
        context.textAlign = "center";
        context.textBaseline = "middle";
        context.fillText(count, size - 8, 8.8);
        favicon.href = canvas.toDataURL("image/png");
      } else {
        canvas.remove();
        favicon.href = maxChatLOGO;
      }
    };
  }

  useEffect(() => {
    updateFavicon(allUnreadCounts);
  }, [allUnreadCounts]);

  useEffect(() => {
    // const unreadEmailCount = localStorage.getItem("unreadEmailCount");
    dispatch(setUnreadEmailCounts(unreadEmailCountLocal));
  }, []);
  useEffect(() => {
    if (window.electronAPI) {
      window.electronAPI.sendUnreadCount(allUnreadCounts);
    }
  }, [allUnreadCounts]);
  // ended Count Notification On Favicon
  // region draft messages
  const AddDraftMessage = async (chatId) => {
    try {
      const formData = new FormData();
      formData.append("ConversationId", chatId);
      formData.append("SenderId", user_id);
      formData.append("MessageContent", reduxInput);
      formData.append("IsDraft", true);
      formData.append("IsRead", false);
      formData.append("CreatedBy", user_id);
      if (draftFiles && draftFiles.length > 0) {
        draftFiles.forEach((file, index) => {
          formData.append("Files", file);
        });
      }
      if (draftAudioUrl && draftAudioBlob) {
        const audioFile = new File(
          [draftAudioBlob],
          `audio_${Date.now()}.mp3`,
          {
            type: "audio/mp3",
            lastModified: Date.now(),
          }
        );
        formData.append("Files", audioFile);
      }
      const resp = await addChatMessage(formData);
      if (resp.status === 200) {
        dispatch(setDraftAudioUrl(null));
        dispatch(setDraftInput(""));
        dispatch(setDraftFiles([]));
      }
    } catch (error) {
      console.error("Error in AddDraftMessage:", error);
    }
  };

  return (
    <BgCallPopupProvider>
      <Router>
        {/* for Message Notifications */}
        <SnackbarProvider
          maxSnack={5}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          classes={{
            containerRoot:
              "bottom-0 right-0 mb-52 md:mb-68 mr-8 lg:mr-80 z-100",
          }}
        >
          <Notifications />
          <TwilioProvider>
            {/* <InactiveContext> */}
            <AuthProvider>
              <BackgroundCallPopup />
              <NoDeviceModal />
              <AppRoutes />
            </AuthProvider>
            {/* </InactiveContext> */}
            <GlobalSnackbar />
            <DraftModal />
          </TwilioProvider>
        </SnackbarProvider>
      </Router>
    </BgCallPopupProvider>
  );
}

export default App;
