import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import CloudDownloadOutlinedIcon from "@mui/icons-material/CloudDownloadOutlined";
import FilterListOutlinedIcon from "@mui/icons-material/FilterListOutlined";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import { DataGrid } from "@mui/x-data-grid";
import React, { useEffect, useRef, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { AddIcCall, Delete, MapsUgc } from "@mui/icons-material";
import * as XLSX from "xlsx";
import {
  addChat,
  addChatMessage,
  ExportUserContacts,
  getUserChats,
  getUserContacts,
  getUserContactsByParams,
  updateUserContact,
} from "../../../services/api/apiManager";
import hiEmote from "../../../../assets/images/hiEmote.gif";
import { TRUE } from "sass";
import { enqueueSnackbar, useSnackbar } from "notistack";
import AddcontactModal from "../chats/chatList/modal/AddcontactModal";
import { useDispatch, useSelector } from "react-redux";
import {
  setAllChats,
  setChat_Id,
  setConvoType,
  setLastSeenRealTime,
  setMainContactList,
  setSelectedContactName,
  setSelectedContactProfileImage,
} from "../../../services/store/slice/ChatSlice";
import {
  InvokeContacts,
  SendNewChat,
} from "../../../services/signalRService/signalRService";
import { useTwilio } from "../../../services/context/TwilioContext";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import PhoneIcon from "@mui/icons-material/Phone";
import { setAudioCallStart } from "../../../services/store/slice/CallSlice";

export const Contacts = () => {
  const { handleDial, handleDialForOutboundCall } = useTwilio();
  const username = localStorage.getItem("username");
  const user_id = localStorage.getItem("user_id");
  // region All states
  const [userContacts, setUserContacts] = useState([]);
  const user_Id = localStorage.getItem("user_id");
  const [userName, setUserName] = useState("");
  const [filterField, setFilterField] = useState(false);
  const [contactType, SetContactType] = useState("");
  const [status, setStatus] = useState("");
  const [open, setOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [count, setCount] = useState();
  const [favContact, setFavContact] = useState([]);
  const [loader, setLoader] = useState(false);
  const userId = localStorage.getItem("user_id");
  const { enqueueSnackbar } = useSnackbar();
  const allChats = useSelector((state) => state.chat.all_chats);
  const storeContacts = useSelector((state) => state.chat.mainContactList);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [userChatData, setUserChatData] = useState([]);

  const handleContact = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setUserName("");
    setOpen(false);
    //setAddedContacts(new Set());
  };

  // region paginations
  const [apiPage, setApiPage] = useState({
    inPageNumber: 1,
    inRows: 100,
  });

  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 10,
  });
  const handlePaginationChange = (e) => {
    setPaginationModel({ page: e.page, pageSize: e.pageSize });

    const shouldLoadMoreRows =
      (e.pageSize === 10 && e.page % 9 === 0) ||
      (e.pageSize === 25 && e.page % 3 === 0) ||
      e.pageSize === 50 ||
      e.pageSize === 100;

    if (shouldLoadMoreRows) {
      setApiPage((prev) => ({
        ...prev,
        inRows: prev.inRows + 100,
      }));
    }
  };
  var encodedPhoneNumber = encodeURIComponent(userName);
  // region get contacts
  const ContactData = async (
    User_ID,
    Rows,
    pageNumber,
    isfav,
    isExternal,
    UserName
  ) => {
    try {
      setLoader(true);
      const response = await getUserContactsByParams(
        User_ID,
        Rows,
        pageNumber,
        isfav,
        isExternal,
        UserName
      );
      if (response.status === 200) {
        const userContactData = response?.data?.result;
        setCount(response?.data?.count);
        setUserContacts(userContactData);
        dispatch(setMainContactList(userContactData));
        setLoader(false);
        return response?.data?.result;
      }
    } catch (error) {
      setLoader(false);
      console.error("Error : ", error);
    }
  };
  useEffect(() => {
    ContactData(
      userId,
      apiPage?.inRows,
      apiPage?.inPageNumber,
      "",
      contactType,
      encodedPhoneNumber
    );
  }, [userName, contactType, apiPage, count]);

  const handleDeleteContact = async (contactId) => {
    try {
      const res = await updateUserContact({
        userContactId: contactId,
        modifiedBy: 1,
        isBlocked: false,
        deleted: true,
        CreatedBy: parseInt(userId),
      });
      if (res.status === 200) {
        ContactData(
          userId,
          apiPage?.inRows,
          apiPage?.inPageNumber,
          "",
          contactType,
          encodedPhoneNumber
        );
        enqueueSnackbar("Contact Deleted Succeffully!", {
          variant: "success",
        });
      }
    } catch (error) {
      console.warn("Error fetching chats", error);
    }
  };

  // region Get User Contacts Data to Excel
  const GetUsersContantToExcel = async () => {
    try {
      const response = await ExportUserContacts(parseInt(userId));

      if (response?.status === 200) {
        handleDownload(response?.data?.result?.fileContents);
      }
    } catch (err) {
      console.warn(err);
    }
  };

  // for excel data
  const handleDownload = (fileContentsExcel) => {
    const base64Data = atob(fileContentsExcel);
    const workbook = XLSX.read(base64Data, { type: "binary" });
    const fileName = "file.xlsx";
    XLSX.writeFile(workbook, fileName);
  };

  // region add new Chat
  const handleCreate = async (ContactUserId) => {
    const formData = new FormData();
    formData.append("ConversationType", "Single");
    const user_Id = localStorage.getItem("user_id");
    formData.append("CreatedBy", user_Id);
    if (user_Id) {
      formData.append("Users[0]", user_Id);
    }
    if (user_Id) {
      formData.append("Users[1]", ContactUserId);
    }
    try {
      const response = await addChat(formData);
      if (response.status === 200) {
        // for sending first Hi Emote-msg  automatically!
        const formData = new FormData();
        formData.append(
          "ConversationId",
          response?.data?.result?.chat?.conversationId
        );
        formData.append("SenderId", user_Id);
        formData.append("MessageContent", "Hi!");
        formData.append("IsRead", false);
        formData.append("CreatedBy", user_Id);
        // // Append the static GIF as a File
        const fileResponse = await fetch(hiEmote);
        const gifBlob = await fileResponse.blob(); // Convert imported GIF to a Blob
        const gifFile = new File([gifBlob], "default-image.gif", {
          type: "image/gif",
        });
        formData.append("Files", gifFile);
        await addChatMessage(formData);
        const selectedUsers = [];
        selectedUsers.push(parseInt(user_Id));
        selectedUsers.push(ContactUserId);
        await SendNewChat(
          response?.data?.result?.chat?.conversationId,
          selectedUsers
        );
        InvokeContacts(user_Id, ContactUserId, null);
        const filterUserChat = response?.data?.result?.participants?.filter(
          (data) => data?.userId != user_Id
        );
        dispatch(
          setSelectedContactProfileImage(
            response?.data?.result?.chat?.conversationType !== "Group"
              ? filterUserChat[0]?.participants?.profilePicPath
              : ""
          )
        );
        dispatch(
          setSelectedContactName(
            response?.data?.result?.chat?.conversationType === "Group"
              ? response?.data?.result?.chat?.conversationName
              : filterUserChat[0]?.participants?.name
          )
        );
        dispatch(setConvoType(response?.data?.result?.chat?.conversationType));
        dispatch(setChat_Id(response?.data?.result?.chat?.conversationId));
        localStorage.setItem(
          "otherUserId",
          response?.data?.result?.chat?.conversationParticipants[0]?.userId
        );
        // dispatch(
        //   setLastSeenRealTime({
        //     lastSeen: response?.data?.result?.chat?.lastSeen,
        //   })
        // );
        // localStorage.setItem(
        //   "lastSeen",
        //   response?.data?.result?.chat?.lastSeen
        // );
        localStorage.setItem(
          "chatId",
          response?.data?.result?.chat?.conversationId
        );
        localStorage.setItem(
          "picPath",
          response?.data?.result?.chat?.conversationType !== "Group"
            ? filterUserChat[0]?.participants?.profilePicPath
            : ""
        );
        localStorage.setItem(
          "convoType",
          response?.data?.result?.chat?.conversationType
        );
        localStorage.setItem(
          "selectedContactName",
          response?.data?.result?.chat?.conversationType === "Group"
            ? response?.data?.result?.chat?.conversationName
            : filterUserChat[0]?.participants?.name
        );
        navigate("/chats");
      }

      // handleChatListClick(response?.data?.result?.chat?.conversationId);
    } catch (error) {
      console.warn("error", error);
    }
  };

  //   data grid column
  const columns = [
    { field: "id", headerName: "SERIAL NO" },
    { field: "contactId", headerName: "CONTACT ID", width: 200 },
    {
      field: "name",
      headerName: "NAME",
      flex: 1,
      sortable: true,
      width: 250,
    },
    {
      field: "phoneNumber",
      headerName: "PHONE NUMBER",
      flex: 1,
      width: 250,
    },

    {
      field: "contactType",
      headerName: "CONTACT TYPE",
      flex: 1,
      width: 250,
      renderCell: (params) => {
        return (
          <Box
            display={"flex"}
            justifyContent={"start"}
            alignItems={"center"}
            height={"100%"}
          >
            <Typography
              sx={{
                fontSize: "small",
                fontWeight: 600,
                color:
                  params?.row?.contactType === "Internal" ? "green" : "#085F99",
              }}
            >
              {params?.row?.contactType}
            </Typography>
          </Box>
        );
      },
    },

    {
      field: "departmentName",
      headerName: "Department",
      flex: 0.5,
      align: "center",
      // renderCell: (params) => {
      //   return (
      //     <span
      //       style={{
      //         color: params.value ? "green" : "yellow",
      //         fontWeight: "bold",
      //       }}
      //     >
      //       {params.value ? "Active" : "Inactive"}
      //     </span>
      //   );
      // },
    },
    // region actions
    {
      field: "action",
      headerName: "ACTION",
      flex: 0.5,
      renderCell: (params) => {
        console.log("params?.row", params?.row);

        return (
          <Stack
            direction={"row"}
            sx={{
              width: "100%",
              height: "100%",
              display: "flex",
              justifyContent: "right",
              alignItems: "center",
            }}
          >
            {params?.row?.contactType === "Internal" && (
              <>
                <IconButton
                  onClick={() => {
                    if (params?.row?.contactExistsInChat) {
                      dispatch(
                        setSelectedContactProfileImage(
                          params?.row?.contactExistsInChat?.picPath
                        )
                      );
                      dispatch(
                        setSelectedContactName(
                          params?.row?.contactExistsInChat?.name
                        )
                      );
                      dispatch(
                        setChat_Id(params?.row?.contactExistsInChat?.chatId)
                      );
                      localStorage.setItem("otherUserId", params?.row?.userid);
                      dispatch(setConvoType("Single"));
                      dispatch(
                        setLastSeenRealTime({
                          lastSeen:
                            params?.row?.contactExistsInChat?.lastSeen !== null,
                        })
                      );
                      localStorage.setItem(
                        "lastSeen",
                        params?.row?.contactExistsInChat?.lastSeen
                      );
                      localStorage.setItem(
                        "chatId",
                        params?.row?.contactExistsInChat?.chatId
                      );
                      localStorage.setItem(
                        "picPath",
                        params?.row?.contactExistsInChat?.picPath
                      );
                      localStorage.setItem("convoType", "Single");
                      localStorage.setItem(
                        "selectedContactName",
                        params?.row?.contactExistsInChat?.name
                      );
                      navigate("/chats");
                    } else {
                      handleCreate(params?.row?.userid);
                    }
                  }}
                >
                  {/* sss */}
                  <MapsUgc
                    sx={{ color: "#1565C0 !important", fontSize: "medium" }}
                  />
                </IconButton>

                <IconButton
                  onClick={() => {
                    navigate("/chats");
                    handleDial(
                      params?.row?.userid,
                      username,
                      "inapp",
                      params?.row?.name,
                      params?.row?.profilePic,
                      params?.row?.userid,
                      true,
                      user_id
                    );
                  }}
                >
                  <PhoneIcon
                    sx={{ color: "#1565C0 !important", fontSize: "medium" }}
                  />
                </IconButton>
              </>
            )}

            {params?.row?.contactType === "External" && (
              <IconButton
                onClick={() => {
                  dispatch(setAudioCallStart(true));
                  navigate("/chats");
                  handleDialForOutboundCall(
                    params?.row?.phoneNumber,
                    params?.row?.name,
                    "outbound"
                  );
                }}
              >
                <AddIcCall
                  sx={{ color: "#1565C0 !important", fontSize: "medium" }}
                />
              </IconButton>
            )}
            {/* {params?.row?.contactExistsInChat && (
              <IconButton
                onClick={() => handleDeleteContact(params?.row?.contactId)}
              >
                <PhoneIcon
                  sx={{ color: "#1565C0 !important", fontSize: "medium" }}
                />
              </IconButton>
            )} */}
            <IconButton
              onClick={() => handleDeleteContact(params?.row?.contactId)}
            >
              <Delete sx={{ color: "red !important", fontSize: "medium" }} />
            </IconButton>
          </Stack>
        );
      },
    },
  ];
  console.log("check table", storeContacts);
  const rows = storeContacts?.map((val, index) => {
    const contactExistsInChat = userChatData?.find(
      (items) => items?.otherUserId == val?.userid
    );
    return {
      id: index + 1,
      contactId: `${val?.userContactId}`,
      name:
        `${val?.firstName || ""} ${val?.lastName || ""} ${
          val?.externalPersonName || ""
        }`.trim() || "No Name Available",
      phoneNumber: `${val.externalPersonContactNo || ""}`.trim() || "_",
      contactType: `${val.externalPersonContactNo ? "External" : "Internal"}`,
      // status: `${val.isActive}`,
      departmentName: val?.departmentName,
      userid: val?.userid,
      contactExistsInChat: contactExistsInChat,
      profilePic: val?.profilePic,
    };
  });

  //getUserChatData
  const fetchUserChats = async (userId, isFav) => {
    try {
      const resp = await getUserChats(userId, isFav, false);
      if (resp.status === 200) {
        setUserChatData(resp?.data?.result);
      } else {
        console.log("FetchUserChatData");
      }
    } catch (error) {
      console.warn("Error fetching chats", error);
    }
  };

  useEffect(() => {
    if (user_Id) {
      fetchUserChats(user_Id, "", "");
    }
  }, [user_Id]);
  return (
    <Box sx={{ p: 2, bgcolor: "#F7FAFF", height: "100vh" }}>
      <Box
        padding={"1rem 10px"}
        display={"flex"}
        sx={{
          flexDirection: { md: "row", xs: "column" },
          gap: "10px",
          justifyContent: { md: "space-between", xs: "center" },
          alignItems: { xs: "center" },
          bgcolor: "white",
          border: "1px solid #e4edfc",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
          <Typography variant="h6" fontWeight={600}>
            Contacts
          </Typography>
        </Box>

        <Box>
          <Box
            display={"flex"}
            gap={"0.3rem"}
            sx={{
              flexDirection: { md: "row", xs: "column" },
              "& > :nth-of-type(1)": {
                order: { xs: 2, md: 1 },
              },
              "& > :nth-of-type(2)": {
                order: { xs: 1, md: 2 },
              },
            }}
          >
            {/* all filter field */}
            {filterField && (
              <Box display={"flex"} gap={"0.3rem"}>
                {/* search by name  */}
                <input
                  value={userName}
                  onChange={(e) => {
                    setUserName(e.target.value);
                  }}
                  placeholder="Search Name"
                  size="small"
                  style={{
                    boxShadow: "none",
                    border: "1px solid #00000030",
                    borderRadius: "5px",
                    height: "37px",
                    width: "6.5rem",
                    padding: "5px 10px",
                  }}
                />
                <FormControl
                  variant="standard"
                  sx={{
                    minWidth: 100,
                    mt: "-15px",
                  }}
                >
                  <InputLabel
                    shrink={!!contactType}
                    sx={{
                      ml: "10px",
                      fontSize: "12px",
                      mt: "5px",
                    }}
                    //id="demo-simple-select-standard-label"
                  >
                    Contact Type
                  </InputLabel>
                  <Select
                    value={contactType}
                    onChange={(e) => SetContactType(e.target.value)}
                    sx={{
                      borderLeft: "6px solid #085F99",
                      borderRadius: "7px",
                      "&.MuiInput-underline:before": {
                        borderBottom: "none",
                      },
                      "&.Mui-focused": {
                        "&.MuiInput-underline:before": {
                          borderBottom: "none",
                        },
                      },
                      height: "35px",
                      width: "100px",
                      "& .MuiSelect-select": {
                        fontSize: "12px",
                        ml: "5px",
                      },
                      outline: "1px solid #00000026",
                    }}
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    MenuProps={{
                      PaperProps: {
                        style: {
                          overflowX: "auto",
                          maxHeight: "300px",
                          width: "130px",
                          overflowY: "auto",
                          scrollbarWidth: "thin",
                          scrollbarColor: "dark",
                          msOverflowStyle: "none",
                          WebkitOverflowScrolling: "touch",
                        },
                      },
                    }}
                  >
                    <MenuItem
                      value={""}
                      sx={{
                        fontSize: "13px",
                      }}
                    >
                      Clear filter
                    </MenuItem>
                    <MenuItem
                      value={"false"}
                      sx={{
                        fontSize: "13px",
                      }}
                    >
                      Internal
                    </MenuItem>
                    <MenuItem
                      value={"true"}
                      sx={{
                        fontSize: "13px",
                      }}
                    >
                      External
                    </MenuItem>
                  </Select>
                </FormControl>

                {/* status */}
                <FormControl
                  variant="standard"
                  sx={{
                    minWidth: 100,
                    mt: "-15px",
                  }}
                >
                  <InputLabel
                    shrink={status === true || status === false}
                    sx={{
                      ml: "10px",
                      fontSize: "12px",
                      mt: "5px",
                    }}
                    id="demo-simple-select-standard-label"
                  >
                    Status
                  </InputLabel>
                  <Select
                    value={status}
                    onChange={(e) => setStatus(e.target.value)}
                    sx={{
                      borderLeft: "6px solid #085F99",
                      borderRadius: "7px",
                      "&.MuiInput-underline:before": {
                        borderBottom: "none",
                      },
                      "&.Mui-focused": {
                        "&.MuiInput-underline:before": {
                          borderBottom: "none",
                        },
                      },
                      height: "35px",
                      width: "100px",
                      "& .MuiSelect-select": {
                        fontSize: "12px",
                        ml: "5px",
                      },

                      outline: "1px solid #00000026",
                    }}
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    label="Status"
                    MenuProps={{
                      PaperProps: {
                        style: {
                          overflowX: "auto",
                          maxHeight: "300px",
                          width: "130px",
                          overflowY: "auto",
                          overflowX: "auto",
                          scrollbarWidth: "thin",
                          scrollbarColor: "dark",
                          msOverflowStyle: "none",
                          WebkitOverflowScrolling: "touch",
                        },
                      },
                    }}
                  >
                    <MenuItem
                      value={""}
                      sx={{
                        fontSize: "13px",
                      }}
                    >
                      Clear Filter
                    </MenuItem>
                    <MenuItem
                      value={true}
                      sx={{
                        fontSize: "13px",
                      }}
                    >
                      Active
                    </MenuItem>
                    <MenuItem
                      value={false}
                      sx={{
                        fontSize: "13px",
                      }}
                    >
                      InActive
                    </MenuItem>
                  </Select>
                </FormControl>
              </Box>
            )}
            <Box display={"flex"} gap={"0.3rem"}>
              <Button
                onClick={() => setFilterField(!filterField)}
                size="small"
                sx={{
                  width: "5rem",
                  height: "2.3rem",
                  borderRadius: "8px",
                  fontSize: "12px",
                  fontWeight: "400",
                  fontFamily: "Poppins",
                  boxShadow: "none",
                  color: " #344054",
                  textTransform: "capitalize",
                  bgcolor: "#D7E5EF",
                }}
                startIcon={
                  <FilterListOutlinedIcon
                    sx={{ size: "small", color: " #344054" }}
                  />
                }
              >
                Filter
              </Button>
              {/* csv Export */}
              <Button
                onClick={() => GetUsersContantToExcel()}
                size="small"
                sx={{
                  width: "5rem",
                  height: "2.3rem",
                  borderRadius: "8px",
                  fontSize: "12px",
                  fontWeight: "400",
                  font: "poppen",
                  border: "1px solid #00000026",
                  boxShadow: "none",
                  color: " #344054",
                  textTransform: "capitalize",
                }}
                startIcon={
                  <CloudDownloadOutlinedIcon
                    sx={{ size: "small", color: " #344054" }}
                  />
                }
              >
                Export
              </Button>
              <Button
                onClick={handleContact}
                size="small"
                variant="contained"
                sx={{
                  width: "8rem",
                  bgcolor: "#085F99",
                  height: "2.3rem",
                  borderRadius: "8px",
                  fontSize: "12px",
                  fontWeight: "400",
                  font: "poppen",
                  boxShadow: "none",
                  color: "#FFFFFF",
                  textTransform: "capitalize",
                }}
                startIcon={
                  <AddOutlinedIcon sx={{ size: "small", color: "#FFFFFF" }} />
                }
              >
                Add Contact
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          overflowX: "auto",
          maxWidth: "100%",
          bgcolor: "white",
          height: "600px",
        }}
      >
        <DataGrid
          key={count}
          rows={rows}
          columns={columns}
          getRowId={(row) => row?.id}
          loading={loader === true}
          columnHeaderHeight={28}
          paginationModel={paginationModel}
          onPaginationModelChange={handlePaginationChange}
          rowCount={count}
          pageSizeOptions={[10, 25, 50, 100]}
          disableRowSelectionOnClick
          sx={{
            "& .MuiDataGrid-cell": {
              fontSize: "13px",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              maxWidth: "200px",
            },
            "& .MuiDataGrid-columnHeader": {
              backgroundColor: "#D7E5EF",
              color: "#085F99",
              fontFamily: "Poppins",
              fontWeight: "600",
              fontSize: "15px",
            },
            "& .MuiSvgIcon-root": {
              color: "#8B83BA",
            },
            "& .MuiDataGrid-footerContainer": {
              backgroundColor: "#D7E5EF",
            },
            "& .MuiTablePagination-selectLabel": {
              fontFamily: "Poppins",
              fontWeight: "500",
              fontSize: "15px",
              color: "#085F99",
            },
            "& .MuiInputBase-root": {
              border: "0.5px solid #383838",
              width: "60px",
              height: "30px",
              borderRadius: "10%",
              backgroundColor: "white",
            },
            "& .MuiTablePagination-displayedRows": {
              color: "#085F99",
              fontFamily: "Poppins",
              fontWeight: "500",
              fontSize: "15px",
            },
            "& .MuiTablePagination-actions": {
              color: "#085F99",
            },
            "& .MuiSelect-select": {
              color: "#085F99",
            },
          }}
        />
      </Box>
      <Outlet />
      {open && (
        <AddcontactModal
          open={open}
          handleClose={handleClose}
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
          contactList={storeContacts}
          fetchUserContacts={fetchUserChats}
          handleContacts={handleContact}
        />
      )}
    </Box>
  );
};
