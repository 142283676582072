import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { addChatMessage } from "../../../../services/api/apiManager";
import {
  setDraftAudioUrl,
  setDraftFiles,
  setDraftInput,
} from "../../../../services/store/slice/ChatSlice";

const DraftMessages = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const reduxInput = useSelector((state) => state?.chat?.draftInput);
  const draftFiles = useSelector((state) => state?.chat?.draftFiles);
  const draftAudioUrl = useSelector((state) => state?.chat?.draftAudioUrl);
  const draftAudioBlob = useSelector((state) => state?.chat?.draftAudioBlob);
  const chat_Id = useSelector((state) => state?.chat?.chat_Id);
  const user_id = localStorage.getItem("user_id");
  const chatId = localStorage.getItem("chatId");
  const AddDraftMessage = async (chatId) => {
    try {
      const formData = new FormData();
      formData.append("ConversationId", chatId);
      formData.append("SenderId", user_id);
      formData.append("MessageContent", reduxInput);
      formData.append("IsDraft", true);
      formData.append("IsRead", false);
      formData.append("CreatedBy", user_id);
      if (draftFiles && draftFiles.length > 0) {
        draftFiles.forEach((file, index) => {
          formData.append("Files", file);
        });
      }
      if (draftAudioUrl && draftAudioBlob) {
        const audioFile = new File(
          [draftAudioBlob],
          `audio_${Date.now()}.mp3`,
          {
            type: "audio/mp3",
            lastModified: Date.now(),
          }
        );
        formData.append("Files", audioFile);
      }
      const resp = await addChatMessage(formData);
      if (resp.status === 200) {
        dispatch(setDraftAudioUrl(null));
        dispatch(setDraftInput(""));
        dispatch(setDraftFiles([]));
      }
    } catch (error) {
      console.error("Error in AddDraftMessage:", error);
    }
  };

  useEffect(() => {
    let executed = false;
    if (
      location.pathname !== "/chats" &&
      !executed &&
      (draftAudioUrl != null || reduxInput != "" || draftFiles?.length > 0)
    ) {
      executed = true;
      AddDraftMessage(chatId);
    }
    return () => {
      executed = false;
    };
  }, [location.pathname, chatId]);

  return <div></div>;
};

export default DraftMessages;
