import React, { useEffect } from "react";
import { Box, Typography, Button, Avatar } from "@mui/material";
import CallIcon from "@mui/icons-material/Call";
import CallEndIcon from "@mui/icons-material/CallEnd";
import { useSelector } from "react-redux";
import { settings as s } from "../../../services/Settings";
import { getUsersById, addCallRecords } from "../../../services/api/apiManager";
import addNotification from "react-push-notification";
import dayjs from "dayjs";
import avatarImg from "../../../../assets/img/Avatar.png";
import { useTwilio } from "../../../services/context/TwilioContext";

function CallNotification() {
  const {
    callDuration,
    setCallAccepted,
    activeConnection,
    incomingCall,
    setIncomingCall,
    callStartTime,
  } = useTwilio();
  const { callStatus, dialingNumber } = useSelector((state) => state.call);
  const user_id = localStorage.getItem("user_id");
  const GetCallerName =
    incomingCall?.parameters?.From?.split(":")[1] ||
    incomingCall?.parameters?.From;

  // Get User By Id data
  const [userById, setUserById] = React.useState([]);
  // For Call logs Details API

  const inappPostedData = {
    // conversationId: 1,
    callType: "Single",
    callTitle: incomingCall?.message?.CallerName,
    CreatedBy: parseInt(user_id),
    STARTTIME:
      callDuration == 0
        ? "00:00:00.0000000"
        : dayjs(callStartTime).format("HH:mm:ss.") +
          dayjs(callStartTime).millisecond().toString().padEnd(7, "0"),
    ENDTIME:
      callDuration == 0
        ? "00:00:00.0000000"
        : dayjs(new Date()).format("HH:mm:ss.") +
          dayjs(new Date()).millisecond().toString().padEnd(7, "0"),
    CALLSTATUS: callDuration == 0 ? "Busy" : "Completed",
    DURATION:
      callDuration == 0
        ? 0
        : calculateCallDuration(
            dayjs(new Date()).format("HH:mm:ss.") +
              dayjs(new Date()).millisecond().toString().padEnd(7, "0"),
            dayjs(callStartTime).format("HH:mm:ss.") +
              dayjs(callStartTime).millisecond().toString().padEnd(7, "0")
          ),
    callParticipants: [
      {
        userId: parseInt(user_id),
        joinedAt:
          dayjs(callStartTime).format("HH:mm:ss.") +
          dayjs(callStartTime).millisecond().toString().padEnd(7, "0"),
        callResponse: incomingCall?.direction,
      },
      {
        userId:
          incomingCall?.message?.To ||
          parseInt(incomingCall?.parameters?.From?.split(":")[1]),
        joinedAt:
          dayjs(callStartTime).format("HH:mm:ss.") +
          dayjs(callStartTime).millisecond().toString().padEnd(7, "0"),
        callResponse:
          incomingCall?.direction == "OUTGOING" ? "INCOMING" : "OUTGOING",
      },
    ],
  };

  // second Object For ExternalNumberCalling

  const outboundPostedData = {
    callType: "Single",
    // callTitle: activeConnection?.message?.CallerName,
    callTitle: "nothing",
    CreatedBy: parseInt(user_id),
    STARTTIME:
      callDuration == 0
        ? "00:00:00.0000000"
        : dayjs(callStartTime).format("HH:mm:ss.") +
          dayjs(callStartTime).millisecond().toString().padEnd(7, "0"),
    ENDTIME:
      callDuration == 0
        ? "00:00:00.0000000"
        : dayjs(new Date()).format("HH:mm:ss.") +
          dayjs(new Date()).millisecond().toString().padEnd(7, "0"),
    CALLSTATUS: callDuration == 0 ? "Busy" : "Completed",
    DURATION:
      callDuration == 0
        ? 0
        : calculateCallDuration(
            dayjs(new Date()).format("HH:mm:ss.") +
              dayjs(new Date()).millisecond().toString().padEnd(7, "0"),
            dayjs(callStartTime).format("HH:mm:ss.") +
              dayjs(callStartTime).millisecond().toString().padEnd(7, "0")
          ),
    callParticipants: [
      {
        userId: parseInt(user_id),
        joinedAt:
          dayjs(callStartTime).format("HH:mm:ss.") +
          dayjs(callStartTime).millisecond().toString().padEnd(7, "0"),
        callResponse: incomingCall?.direction,
        ExternalPersonContactNo: dialingNumber,
        CreatedBy: parseInt(user_id),
      },
    ],
  };

  const inboundPostedData = {
    callType: "Single",
    callTitle: "nothing",
    CreatedBy: parseInt(user_id),
    STARTTIME:
      callDuration == 0
        ? "00:00:00.0000000"
        : dayjs(callStartTime).format("HH:mm:ss.") +
          dayjs(callStartTime).millisecond().toString().padEnd(7, "0"),
    ENDTIME:
      callDuration == 0
        ? "00:00:00.0000000"
        : dayjs(new Date()).format("HH:mm:ss.") +
          dayjs(new Date()).millisecond().toString().padEnd(7, "0"),
    CALLSTATUS: callDuration == 0 ? "Busy" : "Completed",
    DURATION:
      callDuration == 0
        ? 0
        : calculateCallDuration(
            dayjs(new Date()).format("HH:mm:ss.") +
              dayjs(new Date()).millisecond().toString().padEnd(7, "0"),
            dayjs(callStartTime).format("HH:mm:ss.") +
              dayjs(callStartTime).millisecond().toString().padEnd(7, "0")
          ),
    callParticipants: [
      {
        userId: parseInt(user_id),
        joinedAt:
          dayjs(callStartTime).format("HH:mm:ss.") +
          dayjs(callStartTime).millisecond().toString().padEnd(7, "0"),
        callResponse: activeConnection?.direction,
        ExternalPersonContactNo: activeConnection?.parameters?.From,
        CreatedBy: null,
      },
    ],
  };
  //region addCallRecords
  const addCallRecordsApi = async () => {
    if (dialingNumber) {
      await addCallRecords(outboundPostedData);
    } else if (
      incomingCall?.direction == "INCOMING" &&
      incomingCall?.parameters?.From?.startsWith("+")
    ) {
      await addCallRecords(inboundPostedData);
    } else {
      await addCallRecords(inappPostedData);
    }
  };

  useEffect(() => {
    const fetchToken = async (incomingCall) => {
      try {
        const response = await getUsersById(
          incomingCall?.parameters?.From?.split(":")[1]
        );
        handleNotifyFun(
          incomingCall?.parameters?.From?.startsWith("+")
            ? incomingCall.parameters.From
            : response?.data?.result[0]
        );
        if (response) {
          setUserById(response?.data?.result[0]);
        }
      } catch (error) {
        console.log(`Error:`, error);
      }
    };
    if (incomingCall || GetCallerName) {
      fetchToken(incomingCall);
    }
  }, [user_id, incomingCall]);
  // Accept incoming call
  const onAccept = async () => {
    if (incomingCall) {
      incomingCall.accept();
    }
  };

  // Reject incoming call
  const onDecline = () => {
    if (incomingCall) {
      incomingCall.reject();
      addCallRecordsApi();
      setIncomingCall(null);
      setCallAccepted(false);
    }
  };

  // handle Notification Function
  const handleNotifyFun = (userById) => {
    let icon;
    if (userById?.profilePicPath) {
      icon = `<img src="${s.baseUrl}${userById.profilePicPath}" alt="${userById.firstName} ${userById.lastName}" style="width: 40px; height: 40px; border-radius: 50%;" />`;
    } else if (userById?.firstName && userById?.lastName) {
      const initials = `${userById.firstName.charAt(
        0
      )}${userById.lastName.charAt(0)}`;
      icon = `<div style="width: 40px; height: 40px; background-color: #4CAF50; color: white; border-radius: 50%; display: flex; align-items: center; justify-content: center; font-size: 16px; font-weight: bold;">${initials}</div>`;
    } else {
      icon = null;
    }

    addNotification({
      title: incomingCall?.parameters?.From?.startsWith("+")
        ? incomingCall.parameters.From
        : userById?.firstName && userById?.lastName
        ? `Incoming call from\n${userById.firstName} ${userById.lastName}`
        : null,
      message: callStatus,
      theme: "dark",
      position: "bottom-left",
      duration: 10000, // +123545454
      icon: userById?.profilePicPath
        ? `${s.baseUrl}${userById.profilePicPath}`
        : userById?.firstName?.charAt(0) && userById?.lastName?.charAt(0)
        ? userById.firstName.charAt(0) + userById.lastName.charAt(0)
        : typeof userById != "object"
        ? avatarImg
        : avatarImg,
      // : userById && userById.charAt(0),
      native: true,
    });
  };

  return (
    <>
      <Box
        sx={{
          position: "fixed",
          top: 16,
          left: "50%",
          transform: "translateX(-50%)",
          width: "80%",
          maxWidth: 300,
          bgcolor: "black",
          color: "#fff",
          borderRadius: 4,
          boxShadow: 3,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          textAlign: "center",
          padding: "10px 20px",
          zIndex: 1300,
          gap: 2,
        }}
      >
        {/* Caller Avatar */}

        <Avatar
          sx={{
            width: 50,
            height: 50,
            mr: 2,
            objectFit: "cover",
          }}
          alt="Profile"
          src={
            userById?.profilePicPath
              ? `${s.baseUrl}${userById?.profilePicPath}`
              : undefined
          }
          loading="lazy"
        >
          {!userById?.profilePicPath &&
            userById?.firstName?.charAt(0) &&
            userById?.lastName?.charAt(0) &&
            userById?.firstName?.charAt(0).toUpperCase() +
              userById?.lastName?.charAt(0).toUpperCase()}
        </Avatar>

        {/* Caller Information */}
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Typography variant="body1" sx={{ fontWeight: "bold" }}>
            Incoming call from
          </Typography>
          <Typography variant="body2" sx={{ opacity: 0.8 }}>
            {/* {userById?.firstName &&
              userById?.lastName &&
              `${userById.firstName} ${userById.lastName}`} */}
            {/* Suleman */}
            {incomingCall?.parameters?.From?.startsWith("+")
              ? incomingCall.parameters.From
              : userById?.firstName && userById?.lastName
              ? `${userById.firstName} ${userById.lastName}`
              : null}
          </Typography>
        </Box>

        {/* Action Buttons */}
        <Box sx={{ display: "flex", gap: 2 }}>
          <Button
            variant="contained"
            color="success"
            startIcon={<CallIcon />}
            onClick={onAccept}
            sx={{
              minWidth: 40,
              padding: "5px 10px",
              backgroundColor: "#4caf50",
              "&:hover": {
                backgroundColor: "#43a047",
              },
            }}
          >
            Accept
          </Button>
          <Button
            variant="contained"
            color="error"
            startIcon={<CallEndIcon />}
            onClick={onDecline}
            sx={{
              minWidth: 40,
              padding: "5px 10px",
              backgroundColor: "#f44336",
              "&:hover": {
                backgroundColor: "#e53935",
              },
            }}
          >
            Decline
          </Button>
        </Box>
      </Box>
    </>
  );
}

export default CallNotification;

const calculateCallDuration = (endTime, startTime) => {
  const durationInMilliseconds = dayjs(`1970-01-01T${endTime}`).diff(
    dayjs(`1970-01-01T${startTime}`)
  );
  return Math.floor(durationInMilliseconds / 1000);
};
