import React, {
  useState,
  useEffect,
  startTransition,
  useLayoutEffect,
} from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import {
  Box,
  Button,
  List,
  ListItem,
  Typography,
  ThemeProvider,
  createTheme,
  Drawer,
  IconButton,
  Menu,
  MenuItem,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import InboxIcon from "@mui/icons-material/Inbox";
import EditNoteOutlinedIcon from "@mui/icons-material/EditNoteOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import StarBorderOutlinedIcon from "@mui/icons-material/StarBorderOutlined";
import SendOutlinedIcon from "@mui/icons-material/SendOutlined";
import FolderOpenRoundedIcon from "@mui/icons-material/FolderOpenRounded";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import { ExpandLess, Padding } from "@mui/icons-material";
import {
  setDraftEmails,
  setEmailId,
  setPendingDraftEmails,
} from "../../../../services/store/slice/ChatSlice";
import { useSnackbar } from "notistack";
import {
  getFolders,
  updateFolders,
  getEmailList,
  getEmailListDraft,
} from "../../../../services/api/apiManager";
import CreateNewFolder from "./CreateNewFolder";
import { useDispatch, useSelector } from "react-redux";
import {
  setRenderFolder,
  setFoldersName,
  setDispatchFoldersId,
  setDefaultEmailShow,
} from "../../../../services/store/slice/ChatSlice";
import {
  setRenderNewEmail,
  setRenderEmailList,
  setRenderDraft,
  setFolderList,
  setIsDraftEmail,
  setEmptyEmails,
  setEmptyEmailInputs,
  setEmailsAllFieldsEmpty,
} from "../../../../services/store/slice/EmailSlice";

function EmailSidebar() {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const location = useLocation();
  const actionType = useSelector((state) => state?.chat?.renderFolder);
  //dispatching draftemails
  const actionTypeDraftEmails = useSelector(
    (state) => state?.chat?.draftEmails
  );

  const renderDraft = useSelector((state) => state?.email?.renderDraft);
  const [openModal, setOpenModal] = useState(false);
  const [fonderData, setFonderData] = useState([]);
  const [folderName, setFolderName] = useState("");
  const [folderId, setFolderId] = useState("");
  // const [renderAfterDeleteFolder, setRenderAfterDeleteFolder] = useState([]);
  const [folderNameSideBar, setFolderNameSideBar] = useState("");
  const [folderIDsideBar, setFolderIDsideBar] = useState(null);
  const [pendingPath, setPendingPath] = useState("");
  const [unread, setUnread] = useState("");
  const [draftCount, setDraftCount] = useState(0);

  const userId = localStorage.getItem("user_id");
  const [selectedCategory, setSelectedCategory] = useState(
    localStorage.getItem("selectedCategory") || "Inbox"
  );
  const [reRender, setRerender] = useState(false);
  const [showEmailFolder, setShowEmailFolder] = useState(true);
  const [showFolder, setShowFolder] = useState(true);
  const [unreadMails, setUnreadMails] = useState(0);
  const renderNewEmail = useSelector((state) => state?.email?.renderNewEmail);
  const renderEmailList = useSelector((state) => state?.email?.renderEmailList);
  const renderFolder = useSelector((state) => state.chat.renderFolder);
  // menu
  const [anchorEl, setAnchorEl] = useState(null);
  const open1 = Boolean(anchorEl);
  const handleClick1 = (event) => {
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  // pages array
  const emailCategories = [
    {
      label: "Inbox",
      icon: <InboxIcon />,
      count: unreadMails,
      route: "inbox",
    },
    { label: "Sent", icon: <SendOutlinedIcon />, route: "sent" },
    {
      label: "Draft",
      icon: <EditNoteOutlinedIcon />,
      count: draftCount,
      route: "draft",
    },
    {
      label: "Favourite",
      icon: <StarBorderOutlinedIcon />,
      route: "favourite",
    },
    { label: "Trash", icon: <DeleteOutlineOutlinedIcon />, route: "trash" },
  ];

  // region get useEmail

  async function getUserEmailList(id, page, row) {
    try {
      const response = await getEmailList(id, page, row);

      setUnread(response.data.unRead);
      if (response?.status === 200) {
        const filterSentEmail = response?.data?.result?.filter((item) => {
          return userId != item?.senderId;
        });
        // unread
        const unreadData = filterSentEmail?.filter(
          (data) => data?.isRead === false || data?.isRead === null
        );
        setUnreadMails(unreadData?.length);
      }
    } catch (error) {
      console.error(error);
    }
  }

  const pendingDraftData = async (UserId, PageNumber, row, IsDraft) => {
    try {
      const response = await getEmailListDraft(
        UserId,
        PageNumber,
        row,
        IsDraft
      );
      if (response.status === 200) {
        const draftMailsCount = response?.data?.count;
        setDraftCount(draftMailsCount);
      } else {
        console.error("Status code is not equal to 200");
      }
    } catch (error) {
      console.error("Error fetching emails:", error);
    }
  };

  useEffect(() => {
    if (renderNewEmail || renderEmailList || renderDraft) {
      pendingDraftData(userId, 1, 10, true);
      getUserEmailList(userId, 1, 100);
      dispatch(setRenderNewEmail(false));
      dispatch(setRenderEmailList(false));
      dispatch(setRenderDraft(false));
    }
  }, [renderNewEmail, renderEmailList, renderDraft]);

  useEffect(() => {
    pendingDraftData(userId, 1, 10, true);
    getUserEmailList(userId, 1, 100);
  }, []);

  // region getFolders
  const getFolder = async (id) => {
    try {
      const response = await getFolders(id);
      if (response?.status === 200) {
        setFonderData(response?.data?.result);
        dispatch(setFolderList(response?.data?.result));
      }
    } catch (error) {
      console.warn(error);
    }
  };

  // region update Folders
  const updateFolder = async (data) => {
    try {
      const response = await updateFolders(data);
      if (response?.status === 200) {
        const renderFolderChangeValue = renderFolder === true ? false : true;
        dispatch(setRenderFolder(renderFolderChangeValue));
        setAnchorEl(null);
        getFolder(userId);
        setOpenModal(false);
        if (data?.Deleted === true) {
          enqueueSnackbar("Folder Deleted Successfully", {
            variant: "success",
          });
        } else {
          enqueueSnackbar("Folder Updated Successfully", {
            variant: "success",
          });
        }
      } else {
        setAnchorEl(null);
        enqueueSnackbar("Error", { variant: "error" });
      }
    } catch (error) {
      console.warn(error);
      setAnchorEl(null);
      setOpenModal(false);
      enqueueSnackbar("Error", { variant: "error" });
    }
  };

  // Dratt Emails Fun
  const [isModalOpen, setIsModalOpen] = useState(false);

  // Function to handle navigation with path check
  const navigateFun = (path) => {
    let hasDraftData = false;
    // Check if EmailType is internal or external and validate respective fields
    if (actionTypeDraftEmails?.EmailType === "Internal") {
      hasDraftData =
        (actionTypeDraftEmails?.RecipentData &&
          actionTypeDraftEmails?.RecipentData.length > 0) ||
        (actionTypeDraftEmails?.RecipentDataCc &&
          actionTypeDraftEmails?.RecipentDataCc.length > 0) ||
        (actionTypeDraftEmails?.RecipentDataBcc &&
          actionTypeDraftEmails?.RecipentDataBcc.length > 0);
    } else if (actionTypeDraftEmails?.EmailType === "External") {
      hasDraftData =
        (actionTypeDraftEmails?.File &&
          actionTypeDraftEmails?.File?.length > 0) ||
        (actionTypeDraftEmails?.RecipentData &&
          actionTypeDraftEmails?.RecipentData.length > 0) ||
        (actionTypeDraftEmails?.RecipentDataCc &&
          actionTypeDraftEmails?.RecipentDataCc.length > 0) ||
        (actionTypeDraftEmails?.RecipentDataBcc &&
          actionTypeDraftEmails?.RecipentDataBcc.length > 0);
    }

    if (location?.pathname != "/emails/newEmail" && hasDraftData) {
      setIsModalOpen(true); // Open modal if there is draft data based on EmailType
      setPendingPath(path);
    } else {
      navigate(path); // Navigate if no draft data
    }
  };

  useLayoutEffect(() => {
    navigateFun(location.pathname);
  }, [location.pathname]);

  useEffect(() => {
    getFolder(userId);
  }, [actionType]);

  useEffect(() => {
    if (location.pathname === "/emails") {
      navigateFun(`/emails/inbox`);
    }
  }, [selectedCategory, navigateFun, location?.pathname]);
  const handleFolderClick = (folderName, folderId) => {
    if (folderNameSideBar !== folderName) {
      localStorage.setItem("selectedFilter", "");
    }
    dispatch(setDefaultEmailShow(true));
    startTransition(() => {
      setSelectedCategory(folderName);
      localStorage.setItem("selectedId", folderId);
      dispatch(setDispatchFoldersId(folderId));
      setFolderIDsideBar(folderId);
      dispatch(setEmailId(0));
      localStorage.setItem("emailIds", 0);
      dispatch(setFoldersName(folderName));
      setOpen(false);
      navigate(`/emails/folder/${folderId}`, {
        state: { selectedValue: "AllMails" },
      });
    });
  };
  // Drawer
  const [open, setOpen] = React.useState(false);

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  const handleClick = (category) => {
    dispatch(setDefaultEmailShow(true));
    localStorage.setItem("emailIds", 0);
    dispatch(setEmailId(0));
    startTransition(() => {
      setSelectedCategory(category?.route);
      localStorage.setItem("selectedCategory", category?.route);
      navigateFun(`/emails/${category?.route}`);
    });
    dispatch(setEmailId(0));
    localStorage.setItem("selectedFilter", "");
  };

  const isActive = (route) => {
    const storedCategory = localStorage.getItem("selectedCategory");
    // Highlight the category if we're in that route or if we’re in /window with the same last category
    if (location.pathname.includes("/window")) {
      return storedCategory === route;
    }
    // Otherwise, highlight based on direct route match
    return location.pathname.includes(route);
  };
  return (
    <>
      <ThemeProvider theme={theme}>
        <Box
          sx={{
            display: { xs: "none", md: "block" },
          }}
        >
          <Button
            fullWidth
            // onClick={() => navigate("/emails/newEmail")}
            onClick={() => {
              navigate("/emails/newEmail");
              // dispatch(setEmptyEmailInputs(true));
              dispatch(setIsDraftEmail(false));
              dispatch(setEmptyEmails(true));
              dispatch(setDraftEmails(null));
              dispatch(setPendingDraftEmails(null));
            }}
            sx={{
              background: "#C3E7FF",
              borderRadius: "7.6px",
              fontSize: "10px",
              color: "#333",
            }}
            startIcon={<AddIcon />}
          >
            New Email
          </Button>
          <Box
            sx={{
              display: "flex",
              marginTop: "10px",
              gap: 2,
              alignItems: "center",
            }}
          >
            <IconButton
              sx={{ padding: "1px" }}
              onClick={() =>
                setShowEmailFolder(showEmailFolder === true ? false : true)
              }
            >
              {showEmailFolder === true ? (
                <ExpandLess sx={{ fontSize: 20, color: "black" }} />
              ) : (
                <ExpandMoreIcon sx={{ fontSize: 20, color: "black" }} />
              )}
            </IconButton>

            <Typography sx={{ fontSize: "12px" }} fontWeight={"bold"}>
              All Emails
            </Typography>
          </Box>
          <List
            sx={{
              p: 1,
              display: showEmailFolder === true ? "block" : "none",
            }}
          >
            {emailCategories?.map((category, index) => (
              <ListItem
                key={index}
                onClick={() => handleClick(category)}
                sx={{
                  display: "flex",
                  gap: 1,
                  p: 1,
                  fontSize: "18px",
                  alignItems: "center",
                  borderRadius: "4px",
                  background: isActive(category?.route) ? "#c2d7f0" : "#fff",
                  "&:hover": {
                    background: isActive(category?.route) ? "#c2d7f0" : "#fff",
                  },
                  cursor: "pointer",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    {React.cloneElement(category?.icon, {
                      sx: { color: "#212121" },
                    })}
                    <Typography variant="subtitle2" sx={{ marginLeft: 1 }}>
                      {category?.label}
                    </Typography>
                  </Box>
                  {category?.label === "Inbox" && unreadMails > 0 && (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        backgroundColor: "#085f99",
                        fontSize: "12px",
                        color: "white",
                        minWidth: "23px",
                        height: "23px",
                        borderRadius: "38px",
                        padding: "3px",
                      }}
                    >
                      {unreadMails > 99 ? 99 + "+" : unreadMails}
                    </Box>
                  )}

                  {category?.label === "Draft" && draftCount > 0 && (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        backgroundColor: "#085f99",
                        fontSize: "12px",
                        color: "white",
                        minWidth: "23px",
                        height: "23px",
                        borderRadius: "38px",
                        padding: "3px",
                      }}
                    >
                      {draftCount > 99 ? 99 + "+" : draftCount}
                    </Box>
                  )}
                </Box>
              </ListItem>
            ))}
          </List>

          <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
            <IconButton
              sx={{ padding: "1px" }}
              onClick={() => setShowFolder(showFolder === true ? false : true)}
            >
              {showFolder === true ? (
                <ExpandLess sx={{ fontSize: 20, color: "black" }} />
              ) : (
                <ExpandMoreIcon sx={{ fontSize: 20, color: "black" }} />
              )}
            </IconButton>
            <Typography
              //variant="subtitle2"
              sx={{ fontSize: "12px" }}
              fontWeight={"bold"}
            >
              All Folders
            </Typography>
          </Box>
          <List
            sx={{
              display: showFolder === true ? "block" : "none",
              fontFamily: "Poppins",
              p: 1,
              maxHeight: { xl: "380px", md: "250px", xs: "200px" },
              overflowY: "auto",
            }}
          >
            {fonderData?.map((category) => {
              const currentPath = `/emails/folder/${category?.folderId}`;

              return (
                <ListItem
                  key={category?.folderId}
                  onClick={() => {
                    handleFolderClick(category?.folderName, category?.folderId);
                    localStorage.setItem("emailIds", 0);
                    dispatch(setEmailId(0));
                  }}
                  sx={{
                    display: "flex",
                    gap: 1,
                    p: 1,
                    fontSize: "18px",
                    alignItems: "center",
                    borderRadius: "4px",
                    background:
                      location.pathname === currentPath ? "#c2d7f0" : "#fff",
                    "&:hover": {
                      background:
                        location.pathname === currentPath
                          ? "#c2d7f0"
                          : "#f5f5f5",
                    },
                    cursor: "pointer",
                  }}
                >
                  <Box
                    sx={{ display: "flex", alignItems: "center" }}
                    onContextMenu={(e) => {
                      handleClick1(e);
                      setFolderId(category?.folderId);
                      setFolderNameSideBar(category?.folderName);
                    }}
                  >
                    <FolderOpenRoundedIcon />
                    <Typography variant="subtitle2" sx={{ marginLeft: 1 }}>
                      {category?.folderName}
                    </Typography>
                  </Box>
                </ListItem>
              );
            })}
          </List>

          <Button
            onClick={() => {
              setOpenModal(true);
              setFolderId(null);
              setFolderName("");
              setFolderNameSideBar("");
            }}
            sx={{ textTransform: "capitalize", fontSize: "13px" }}
          >
            Create new folder
          </Button>
        </Box>
        <div>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <IconButton
              sx={{
                display: { xs: "block", md: "none" },
                color: "black",
                marginTop: "4px",
              }}
              onClick={toggleDrawer(true)}
            >
              <MenuOutlinedIcon />
            </IconButton>

            <Button
              fullWidth
              onClick={() => {
                dispatch(setEmptyEmailInputs(true));
                dispatch(setDraftEmails(null));
                dispatch(setEmptyEmails(false));
                navigate("/emails/newEmail");
              }}
              sx={{
                background: "#C3E7FF",
                borderRadius: "7.6px",
                fontSize: "8px",
                color: "#333",
                border: "1px solid #00000026",
                width: "130px",
                display: { xs: "flex", md: "none" },
              }}
              startIcon={<AddIcon />}
            >
              New Email
            </Button>
          </Box>
          {/* drawer */}
          <Drawer
            open={open}
            onClose={toggleDrawer(false)}
            PaperProps={{
              sx: { width: 150, paddingTop: "10px" },
            }}
          >
            <Box>
              <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
                <ExpandMoreIcon fontSize="large" />
                <Typography variant="subtitle2" fontWeight={"bold"}>
                  All Emails
                </Typography>
              </Box>

              <List>
                {emailCategories?.map((category, index) => (
                  <ListItem
                    key={index}
                    onClick={() => handleClick(category)}
                    sx={{
                      display: "flex",
                      gap: 1,
                      p: 0.5,
                      alignItems: "center",
                      borderRadius: "4px",
                      background:
                        selectedCategory === category.label
                          ? "#c2d7f0"
                          : "#fff",
                      "&:hover": {
                        background:
                          selectedCategory === category.label
                            ? "#c2d7f0"
                            : "#f5f5f5",
                      },
                      cursor: "pointer",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        width: "100%",
                      }}
                    >
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        {React.cloneElement(category?.icon, {
                          sx: { color: "#212121" },
                        })}
                        <Typography variant="subtitle2" sx={{ marginLeft: 1 }}>
                          {category?.label}
                        </Typography>
                      </Box>
                      {category?.label === "Inbox" && (
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            backgroundColor: "#085f99",
                            fontSize: "12px",
                            color: "white",
                            minWidth: "20px",
                            height: "20px",
                            borderRadius: "38px",
                            padding: "1px",
                          }}
                        >
                          {/* {category?.count > 99 ? 99 + "+" : category?.count} */}
                          {unread}
                        </Box>
                      )}
                    </Box>
                  </ListItem>
                ))}
              </List>

              <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
                <ExpandMoreIcon fontSize="large" />
                <Typography variant="subtitle2" fontWeight={"bold"}>
                  All Folders
                </Typography>
              </Box>

              <List
                sx={{
                  fontFamily: "Poppins",
                  p: 1,
                  maxHeight: "450px",
                  overflowY: "auto",
                }}
              >
                {fonderData?.map((category) => (
                  <ListItem
                    key={category?.label}
                    onClick={() => {
                      handleFolderClick(
                        category?.folderName,
                        category?.folderId
                      );
                      localStorage.setItem("selectedFilter", "");
                      localStorage.setItem("emailIds", 0);
                      dispatch(setEmailId(0));
                    }}
                    sx={{
                      display: "flex",
                      gap: 1,
                      p: 0.5,
                      alignItems: "center",
                      borderRadius: "4px",
                      background:
                        selectedCategory === category?.folderName
                          ? "#c2d7f0"
                          : "#fff",
                      "&:hover": {
                        background:
                          selectedCategory === category?.folderName
                            ? "#c2d7f0"
                            : "#f5f5f5",
                      },
                      cursor: "pointer",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <FolderOpenRoundedIcon />
                      <Typography variant="subtitle2" sx={{ marginLeft: 1 }}>
                        {category?.folderName}
                      </Typography>
                    </Box>
                  </ListItem>
                ))}
              </List>

              <Button
                onClick={() => setOpenModal(true)}
                sx={{ textTransform: "capitalize", fontSize: "12px" }}
              >
                Create new folder
              </Button>
            </Box>
          </Drawer>
          {/* create new folder Modal */}
          <CreateNewFolder
            openModal={openModal}
            setOpenModal={setOpenModal}
            folderId={folderId}
            folderNameSideBar={folderNameSideBar}
            setFolderNameSideBar={setFolderNameSideBar}
            renderFromParent={setRerender}
          />
          {/* menu */}
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open1}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <MenuItem
              onClick={() => {
                handleClose();
                setOpenModal(true);
              }}
            >
              Rename
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleClose();
                updateFolder({ FolderId: folderId, Deleted: true });
                navigate("/emails/inbox");
              }}
              sx={{ color: "red" }}
            >
              Delete
            </MenuItem>
          </Menu>
        </div>
      </ThemeProvider>
    </>
  );
}

export default EmailSidebar;

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  // border: "2px solid #000",
  // boxShadow: 24,
  p: 2,
};

const theme = createTheme({
  typography: {
    fontFamily: "Poppins, sans-serif",
    fontSize: "10px",
  },
});
