import React, { useState } from "react";
import { BgCallPopupContext } from "../../../services/context/BgCallPopupContext";
import { useLocation, useNavigate } from "react-router-dom";
import { Avatar, Box } from "@mui/material";
import { settings as s } from "../../../services/Settings";
import { useDispatch, useSelector } from "react-redux";
import CallEndIcon from "@mui/icons-material/CallEnd";
import MicIcon from "@mui/icons-material/Mic";
import MicOffIcon from "@mui/icons-material/MicOff";
import IconButton from "@mui/material/IconButton";
import { addCallRecords } from "../../../services/api/apiManager";
import dayjs from "dayjs";
import {
  setAudioCallStart,
  setCallRunningInBG,
  setIsMuted,
  setIsReceiveMuted,
  setIsReceiveRecording,
  setShowChatSectionDuringCall,
} from "../../../services/store/slice/CallSlice";
import MicOffRoundedIcon from "@mui/icons-material/MicOffRounded";
import MicRoundedIcon from "@mui/icons-material/MicRounded";
import { SendMute } from "../../../services/signalRService/signalRService";
import { useTwilio } from "../../../services/context/TwilioContext";

const BackgroundCallPopup = () => {
  const { setIsCallActive } = BgCallPopupContext();
  const {
    callDuration,
    setCallDuration,
    setCallAccepted,
    activeConnection,
    setActiveConnection,
    setCallStatus,
    callStartTime,
  } = useTwilio();
  const { userById, callerName, contactImage, isMuted } = useSelector(
    (state) => state.call
  );
  const dispatch = useDispatch();
  const { isCallActive } = BgCallPopupContext();
  const [position, setPosition] = useState({ x: 150, y: 20 });
  const navigator = useNavigate();
  const currentLocation = useLocation();
  const handleDrag = (e) => {
    setPosition({ x: e.clientX, y: e.clientY });
  };

  // Format duration to MM:SS
  const formatDuration = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${String(minutes).padStart(2, "0")}:${String(secs).padStart(
      2,
      "0"
    )}`;
  };

  const user_id = localStorage.getItem("user_id");
  const currentPCTimeWithAPIFORMAT =
    dayjs().format("HH:mm:ss.") +
    dayjs().millisecond().toString().padEnd(7, "0");
  // Send Call-End Data
  const activeConnData = {
    // conversationId: 1,
    callType: "Single",
    callTitle: activeConnection?.message?.CallerName,
    CreatedBy: parseInt(user_id),
    STARTTIME:
      callDuration == 0
        ? "00:00:00.0000000"
        : dayjs(callStartTime).format("HH:mm:ss.") +
          dayjs(callStartTime).millisecond().toString().padEnd(7, "0"),
    ENDTIME:
      callDuration == 0
        ? "00:00:00.0000000"
        : dayjs(new Date()).format("HH:mm:ss.") +
          dayjs(new Date()).millisecond().toString().padEnd(7, "0"),
    CALLSTATUS: callDuration == 0 ? "Busy" : "Completed",
    DURATION:
      callDuration == 0
        ? 0
        : calculateCallDuration(
            dayjs(new Date()).format("HH:mm:ss.") +
              dayjs(new Date()).millisecond().toString().padEnd(7, "0"),
            dayjs(callStartTime).format("HH:mm:ss.") +
              dayjs(callStartTime).millisecond().toString().padEnd(7, "0")
          ),
    callParticipants: [
      {
        userId: parseInt(user_id),
        joinedAt:
          dayjs(callStartTime).format("HH:mm:ss.") +
          dayjs(callStartTime).millisecond().toString().padEnd(7, "0"),
        callResponse: activeConnection?.direction,
      },
      {
        userId:
          activeConnection?.message?.To ||
          parseInt(activeConnection?.parameters?.From?.split(":")[1]),
        joinedAt:
          dayjs(callStartTime).format("HH:mm:ss.") +
          dayjs(callStartTime).millisecond().toString().padEnd(7, "0"),
        callResponse:
          activeConnection?.direction == "OUTGOING" ? "INCOMING" : "OUTGOING",
      },
    ],
  };

  //region addCallRecords
  const addCallRecordsApi = async () => {
    const res = await addCallRecords(activeConnData);
  };

  // onCalEnd Function
  const onEndCall = () => {
    if (activeConnection) {
      activeConnection.disconnect();
      setCallStatus("Call Ended");
      setCallAccepted(false);
      setCallDuration(0);
      setActiveConnection(null);
      setIsCallActive(false);
    }
    dispatch(setAudioCallStart(false));
    dispatch(setShowChatSectionDuringCall(false));
    dispatch(setCallRunningInBG(false));
    dispatch(setIsReceiveMuted([]));
    dispatch(setIsReceiveRecording([]));
    setCallDuration(0);
    setCallStatus("");
    addCallRecordsApi();
  };

  const onToggleMute = () => {
    if (isMuted === false) {
      dispatch(setIsMuted(true));
      SendMute(
        activeConnection?.message?.To === undefined
          ? parseInt(
              activeConnection?.parameters?.From.replace("client:", ""),
              10
            )
          : activeConnection?.message?.To,
        parseInt(user_id),
        true
      );
      activeConnection.mute(true);
    } else {
      dispatch(setIsMuted(false));
      SendMute(
        activeConnection?.message?.To === undefined
          ? parseInt(
              activeConnection?.parameters?.From.replace("client:", ""),
              10
            )
          : activeConnection?.message?.To,
        parseInt(user_id),
        false
      );
      activeConnection.mute(false);
    }
  };

  return (
    <Box
      draggable
      onDrag={handleDrag}
      onDragEnd={(e) => {
        setPosition({ x: e.clientX, y: e.clientY });
      }}
      style={{
        display:
          isCallActive === false
            ? "none"
            : isCallActive && currentLocation.pathname === "/chats"
            ? "none"
            : isCallActive && currentLocation.pathname === "/meetings"
            ? "none"
            : "block",
        position: "absolute",
        left: position.x,
        top: position.y,
        width: "16%",
        height: "20vh",
        backgroundColor: "#081C27",
        cursor: "move",
        zIndex: 999,
      }}
    >
      <Box>
        <Box
          width={"95%"}
          height={"30px"}
          display={"flex"}
          justifyContent={"flex-end"}
        >
          {/* <span style={{ color: "red", fontWeight: "bold", cursor: "pointer" }}>
            X
          </span> */}
        </Box>

        <Avatar
          sx={{
            fontSize: "30px",
            fontWeight: "bold",
            cursor: "pointer",
            margin: "auto",
            width: { xs: 40, sm: 40 },
            height: { xs: 40, sm: 40 },
            objectFit: "cover",
          }}
          alt="Profile"
          src={
            userById?.profilePicPath
              ? `${s.baseUrl}${userById?.profilePicPath}`
              : `${s.baseUrl}${contactImage}`
          }
          loading="lazy"
        >
          {!userById?.profilePicPath &&
          userById?.firstName &&
          userById?.firstName?.charAt(0) &&
          userById?.lastName &&
          userById?.lastName?.charAt(0)
            ? userById?.firstName &&
              userById?.firstName.charAt(0) + userById?.lastName &&
              userById?.lastName.charAt(0)
            : userById?.callerName &&
              callerName.charAt(0) + callerName.charAt(1)}
        </Avatar>

        <p style={{ textAlign: "center", color: "wheat" }}>
          Call with{" "}
          {userById?.firstName && userById?.lastName
            ? `${userById.firstName} ${userById.lastName}`
            : callerName}
        </p>
        <p style={{ textAlign: "center", color: "wheat", fontSize: "12px" }}>
          {formatDuration(callDuration)}
        </p>

        <Box
          width={"100%"}
          height={"30px"}
          display={"flex"}
          justifyContent={"space-around"}
        >
          {/* <IconButton
            aria-label="end call"
            size="large"
            sx={{
              backgroundColor: "#f5f5f5",
              border: "1px solid #ddd",
              borderRadius: "8px",
              boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
              transition: "all 0.3s ease",
              "&:hover": {
                backgroundColor: "#e0e0e0",
                boxShadow: "0px 6px 8px rgba(0, 0, 0, 0.2)",
              },
              "& .MuiSvgIcon-root": {
                color: "#03A3FF",
                fontSize: "28px",
              },
              p: "0px",
            }}
          >
            <MicIcon />
          </IconButton> */}
          <IconButton
            color="primary"
            onClick={onToggleMute}
            sx={{
              "&:hover": {
                boxShadow: "none",
                backgroundColor: "transparent",
              },
              "&.Mui-focusVisible": {
                boxShadow: "none",
              },
            }}
          >
            {isMuted === true ? (
              <Box
                sx={{
                  borderRadius: "50%",
                  width: 33,
                  height: 33,
                  border: "1px solid #1E1F24",
                  backgroundColor: "#1E1F24",
                  overflow: "hidden",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <MicOffRoundedIcon
                  style={{ width: 23, height: 23, color: "#818090" }}
                />
              </Box>
            ) : (
              <Box
                sx={{
                  borderRadius: "50%",
                  width: 33,
                  height: 33,
                  border: "1px solid #1E1F24",
                  backgroundColor: "#1E1F24",
                  overflow: "hidden",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <MicRoundedIcon
                  style={{ width: 23, height: 23, color: "#03A3FF" }}
                />
              </Box>
            )}
          </IconButton>
          <button
            onClick={() => {
              navigator("/chats");
            }}
            style={{
              width: "80px",
              height: "30px",
              backgroundColor: "wheat",
              color: "black",
              borderRadius: "5px",
              cursor: "pointer",
            }}
          >
            Go To Call
          </button>
          <IconButton
            color="error"
            aria-label="end call"
            size="large"
            sx={{
              backgroundColor: "#ff4d4f",
              color: "#fff",
              border: "none",
              borderRadius: "8px",
              boxShadow: "0px 4px 8px rgba(255, 77, 79, 0.3)",
              transition: "all 0.3s ease",
              "&:hover": {
                backgroundColor: "#e04143",
                boxShadow: "0px 6px 12px rgba(255, 77, 79, 0.5)",
              },
              "& .MuiSvgIcon-root": {
                fontSize: "28px",
              },
              p: "0 6px",
            }}
            onClick={onEndCall}
          >
            <CallEndIcon />
          </IconButton>
        </Box>
      </Box>
    </Box>
  );
};

export default BackgroundCallPopup;

const calculateCallDuration = (endTime, startTime) => {
  const durationInMilliseconds = dayjs(`1970-01-01T${endTime}`).diff(
    dayjs(`1970-01-01T${startTime}`)
  );
  return Math.floor(durationInMilliseconds / 1000);
};
