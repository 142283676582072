import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isOnCall: false,
  caller: null,
  disconnectCall: null,
  callInterval: null,
  clearIncomingCall: false,
  callRunningInBG: false,
  audioCallStart: false,
  showChatSectionDuringCall: false,
  callerName: "",
  userById: null,
  contactImage: null,
  isReceiveMuted: [],
  isReceiveRecording: [],
  groupCallIds: [],
  isShareScreen: [],
  isSendingRoomData: [],
  room: null,
  participants: [],
  mainParticipant: null,
  isMuted: false,
  dialingNumber: "",
  callOpen: false,
  dialOpen: false,
  callLogsDetailsDispatch: false,
};

const callSlice = createSlice({
  name: "call",
  initialState,
  reducers: {
    setClearIncomingCall: (state, action) => {
      state.clearIncomingCall = action.payload;
    },
    setCaller: (state, action) => {
      state.caller = action.payload;
    },
    setDisconnectCall: (state, action) => {
      state.disconnectCall = action.payload;
    },
    setCallInterval: (state, action) => {
      state.callInterval = action.payload;
    },
    setIsOnCall: (state, action) => {
      state.isOnCall = action.payload;
    },
    setCallRunningInBG: (state, action) => {
      state.callRunningInBG = action.payload;
    },
    setAudioCallStart: (state, action) => {
      state.audioCallStart = action.payload;
    },
    setShowChatSectionDuringCall: (state, action) => {
      state.showChatSectionDuringCall = action.payload;
    },
    setUserById: (state, action) => {
      state.userById = action.payload;
    },
    setCallerName: (state, action) => {
      state.callerName = action.payload;
    },
    setContactImage: (state, action) => {
      state.contactImage = action.payload;
    },
    setIsReceiveMuted: (state, action) => {
      state.isReceiveMuted = action.payload;
    },
    setIsReceiveRecording: (state, action) => {
      state.isReceiveRecording = action.payload;
    },
    setgroupCallIds: (state, action) => {
      state.groupCallIds = action.payload;
    },
    setIsShareScreen: (state, action) => {
      state.isShareScreen = action.payload;
    },

    setIsSendingRoomData: (state, action) => {
      state.isSendingRoomData = action.payload;
    },
    setRoom: (state, action) => {
      state.room = action.payload;
    },
    setParticipants: (state, action) => {
      state.participants = action.payload;
    },
    setMainParticipant: (state, action) => {
      state.mainParticipant = action.payload;
    },
    setIsMuted: (state, action) => {
      state.isMuted = action.payload;
    },
    setDialingNumber: (state, action) => {
      state.dialingNumber = action.payload;
    },
    setDialOpen: (state, action) => {
      state.dialOpen = action.payload;
    },
    setCallOpen: (state, action) => {
      state.callOpen = action.payload;
    },
    setCallLogsDetailsDispatch: (state, action) => {
      state.callLogsDetailsDispatch = action.payload;
    },
  },
});

export const {
  setClearIncomingCall,
  setCaller,
  setDisconnectCall,
  setCallInterval,
  setIsOnCall,
  setCallRunningInBG,
  setAudioCallStart,
  setShowChatSectionDuringCall,
  setCallerName,
  setUserById,
  setContactImage,
  setIsReceiveMuted,
  setIsReceiveRecording,
  setgroupCallIds,
  setIsShareScreen,
  setIsSendingRoomData,
  setRoom,
  setParticipants,
  setMainParticipant,
  setIsMuted,
  setDialingNumber,
  setCallOpen,
  setDialOpen,
  setCallLogsDetailsDispatch,
} = callSlice.actions;
export default callSlice.reducer;
