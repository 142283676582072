import * as signalR from "@microsoft/signalr";
import {
  deleteMessage,
  newChatRecieve,
  receiveMessage,
  receiveReadReceipt,
  receiveTyping,
  removeChat,
  setUserOnline,
  updateEditedMessage,
  setReaction,
  setRenderReaction,
  addSingleContact,
  addReactions,
  addSingleAllContacts,
  setEventsRealTime,
  addSingleMainContactList,
  addParticipantRealTime,
  addSingleParticipant,
  setLastSeenRealTime,
  setLastSeenRealTimeArr,
  setMuteChatRealTime,
} from "../store/slice/ChatSlice";
import store from "../store/Store";
import { settings as s } from "../Settings";
import { showNotification } from "../store/slice/NotificationSlice";
import {
  setRealTimeEmailData,
  setRealtimeEmailRecieve,
  setRenderNewEmail,
  setUnreadEmailCounts,
} from "../store/slice/EmailSlice";
import {
  setIsReceiveMuted,
  setIsReceiveRecording,
  setIsSendingRoomData,
  setIsShareScreen,
} from "../store/slice/CallSlice";
import { useSelector } from "react-redux";

let connection = null;

// Initialize SignalR connection
export const initializeSignalRConnection = (setCallStatus) => {
  if (!connection) {
    connection = new signalR.HubConnectionBuilder()
      // .withUrl(`${s.baseUrl}/chatHub`, signalR.HttpTransportType.WebSockets) // Fallback to WebSockets
      .withUrl(`${s.baseUrl}/chatHub`) // Fallback to WebSockets
      .withAutomaticReconnect()
      .build();

    connection
      .start()
      .then(async () => {
        const user_id = localStorage.getItem("user_id");
        console.log("Connected to SignalR Hub");
        await sendUserOnline(user_id);

        // Log all events to check if they are being registered
        connection.onreconnected(async () => {
          console.log("Reconnected to SignalR Hub");
          await sendUserOnline(user_id);
        });
        connection.onreconnecting(() =>
          console.log("Reconnecting to SignalR Hub")
        );
        connection.onclose((error) =>
          console.error("Connection closed with error:", error)
        );

        // Check if ReceiveMessage event is registered
        console.log("Registering ReceiveMessage event");
        // region on ReceiveMessage
        connection.on("ReceiveMessage", (message, chatId, participant) => {
          console.log("message??", message);
          const userId = localStorage.getItem("user_id");
          const allChats = store.getState((state) => state?.chat?.all_chats);
          const payload = {
            message: message,
            chatId: chatId,
          };
          store.dispatch(receiveMessage(payload));
          // Get current location
          const currentPath = window.location.pathname;

          allChats.chat.all_chats?.forEach((chat) => {
            if (
              chat.ishide != true &&
              message?.senderId !== parseInt(userId) &&
              chat.isMute != true &&
              chat.chatId === chatId
            ) {
              store.dispatch(showNotification({ message, chatId }));
            }
          });
          // if (message?.senderId != userId && !message?.isHide) {
          //   store.dispatch(showNotification({ message, chatId }));
          // }
        });
        // region on ReceiveMessage attachment
        connection.on("ReceiveMessages", (message, chatId, participant) => {
          const userId = localStorage.getItem("user_id");
          const payload = {
            message: message,
            chatId: chatId,
          };
          store.dispatch(receiveMessage(payload));
          // Get current location
          const currentPath = window.location.pathname;
          const userNotification = participant?.some(
            (item) => item?.userId == userId
          );
          // Show notification if not on the /chats page
          if (userNotification && message?.senderId !== parseInt(userId)) {
            store.dispatch(showNotification({ message, chatId }));
          }
        });

        // region on emails Receive
        connection.on("ReceiveEmail", (email) => {
          const userId = localStorage.getItem("user_id");
          if (userId && email?.sender?.senderId?.toString() !== userId) {
            store.dispatch(setRenderNewEmail(true));
            store.dispatch(setRealTimeEmailData(email));
            store.dispatch(setUnreadEmailCounts(email?.unreadCount));
            localStorage.setItem("unreadEmailCount", email?.unreadCount);
            console.log("Real Object of Email is >>", email);
            store.dispatch(
              setRealtimeEmailRecieve({
                attachments: [],
                body: email?.body,
                createdDate: email?.createdDate,
                emailId: email?.emailId,
                emailType: email?.emailType,
                externalEmail: null,
                isDraft: email?.isDraft,
                isFav: email?.isFav,
                isRead: email?.isRead,
                isTrash: null,
                parentEmailId: email?.parentEmailId,
                recipientId: user_id,
                senderEmail: email?.sender?.email,
                senderFirstName: email?.sender?.firstName,
                senderId: email?.sender?.senderId,
                senderLastName: email?.sender?.lastName,
                senderProfilePicPath: email?.sender?.profilePicPath,
                subject: email?.subject,
              })
            );
          }
        });
        // region on ReceiveEditMessage
        connection.on("ReceiveEditMessage", (message, chatId) => {
          const payload = {
            message,
            chatId,
          };
          store.dispatch(updateEditedMessage(payload));
        });
        connection.on("ReceiveDeleteMessage", (messageId, chatId) => {
          console.log("Received edited message for chatId:", chatId);
          const payload = {
            messageId,
            chatId,
          };
          // Dispatch action to update the message in the store
          store.dispatch(deleteMessage(payload));
        });
        // region On ReceiveNewChat
        connection.on("ReceiveNewChat", (chat) => {
          store.dispatch(newChatRecieve(chat));
        });

        connection.on("ReceiveRemoveChat", (chatId) => {
          store.dispatch(removeChat(chatId));
        });

        connection.on("ReceiveReadReceipt", (readerUser, chatId, messageId) => {
          const payload = {
            readerUser,
            chatId,
            messageId,
          };
          store.dispatch(receiveReadReceipt(payload));
        });
        connection.on("ReceiveTyping", (user_id, userName, chatId) => {
          const payload = {
            user_id,
            userName,
            chatId,
          };
          store.dispatch(receiveTyping(payload));
        });
        connection.on("UserOffline", (user_id) => {
          const payload = {
            userId: user_id,
            userOnline: false,
          };
          store.dispatch(setUserOnline(payload));
        });

        connection.on("UserOnline", (user_id) => {
          const payload = {
            userId: user_id,
            userOnline: true,
          };
          store.dispatch(setUserOnline(payload));
        });
        // region Last Seen
        connection.on("Logout", (user_id, lastSeenMessage) => {
          const userId = localStorage.getItem("otherUserId");
          const payload = {
            userId: user_id,
            lastSeen: lastSeenMessage,
          };
          if (userId == user_id) {
            store.dispatch(setLastSeenRealTime(payload));
          }
          store.dispatch(setLastSeenRealTimeArr(payload));
        });

        // region Hide Conversation
        connection.on("ReceiveIsHide", (chatid, userid, IsHide, IsMute) => {
          // const payload = { chatid, userid, IsHide, IsMute };
          // store.dispatch(setMuteChatRealTime(payload));
        });

        connection.on("ReceiveCall", (call) => {
          setCallStatus(call);
        });

        connection.on("ReceiveCallStatus", () => {
          // console.log(`Call SID Zain:--->> ${callSid}, Status:--->> ${status}`);
        });
        // Receive Muted
        connection.on("ReceiveMute", (CallId, UserId, isMuted) => {
          if (CallId && UserId && isMuted === true) {
            const payload = { CallId, UserId, isMuted };
            store.dispatch(setIsReceiveMuted(payload));
          }
          if (CallId && UserId && isMuted === false) {
            const payload = { CallId, UserId, isMuted };
            store.dispatch(setIsReceiveMuted(payload));
          }
        });
        // Receive Recording
        connection.on(
          "ReceiveRecording",
          (CallId, UserId, CallSid, IsRecord) => {
            if (CallId && UserId && CallSid && IsRecord === true) {
              const payload = { CallId, UserId, CallSid, IsRecord };
              store.dispatch(setIsReceiveRecording(payload));
            }
            if (CallId && UserId && CallSid && IsRecord === false) {
              const payload = { CallId, UserId, CallSid, IsRecord };
              store.dispatch(setIsReceiveRecording(payload));
            }
          }
        );
        // Receive Screen Sharing
        connection.on(
          "ReceiveShareScreen",
          (CallId, UserId, CallSid, IsStream, RoomName) => {
            const payload = { CallId, UserId, CallSid, IsStream, RoomName };
            store.dispatch(setIsShareScreen(payload));
          }
        );
        // Receive Group Call

        connection.on(
          "ReceiveGroupCall",
          (Type, RoomName, fromUserId, ToUserIds, UsersData) => {
            const matchUserId = ToUserIds?.some((id) => id == user_id);
            if (matchUserId) {
              const payload = {
                Type,
                RoomName,
                fromUserId,
                ToUserIds,
                UsersData,
              };
              store.dispatch(setIsSendingRoomData(payload));
            }
          }
        );
        // Receive MissedCallStatus

        connection.on("ReceiveMissedCallStatus", (getMissedCalls) => {
          console.log(`check received notify--->>>`, getMissedCalls);
        });
        // region Add reaction On
        connection.on(
          "ReceiveReaction",
          (MessageId, UserId, Reaction, ReactionData) => {
            const payload = {
              MessageId: ReactionData?.messageId,
              messageReactionId: ReactionData?.messageReactionId,
              UserId: ReactionData?.reactorId,
              reaction: ReactionData?.reaction,
              reactor_Name: ReactionData?.name?.name,
              proifle_Pic: ReactionData?.name?.profilePicPath,
            };
            store.dispatch(setReaction(payload));
          }
        );
        // region Remove reaction On
        connection.on(
          "RemoveReaction",
          (userId, messageReactionId, reaction, ReactionData) => {
            const payload = {
              MessageId: ReactionData?.messageId,
              messageReactionId: ReactionData?.messageReactionId,
              UserId: ReactionData?.reactorId,
              reaction: ReactionData?.reaction,
              reactor_Name: ReactionData?.name?.name,
              proifle_Pic: ReactionData?.name?.profilePicPath,
            };
            store.dispatch(setReaction(payload));
          }
        );
        // region AddRemoveUser events
        connection.on("AddRemoveUser", (Events) => {
          store.dispatch(setEventsRealTime(Events));
        });
        // region get Contacts
        connection.on("ReceiveNewContact", (chat) => {
          store.dispatch(addSingleAllContacts(chat));
          store.dispatch(addSingleMainContactList(chat));
        });
        // region GetParticipant
        connection.on("ReceiveChatParticipant", (chat, model) => {
          const userId = localStorage.getItem("user_id");
          const filterUserData = model.users?.some((item) => userId == item);
          if (filterUserData === true) {
            store.dispatch(addSingleParticipant(chat));
            store.dispatch(
              showNotification({
                message: chat,
                chatId: chat?.chatId,
              })
            );
          }
        });
      })
      .catch((err) => {
        console.error("Error connecting to SignalR Hub:", err);
        if (err instanceof Error) {
          console.error("Detailed error:", err.message);
        }
      });
  }
};

// Function to check connection status
export const getConnectionStatus = () => {
  if (connection) {
    switch (connection.state) {
      case signalR.HubConnectionState.Disconnected:
        return "Disconnected";
      case signalR.HubConnectionState.Connecting:
        return "Connecting";
      case signalR.HubConnectionState.Connected:
        return "Connected";
      case signalR.HubConnectionState.Disconnecting:
        return "Disconnecting";
      default:
        return "Unknown";
    }
  } else {
    return "Connection not initialized";
  }
};

// region Helper function to handle SignalR operations
const invokeSignalRMethod = async (methodName, ...params) => {
  if (getConnectionStatus() === "Connected") {
    try {
      await connection.invoke(methodName, ...params);
      console.log(`${methodName} successfully invoked.`);
    } catch (err) {
      console.error(`Error invoking ${methodName}:`, err);
      if (err instanceof Error) {
        console.error("Detailed error:", err.message);
      }
    }
  } else {
    console.error("SignalR connection is not established or not connected.");
  }
};

// Check User Online
export const sendUserOnline = async (user_id) => {
  await invokeSignalRMethod("SendUserOnline", parseInt(user_id));
};

// Function to send message
export const sendMessageToHub = async (message) => {
  await invokeSignalRMethod("SendMessage", message);
};

// Function to send message with attachments
export const sendMessageWithAttachment = async (MessageId, ChatId) => {
  await invokeSignalRMethod("SendMessageWithAttachment", MessageId, ChatId);
};
// region update messages invoke
export const SendEditMessage = async (message, DeleteDraft) => {
  debugger;
  await invokeSignalRMethod("SendEditMessage", message, DeleteDraft);
};
// region read messages
export const SendReadReceipt = async (readerUserId, chatId, messageId) => {
  await invokeSignalRMethod(
    "SendReadReceipt",
    parseInt(readerUserId),
    chatId,
    messageId
  );
};
// region SendNewChat invoke
export const SendNewChat = async (chatId, users) => {
  await invokeSignalRMethod("SendNewChat", parseInt(chatId), users);
};

// region lastSeen
export const LastSeenMessage = async (userId) => {
  await invokeSignalRMethod("Logout", parseInt(userId));
};
// region Hide Conversation
export const HideConversation = async (ChatId, userId, isHide, isMute) => {
  const chatid = parseInt(ChatId);
  const userid = parseInt(userId);
  await invokeSignalRMethod("HideMute", chatid, userid, isHide, isMute);
  const payload = { chatid, userid, isHide, isMute };
  store.dispatch(setMuteChatRealTime(payload));
};
// region Invok contacts
export const InvokeContacts = async (senderUserid, contactId, phoneNo) => {
  await invokeSignalRMethod(
    "AddNewContact",
    parseInt(senderUserid),
    parseInt(contactId),
    phoneNo
  );
};
// region invoke AddRection
export const AddReaction = async (MessageId, UserId, Reaction) => {
  await invokeSignalRMethod("AddReaction", MessageId, UserId, Reaction);
};
// region invoke RemoveRection
export const RemoveReaction = async (userId, messageReactionId) => {
  await invokeSignalRMethod("RemoveReaction", userId, messageReactionId);
};
// region AddRemoveUser
export const AddRemoveUser = async (ConversationEventId) => {
  await invokeSignalRMethod("AddRemoveUser", parseInt(ConversationEventId));
};
// region AddChatParticipant
export const AddParticipantRealTime = async (model, AdminId) => {
  await invokeSignalRMethod("AddChatParticipant", model, parseInt(AdminId));
};

export const newEmailNotification = async (email) => {
  await invokeSignalRMethod("ReceiveEmail", email);
};

export const SendTyping = async (user_id, userName, chatId) => {
  await invokeSignalRMethod(
    "SendTyping",
    parseInt(user_id),
    userName,
    parseInt(chatId)
  );
};

export const StopConnection = async () => {
  if (connection && connection.state !== "Disconnected") {
    try {
      connection.off("ReceiveMessage");
      connection.off("ReceiveEditMessage");
      connection.off("ReceiveDeleteMessage");
      connection.off("ReceiveNewChat");
      connection.off("ReceiveReadReceipt");
      connection.off("ReceiveTyping");
      connection.off("UserOffline");
      connection.off("UserOnline");

      await connection.stop();
      console.log("SignalR disconnected");
    } catch (error) {
      console.error("Error while disconnecting SignalR:", error);
    } finally {
      connection = null;
    }
  } else {
    console.log("Connection is already disconnected or null");
  }
};

export const RemoveAttachment = async (attachmentId, userId) => {
  await invokeSignalRMethod("RemoveAttachment", attachmentId, userId);
};

export const SendCallAccept = async (call) => {
  if (getConnectionStatus() === "Connected") {
    try {
      await connection.invoke("CallAccept", call);
    } catch (err) {
      console.error("Error sending message:", err);
      if (err instanceof Error) {
        console.error("Detailed error:", err.message);
      }
    }
  } else {
    console.error("SignalR connection is not established or not connected.");
  }
};

export const sendAudioRemoteStream = async (stream) => {
  if (getConnectionStatus() === "Connected") {
    try {
      await connection.invoke("ScreenShare", stream);
    } catch (err) {
      console.error("Error sending message:", err);
      if (err instanceof Error) {
        console.error("Detailed error:", err.message);
      }
    }
  } else {
    console.error("SignalR connection is not established or not connected.");
  }
};
export const SendMute = async (CallId, UserId, isMuted) => {
  if (getConnectionStatus() === "Connected") {
    try {
      await connection.invoke("SendMute", CallId, UserId, isMuted);
    } catch (err) {
      console.error("Error sending message:", err);
      if (err instanceof Error) {
        console.error("Detailed error:", err.message);
      }
    }
  } else {
    console.error("SignalR connection is not established or not connected.");
  }
};
export const SendRecording = async (CallId, UserId, CallSid, IsRecord) => {
  if (getConnectionStatus() === "Connected") {
    try {
      await connection.invoke(
        "SendRecording",
        CallId,
        UserId,
        CallSid,
        IsRecord
      );
    } catch (err) {
      console.error("Error sending message:", err);
      if (err instanceof Error) {
        console.error("Detailed error:", err.message);
      }
    }
  } else {
    console.error("SignalR connection is not established or not connected.");
  }
};
// For Sharing Screen
export const SendShareScreen = async (
  CallId,
  UserId,
  CallSid,
  IsStream,
  RoomName
) => {
  if (getConnectionStatus() === "Connected") {
    try {
      await connection.invoke(
        "ShareScreen",
        CallId,
        UserId,
        CallSid,
        IsStream,
        RoomName
      );
    } catch (err) {
      console.error("Error sending message:", err);
      if (err instanceof Error) {
        console.error("Detailed error:", err.message);
      }
    }
  } else {
    console.error("SignalR connection is not established or not connected.");
  }
};

// For Group CAlling
export const startGroupCall = async (Type, RoomName, fromUserId, ToUserIds) => {
  if (getConnectionStatus() === "Connected") {
    try {
      await connection.invoke(
        "startGroupCall",
        Type,
        RoomName,
        fromUserId,
        ToUserIds
      );
    } catch (err) {
      console.error("Error sending message:", err);
      if (err instanceof Error) {
        console.error("Detailed error:", err.message);
      }
    }
  } else {
    console.error("SignalR connection is not established or not connected.");
  }
};

// For Missed Call Notification
// export const getMissedCallNotify = async (userId) => {
//   if (getConnectionStatus() === "Connected") {
//     try {
//       await connection.invoke("MissedCallStatus", userId);
//     } catch (err) {
//       console.error("Error sending message:", err);
//       if (err instanceof Error) {
//         console.error("Detailed error:", err.message);
//       }
//     }
//   } else {
//     console.error("SignalR connection is not established or not connected.");
//   }
// };
