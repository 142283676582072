import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Modal,
  Typography,
  Avatar,
  Input,
  InputAdornment,
  Grid,
  IconButton,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  TextField,
  Divider,
} from "@mui/material";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import CallIcon from "@mui/icons-material/Call";
import BackspaceOutlinedIcon from "@mui/icons-material/BackspaceOutlined";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import AddIcon from "@mui/icons-material/Add";
import SearchIcon from "@mui/icons-material/Search";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import CheckIcon from "@mui/icons-material/Check";
import {
  addUserContacts,
  getUserContactStatusLists,
} from "../../../../../services/api/apiManager";
import { useSnackbar } from "notistack";
import { InvokeContacts } from "../../../../../services/signalRService/signalRService";
import { useDispatch, useSelector } from "react-redux";
import { setOtherContactList } from "../../../../../services/store/slice/ChatSlice";
import InputMask from "react-input-mask";
import { useTwilio } from "../../../../../services/context/TwilioContext";
// import { settings as s } from "../../../../services/Settings";
import { settings as s } from "../../../../../services/Settings";

const AddcontactModal = ({
  open,
  handleClose,
  contactList,
  otherList,
  // user_id,
  searchQuery,
  setSearchQuery,
  fetchUserContacts,
  handleContacts,
}) => {
  const { handleDialForOutboundCall } = useTwilio();
  const username = localStorage.getItem("username");
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [otherId, setOtherId] = useState(null);
  const [addedContacts, setAddedContacts] = useState(new Set());
  const [addContactName, setAddContactName] = useState("");
  const [error, setError] = useState(false);
  const [errorAddContact, setErrorAddContact] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const otherContactList = useSelector((state) => state.chat.otherContactList);
  const handleAddContactName = (event) => {
    setAddContactName(event.target.value);
    setError(false);
  };
  const user_id = localStorage.getItem("user_id");
  const dispatch = useDispatch();
  // Dial Modal States
  const [errValidMsg, setErrValidMsg] = useState("");
  const [dialOpen, setDialOpen] = useState(false);
  const handleDialModalOpen = () => setDialOpen(true);
  const handleDialModalClose = () => setDialOpen(false);

  // region Dial-Pad
  const [display, setDisplay] = useState("");
  const clickTimeout = useRef(null);
  let buffer = "";

  const [value, setValue] = useState("+1");
  const inputRef = useRef(null);
  const [cursorPosition, setCursorPosition] = useState(value?.length);
  const maxLength = 13;

  const numbersArray = [
    { number: "1", letters: "" },
    { number: "2", letters: "A B C" },
    { number: "3", letters: "D E F" },
    { number: "4", letters: "G H I" },
    { number: "5", letters: "J K L" },
    { number: "6", letters: "M N O" },
    { number: "7", letters: "P Q R S" },
    { number: "8", letters: "T U V" },
    { number: "9", letters: "W X Y Z" },
    { number: "*", letters: "" },
    { number: "0", letters: "+" },
    { number: "#", letters: "" },
  ];

  // region get all contacts
  // region get all contacts
  const fetchUserContactStatusLists = async (userId) => {
    try {
      const { data: { status, result = {} } = {} } =
        await getUserContactStatusLists(userId);
      const { UserContacts = [], NotInUserContacts = [] } = result;

      dispatch(setOtherContactList(NotInUserContacts));

      dispatch(setOtherContactList(NotInUserContacts));
    } catch (error) {
      console.warn("Error fetching contact lists", error);
    }
  };

  useEffect(() => {
    fetchUserContactStatusLists(user_id);
  }, []);

  // Clean the input to only allow numbers and the plus sign
  const cleanValue = (inputValue) => {
    return inputValue.replace(/[^0-9+]/g, "");
  };

  const handleInputChange = (e) => {
    const cleanedValue = cleanValue(e.target.value);
    setValue(cleanedValue);
  };

  const handleInputClick = () => {
    const input = inputRef.current;
    if (input) {
      setCursorPosition(input.selectionStart);
    }
  };

  const handleNumberClick = (number) => {
    setValue((prev) => {
      if (prev.replace(/[^0-9+]/g, "").length < maxLength - 1) {
        const beforeCursor = prev.slice(0, cursorPosition);
        const afterCursor = prev.slice(cursorPosition);
        const newValue = beforeCursor + number + afterCursor;
        setTimeout(() => setCursorPosition(beforeCursor.length + 1), 0);
        return newValue;
      }
      return prev; // Do nothing if max length is reached
    });
  };

  const handleBackspace = () => {
    setValue((prev) => {
      if (cursorPosition > 0) {
        const beforeCursor = prev.slice(0, cursorPosition - 1);
        const afterCursor = prev.slice(cursorPosition);
        const newValue = beforeCursor + afterCursor;
        setTimeout(() => setCursorPosition(beforeCursor.length), 0); // Move cursor back one position
        return newValue;
      }
      return prev;
    });
  };

  useEffect(() => {
    const input = inputRef.current;
    if (input) {
      setCursorPosition(input.selectionStart);
    }
  }, [value]);

  const handleCloseDialPad = () => {
    setValue("+1");
    setErrValidMsg("");
    handleDialModalClose();
  };

  const [openAddContactName, setOpenAddContactName] = useState(false);
  const handleOpenAddContactName = () => {
    setOpenAddContactName(true);
  };
  const handleCloseAddContactName = () => setOpenAddContactName(false);

  const [openNewContactAdded, setOpenNewContactAdded] = useState(false);
  const handleOpenNewContactAdded = () => setOpenNewContactAdded(true);
  const handleCloseNewContactAdded = () => setOpenNewContactAdded(false);

  const handleAddClick = (contactId) => {
    if (!addedContacts.has(contactId)) {
      setAddedContacts((prevAddedContacts) =>
        new Set(prevAddedContacts).add(contactId)
      );
      setShowConfirmModal(true);
      setOtherId(contactId);
    }
  };

  const filteredOthers = otherContactList?.filter(
    (cont) =>
      !addedContacts.has(cont?.userId) &&
      `${cont?.firstName} ${cont?.lastName}`
        .toLowerCase()
        .includes(searchQuery.toLowerCase())
  );
  // region add contacts
  const handleAddContacts = async (contactData) => {
    const isContactSame = contactList?.some(
      (element) => element?.externalPersonContactNo === value
    );

    if (!isContactSame) {
      try {
        const response = await addUserContacts(contactData);
        if (response?.status === 200) {
          InvokeContacts(user_id, null, value);
          setShowConfirmModal(false);
          fetchUserContactStatusLists(user_id);
          handleOpenNewContactAdded();
          setDisplay("");
          // setValue("");
          setAddContactName("");
          // handleDialModalClose();
          handleCloseAddContactName();
          fetchUserContacts(user_id);
          handleContacts(user_id, "", "", "");
          enqueueSnackbar("Contact has been added", { variant: "success" });
        } else {
          enqueueSnackbar("Failed to add contact", { variant: "error" });
          setValue("");
          setAddContactName("");
        }
      } catch (error) {
        console.error("Error adding new contact:", error.message, error.stack);
        enqueueSnackbar("An error occurred while adding contact", {
          variant: "error",
        });
      }
    } else {
      console.log("Contact is already present.");
      enqueueSnackbar("Contact already exists", {
        variant: "info",
        style: { backgroundColor: "red", color: "white" },
      });
    }
  };

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Grid container xs={12} sm={12} md={12} lg={12} xl={12}>
            {/* Header */}
            <Grid
              item
              xs={12}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Box sx={{ flex: 1, textAlign: "center" }}>
                <Typography
                  variant="h6"
                  color={"#085F99"}
                  fontWeight={"bold"}
                  fontSize={"18px"}
                >
                  Add New Contact
                </Typography>
              </Box>
              <IconButton onClick={handleClose}>
                <CancelOutlinedIcon
                  sx={{ cursor: "pointer", fontSize: "30px", color: "#333" }}
                />
              </IconButton>
            </Grid>

            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                width: "100%",
                gap: 1,
              }}
            >
              <Button
                variant="contained"
                sx={{ fontSize: "75%", width: "22%", height: "27px" }}
              >
                Internal
              </Button>
              <Button
                sx={{
                  backgroundColor: "#d7e5ef",
                  fontSize: "75%",
                  width: "22%",
                  height: "27px",
                }}
                onClick={() => {
                  handleDialModalOpen();
                  //handleClose();
                }}
              >
                External
              </Button>
            </Box>
            {/* Search People */}
            <Grid item xs={12}>
              <Input
                placeholder="Search people"
                fullWidth
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                sx={{
                  borderRadius: "4px",
                  margin: "7px 0",
                  "& input::placeholder": {
                    color: "#4F4C52",
                    fontWeight: "bold",
                    fontFamily: "Poppins",
                  },
                }}
                startAdornment={
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                }
              />
            </Grid>

            {/* Other List Section */}
            <Typography margin={"5px 0"} variant="h6">
              Other People
            </Typography>
            <Grid
              item
              xs={12}
              sx={{
                height: "270px",
                overflowY: "auto",
              }}
            >
              {filteredOthers?.map((otherItem, index) => (
                <Grid
                  key={index}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    marginBottom: 1,
                  }}
                >
                  <Grid container spacing={1} alignItems="center">
                    <Grid item>
                      <Avatar
                        sx={{ width: 30, height: 30, fontSize: "11px" }}
                        alt="Profile"
                        src={
                          otherItem?.profilePicPath
                            ? `${s.baseUrl}${otherItem?.profilePicPath}`
                            : undefined
                        }
                      >
                        {otherItem?.ExternalPersonContactNo
                          ? otherItem?.ExternalPersonContactNo?.slice(0, 2)
                          : otherItem?.firstName
                              ?.split(" ")
                              .map((part) => part.charAt(0).toUpperCase())
                              .join("") +
                            (otherItem?.lastName?.charAt(0)?.toUpperCase() ||
                              "")}
                      </Avatar>
                    </Grid>
                    <Grid item>
                      <Typography
                        variant="body1"
                        sx={{ fontFamily: "poppins" }}
                      >
                        {`${otherItem?.firstName} ${otherItem?.lastName}`}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Button
                    size="small"
                    variant={
                      addedContacts.has(otherItem?.userId)
                        ? "outlined"
                        : "contained"
                    }
                    startIcon={
                      addedContacts.has(otherItem?.userId) ? (
                        <CheckIcon />
                      ) : (
                        <AddOutlinedIcon />
                      )
                    }
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      marginRight: "4px",
                      backgroundColor: addedContacts.has(otherItem?.userId)
                        ? "white"
                        : "#1565c0",
                      color: addedContacts.has(otherItem?.userId)
                        ? "black"
                        : "white",
                      borderColor: addedContacts.has(otherItem?.userId)
                        ? "black"
                        : "transparent",
                    }}
                    onClick={() => handleAddClick(otherItem?.userId)}
                  >
                    Add
                  </Button>
                </Grid>
              ))}
            </Grid>
          </Grid>

          {/* Confirm Modal */}
          <Dialog
            open={showConfirmModal}
            onClose={() => setShowConfirmModal(false)}
            aria-labelledby="confirm-dialog-title"
            aria-describedby="confirm-dialog-description"
          >
            <DialogContent>
              <Typography variant="body1">
                Are you sure you want to add this contact?
              </Typography>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setShowConfirmModal(false)}>Cancel</Button>
              <Button
                onClick={() => {
                  handleAddContacts({
                    userId: user_id,
                    userContacts: otherId,
                    addToFavorite: false,
                    createdBy: user_id,
                  });
                  InvokeContacts(user_id, otherId, null);
                  fetchUserContactStatusLists(user_id);
                }}
                color="primary"
              >
                Okay
              </Button>
            </DialogActions>
          </Dialog>
        </Box>
      </Modal>

      {/* Dialpad Modal */}
      <Modal
        open={dialOpen}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <IconButton
              sx={{ padding: "0", color: "#085F99" }}
              onClick={handleCloseDialPad}
            >
              <KeyboardBackspaceIcon />
            </IconButton>
            <Typography
              variant="h6"
              color={"#085F99"}
              fontWeight={"bold"}
              fontSize={"18px"}
            >
              Dial Phone Number
            </Typography>
            <IconButton
              sx={{ padding: "0", color: "#085F99" }}
              onClick={handleCloseDialPad}
            >
              <HighlightOffIcon />
            </IconButton>
          </Box>
          {/* Show Contact Number */}
          {errValidMsg && (
            <Typography
              sx={{
                color: "red",
                fontWeight: "550",
                fontSize: "16px",
                textAlign: "center",
              }}
            >
              {errValidMsg}
            </Typography>
          )}
          <Box padding={"10px"} display={"flex"}>
            {/* unMasking Field */}
            {/* <TextField
              variant="standard"
              placeholder=""
              value={display}
              onChange={handleInputChange}
              onFocus={() => setIsTextFieldFocused(true)}
              onBlur={() => setIsTextFieldFocused(false)}
              inputRef={inputRef}
              error={errorAddContact}
              helperText={errorAddContact ? "Field cannot be empty" : ""}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Button
                      onClick={() => {
                        handleOpenAddContactName();
                      }}
                      sx={{
                        height: "30px",
                        border: "1px solid #c4c4c4",
                        bgcolor: "#e0f3ff",
                        textTransform: "none",
                        marginBottom: "5px",
                        color: "black",
                        "&:hover": {
                          border: "1px solid black",
                        },
                      }}
                      edge="end"
                      disabled={!display}
                    >
                      <AddIcon fontSize="100px" />
                      <Typography sx={{ fontSize: "10px", fontWeight: "bold" }}>
                        Add Contact
                      </Typography>
                    </Button>
                  </InputAdornment>
                ),
              }}
              sx={{
                width: "100%",
                borderRadius: "20px",
                "& .MuiOutlinedInput-root": {
                  borderColor: "#BCBCBC",
                },
                "& .MuiInput-underline:before": {
                  borderBottomColor: "#BCBCBC",
                },
                "& .MuiInput-underline:after": {
                  borderBottomColor: "#BCBCBC",
                },
                "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
                  borderBottomColor: "#BCBCBC",
                },

                "& input::placeholder": {
                  color: "gray",
                  opacity: 1,
                },
              }}
            /> */}

            <InputMask
              mask="+1 999-999-9999" // Mask pattern
              value={value}
              onChange={handleInputChange}
              maskPlaceholder=" " // Optional placeholder for missing digits
            >
              {(inputProps) => (
                <TextField
                  {...inputProps}
                  inputRef={inputRef}
                  variant="standard"
                  fullWidth
                  placeholder="Enter your phone number"
                  autoComplete="off"
                  onClick={handleInputClick}
                />
              )}
            </InputMask>
            <Button
              onClick={() => {
                setOpenAddContactName(true);
                // setAddingContactName({
                //   contactName: "",
                // });
              }}
              sx={{
                height: "30px",
                border: "1px solid #c4c4c4",
                bgcolor: "#e0f3ff",
                width: "25%",
                textTransform: "none",
                color: "black",
                "&:hover": {
                  border: "1px solid black",
                },
              }}
              edge="end"
              disabled={value?.length < 12}
            >
              <AddIcon fontSize="100px" />
              <Typography sx={{ fontSize: "10px", fontWeight: "bold" }}>
                Add Contact
              </Typography>
            </Button>
          </Box>
          {/* Numbers */}
          <Box sx={{ textAlign: "center" }}>
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "repeat(3, 1fr)",
                gap: "10px",
                padding: "0 10px 0 70px",
              }}
            >
              {numbersArray?.map(({ number, letters }, index) => (
                <Box
                  key={index}
                  onClick={() => handleNumberClick(number)}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    bgcolor: "#e0f3ff",
                    borderRadius: "50%",
                    width: "70px",
                    height: "70px",
                    cursor: "pointer",
                    boxShadow: 2,
                    "&:hover": {
                      bgcolor: number === number ? "#90c0ff" : "#c0e0ff",
                    },
                    outline: "none",
                    userSelect: "none",
                    "&:focus": { outline: "none" },
                  }}
                >
                  <Typography
                    color={"black"}
                    fontWeight={"bold"}
                    fontSize={"24px"}
                  >
                    {number}
                  </Typography>
                  {letters && (
                    <Typography color={"black"} fontSize={"10px"}>
                      {letters}
                    </Typography>
                  )}
                </Box>
              ))}
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-around",
                alignItems: "center",
                padding: "14px 75px 0 100px",
              }}
            >
              <Box
                width={"100%"}
                paddingLeft={"20px"}
                display={"flex"}
                justifyContent={"center"}
              >
                <IconButton
                  sx={{
                    border: "1px solid #DBDBDB",
                    background: "#4ebc4e",
                    color: "white",
                    borderRadius: "50%",
                    width: "60px",
                    height: "60px",
                    cursor: "pointer",
                    boxShadow: 2,
                    fontSize: "35px",
                    "&:hover": { bgcolor: "#4ebc4e" },
                  }}
                  onClick={() => {
                    if (!value.startsWith("+")) {
                      setErrValidMsg(
                        "Please enter a valid number like +1234567..."
                      );
                      return;
                    } else {
                      handleDialForOutboundCall(
                        value.toString(),
                        username,
                        "outbound",
                        value
                      );
                      setValue("");
                      setDialOpen(false);
                      handleClose();
                    }
                  }}
                >
                  <CallIcon fontSize="20px" />
                </IconButton>
              </Box>
              <IconButton onClick={handleBackspace}>
                <BackspaceOutlinedIcon sx={{ color: "black" }} />
              </IconButton>
            </Box>
          </Box>
        </Box>
      </Modal>

      {/* Add Contact Name Modal  */}
      <Modal
        open={openAddContactName}
        onClose={handleCloseAddContactName}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            variant="h6"
            color={"#085F99"}
            fontWeight={"bold"}
            fontSize={"18px"}
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            Add Contact Name
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Contact Name <span style={{ color: "red" }}> *</span>
          </Typography>
          <TextField
            autoComplete="off"
            value={addContactName}
            onChange={(e) => {
              const value = e.target.value;
              if (/^[^0-9]*$/.test(value)) {
                handleAddContactName(e);
                setError(false);
              } else {
                setError(true);
              }
            }}
            error={error}
            helperText={
              error
                ? addContactName?.trim()
                  ? "Alphabets only"
                  : "Contact name cannot be empty"
                : ""
            }
            sx={{
              width: "100%",
              margin: "5px 0 10px 0",
              "& .MuiInputBase-root": {
                height: "40px",
              },
            }}
          />
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              gap: 1,
              marginBottom: "10px",
            }}
          >
            <Button
              variant="contained"
              sx={{
                bgcolor: "#dedede",
                color: "black",
                width: "25%",
                "&:hover": {
                  bgcolor: "#dedede",
                  color: "black",
                },
              }}
              onClick={() => {
                handleCloseAddContactName();
                setAddContactName("");
              }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              sx={{ width: "25%" }}
              onClick={() => {
                if (!addContactName.trim()) {
                  setError(true);
                  return;
                }
                if (/\d/.test(addContactName)) {
                  setError(true);
                  return;
                }

                handleAddContacts({
                  userId: user_id,
                  userContacts: otherId,
                  addToFavorite: false,
                  createdBy: user_id,
                  ExternalPersonName: addContactName,
                  ExternalPersonContactNo: value,
                });
                setAddContactName("");
                setOpenAddContactName(false);
                // setValue("");
              }}
            >
              Add
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  borderRadius: "10px",
  boxShadow: 24,
  p: "10px 20px",
};

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: "90%", sm: "80%", md: "60%", lg: "35%", xl: "35%" },
  bgcolor: "background.paper",
  borderRadius: 2,
  border: "2px solid #FFFFFF",
  p: 2,
};

export default AddcontactModal;
