export const settings = {
  // baseUrl: "http://tfs-dgk:8784",
  // baseUrl: "http://tfs-server:8073",
  // baseUrl: "https://uhs.unionhealthsolutions.com/MaxChats",
  // baseUrl: "http://tfs-server:8073",
  baseUrl: "https://communication.mremind.com/MaxChatsAPI",

  chatuser: {
    login: "/api/User/Login",
    sendOtp: "/api/User/SendOTP?email=[email]",
    verifyOTP: "/api/User/VerifyOTP?email=[email]&otp=[otp]",
    resetPassword: "/api/User/ResetPassword?email=[email]&password=[password]",
    refreshToken:
      "/api/User/RefreshAccessToken?refreshAccessToken=[refreshAccessToken]",
    addUser: "/api/User/AddUser",
    addUserContacts: "/api/User/AddUserContacts",
    updateUser: "/api/User/UpdateUser",
    updateUserContact: "/api/User/UpdateUserContact",
    getUsers:
      "/api/User/GetUsers?page=[page]&pageSize=[pageSize]&username=[username]&depId=[depId]&status=[status]",
    getUserById: "/api/User/GetUserById?UserId=[UserId]",
    getUsersCSV:
      "/api/User/GetUsersCSV?page=[page]&pageSize=[pageSize]&username=[username]&depId=[depId]&status=[status]",
    ExportUserContactsToExcel:
      "/api/User/GetUserContactsBase64?UserId=[UserId]",
    getUsersList: "/api/User/GetUsersList",
    getUserContactsById: "/api/User/GetUserContacts?UserId=[UserId]",
    getRolesList: "/api/User/GetRolesList",
    getDesignationListById:
      "/api/User/GetDesignationList?DepertmentId=[DepertmentId]",
    getDepertmentList: "/api/User/GetDepertmentList",
    getUserContactStatusLists:
      "/api/User/GetUserContactStatusLists?userId=$[userId]",
    addUserContacts: "/api/User/AddUserContacts",
    addCallRecords: "/api/Call/AddCallRecords",
    getUserContactsByParams:
      "/api/User/GetUserContacts?UserId=[UserId]&Rows=[Rows]&PageNumber=[PageNumber]&isFav=[isFav]&isExternal=[IsExternal]&UserName=[UserName]",
  },

  chats: {
    getUserChats:
      "/api/Chat/GetUserChats?userId=$[userId]&isFav=$[isFav]&ishide=$[ishide]",
    getSearchUserChats:
      "/api/Chat/GetUserChats?userId=$[userId]&isFav=$[isFav]&IsAdmin=$[IsAdmin]",
    getSearchUserContacts:
      "/api/User/GetUserContacts?userId=$[userId]&IsAdmin=$[IsAdmin]",
    addChat: "/api/Chat/AddChat",
    updateChat: "/api/Chat/UpdateChat",
    updateNameChat: "/api/Chat/UpdateChat?DeleteName=$[DeleteName]",
    updateFavChat: "/api/Chat/UpdateFavChat",
    removeChatParticipant: "/api/Chat/RemoveChatParticipant",
    addChatParticipant: "/api/Chat/AddChatParticipant",
    getChatMessages:
      "/api/Chat/GetChatMessages?userId=$[userId]&chatId=$[chatId]&isAdmin=$[isAdmin]&page=$[page]&pageSize=$[pagesize]",
    getChatMessagesUser:
      "/api/Chat/GetChatMessages?userId=$[userId]&chatId=$[chatId]",
    getUserContacts: "/api/User/GetUserContacts?userId=$[userId]",
    getSearchUserContacts:
      "/api/User/GetUserContacts?userId=$[userId]&IsAdmin=$[IsAdmin]&Rows=$[Rows]&PageNumber=$[PageNumber]",
    addChatMessage: "/api/Chat/AddChatMessage",
    GetChatAttachments: "/api/Chat/GetChatAttachments?ChatId=$[ChatId]",
    GetChatParticipants:
      "/api/Chat/GetChatParticipants?ChatId=$[ChatId]&UserId=$[UserId]",
    getCallHistory:
      "/api/Call/GetRecentCalls?clientIdentifier=$[clientIdentifier]&conference=$[conference]",
    getCallRecordings:
      "/api/Call/get-recording?AutoRecording=$[autoRecording]&sid=$[sid]",
  },
  emails: {
    getEmailsList:
      "/api/Email/GetEmailsList?UserId=$[UserId]&PageNumber=$[PageNumber]&Rows=$[Rows]",
    getEmailsListSent:
      "/api/Email/GetEmailsList?UserId=$[UserId]&PageNumber=$[PageNumber]&Rows=$[Rows]&IsSent=$[IsSent]",
    getEmailsListDraft:
      "/api/Email/GetEmailsList?UserId=$[UserId]&PageNumber=$[PageNumber]&Rows=$[Rows]&IsDraft=$[IsDraft]",
    getEmailsListFav:
      "/api/Email/GetEmailsList?UserId=$[UserId]&PageNumber=$[PageNumber]&Rows=$[Rows]&IsFav=$[IsFav]",
    getEmailsListTrash:
      "/api/Email/GetEmailsList?UserId=$[UserId]&PageNumber=$[PageNumber]&Rows=$[Rows]&IsTrash=$[IsTrash]",
    getIndividualFoldersEmailsList:
      "/api/Email/GetEmailsList?UserId=$[UserId]&PageNumber=$[PageNumber]&Rows=$[Rows]&FolderId=$[FolderId]",
    getEmailDetail: "/api/Email/GetEmailDetail?EmailId=$[EmailId]",
    sendEmail: "/api/Email/SendEmail",
    updateEmail: "/api/Email/UpdateEmail",
    getUserFolders: "/api/Email/GetUserFolders?UserId=$[UserId]",
    addUserFolder: "/api/Email/AddUserFolder",
    updateUserFolder: "/api/Email/UpdateUserFolder",
    moveToFolder: "/api/Email/MoveToFolder",
  },
  twilio: {
    getToken: "/api/Call/GenerateToken?identity",
    getVideoCallToken:
      "/api/Call/GenerateToken?identity=$[identity]&roomName=$[roomName]",
    addParticipant: "/api/Call/add-participant",
    startRecordingCall: "/api/Call/start-recording",
    stopRecordingCall: "/api/Call/stop-recording",
    getRecordingCall: "/api/Call/get-recording",
    fetchingCallDetail: "/api/Call/GetRecentCalls",
    fetchCallLogsDetails:
      "/api/Call/GetCallRecords?UserId=$[userID]&page=$[page]&pageSize=$[pageSize]&searchTerm=$[searchTerm]",
    outboundCall: "/api/Call/GetOutboundStatus",
    inprogressCall: "/api/Call/GetInProgressCalls?clientId=$[clientId]",
    groupCall: "/api/Call/call-group",
    createRoom: "/api/Call/CreateRoom",
    group: {
      startGroupRecording: "/api/Call/start-Grouprecording",
      stopGroupRecording: "/api/Call/stop-GroupRecording",
    },
  },
  tasks: {
    getTasksForBoardView: "/api/Task/GetTasks?TaskId=$[taskID]",
    addNewTask: "/api/Task/AddTasks",
    updateTask: "/api/Task/UpdateTasks",
    GetDataGridTableApi: `/api/Task/TaskManagementGrid`, //Get_Api for DataGridViewTable
    GetDataGridTableTaskId: `/api/Task/TaskManagementGrid?TaskId=$[taskID]`, //Get_Api for DataGridViewTable with task ID
    DeleteGridRow: "/api/Task/UpdateTasks", // DeleteApi , work in FormData
    UpdateGridRow: "/api/Task/UpdateTasks",
  },
};
