import { createSlice } from "@reduxjs/toolkit";
import { SendReadReceipt } from "../../signalRService/signalRService";
const user_id = localStorage.getItem("user_id");

const initialState = {
  messages: [],
  events: [],
  chat_Id: null,
  user_id: null,
  all_chats: [],
  typingUsers: "",
  typingFlag: false,
  typingChatId: "",
  otherUserId: null,
  userOnline: false,
  isFav: false,
  isFavChat: false,
  action: "",
  renderFolder: false,
  emailId: null,
  foldersId: null,
  foldersName: null,
  dispatchFolderId: null,
  newCreatedfolderName: "",
  draftEmails: null,
  pendingDraftEmails: null,
  sideEmailData: [],
  favSideEmailData: [],
  folderSideEmailData: [],
  chatLoginId: null,
  defaultEmailShow: false,
  user_Name: "",
  reaction: [],
  renderReaction: false,
  chatSelectedFromContactList: {},
  contactList: [],
  mainContactList: [],
  clipboardImageURL: [],
  selectedContactName: null,
  selectedContactProfileImage: null,
  allContacts: [],
  otherContactList: [],
  renderContactPanel: false,
  convoType: "",
  contactId: "",
  pagination: { page: 1, pageSize: 50 },
  clearChatRender: false,
  multiSelectChat: false,
  selectedMessages: [],
  senderId: [],
  draftAudioBlob: null,
  draftAudioUrl: null,
  draftInput: "",
  draftFiles: [],
  lastSeenRealTime: new Date(),
  lastSeenRealTimeArr: [],
  draftMessage: [],
  draftChatId: null,
  muteChatRealTime: [],
  hitDraftApi: false,
  hiddenChats: [],
};
const chatSlice = createSlice({
  name: "chat",
  initialState,
  reducers: {
    setEmailId: (state, action) => {
      state.emailId = action.payload;
    },
    setUser_Id: (state, action) => {
      state.user_id = action.payload;
    },
    setAction: (state, action) => {
      state.action = action.payload;
    },
    setEventsInChat: (state, action) => {
      state.events = action.payload;
    },
    setEventsRealTime: (state, action) => {
      if (action.payload?.conversationId == state.chat_Id) {
        state.events.push(action.payload);
      }
    },
    addMessage: (state, action) => {
      state.messages.push(action.payload);
    },
    setRenderFolder: (state, action) => {
      state.renderFolder = action.payload;
    },
    setFoldersId: (state, action) => {
      state.foldersId = action.payload;
    },
    setDispatchFoldersId: (state, action) => {
      state.dispatchFolderId = action.payload;
    },
    setFoldersName: (state, action) => {
      state.foldersName = action.payload;
    },
    setNewCreatedfolderName: (state, action) => {
      state.newCreatedfolderName = action.payload;
    },
    setDraftEmails: (state, action) => {
      state.draftEmails = action.payload;
    },
    setPendingDraftEmails: (state, action) => {
      state.pendingDraftEmails = action.payload;
    },
    setSideEmailData: (state, action) => {
      state.sideEmailData = action.payload;
    },
    setFavSideEmailData: (state, action) => {
      state.favSideEmailData = action.payload;
    },
    setFolderSideEmailData: (state, action) => {
      state.folderSideEmailData = action.payload;
    },
    setDefaultEmailShow: (state, action) => {
      state.defaultEmailShow = action.payload;
    },
    setUser_Name: (state, action) => {
      state.user_Name = action.payload;
    },
    setRenderReaction: (state, action) => {
      state.renderReaction = action.payload;
    },
    setContactList: (state, action) => {
      state.contactList = action.payload;
    },
    addSingleContact: (state, action) => {
      state.contactList.push(action.payload);
    },
    setMainContactList: (state, action) => {
      state.mainContactList = action.payload;
    },
    addSingleMainContactList: (state, action) => {
      state.mainContactList.push(action.payload);
    },
    setAllContacts: (state, action) => {
      state.allContacts = action.payload;
    },
    addSingleAllContacts: (state, action) => {
      state.allContacts.push(action.payload);
    },
    addSingleParticipant: (state, action) => {
      state.all_chats.push(action.payload);
    },
    setMessagesInChat: (state, action) => {
      state.messages = action.payload;
    },
    setOtherContactList: (state, action) => {
      state.otherContactList = action.payload;
    },
    setRenderContactPanel: (state, action) => {
      state.renderContactPanel = action.payload;
    },
    setLastSeenRealTime: (state, action) => {
      state.lastSeenRealTime = action.payload;
    },
    setMessagesUpdatedName: (state, action) => {
      const senderName = action.payload;
      const updatedMessages = state.messages.map((message) => {
        return {
          ...message,
          senderName: senderName,
        };
      });
      state.messages = updatedMessages;
    },
    setMuteChatRealTime: (state, action) => {
      const existChatId = state.muteChatRealTime.findIndex(
        (item) => item.userid === action.payload.userid
      );
      if (existChatId !== -1) {
        state.muteChatRealTime[existChatId] = action.payload;
      } else {
        state.muteChatRealTime.push(action.payload);
      }
    },
    setHiddenChats: (state, action) => {
      state.hiddenChats = action.payload;
    },
    setLastSeenRealTimeArr: (state, action) => {
      const existingIndex = state.lastSeenRealTimeArr.findIndex(
        (item) => item.userId === action.payload.userId
      );
      if (existingIndex !== -1) {
        state.lastSeenRealTimeArr[existingIndex] = action.payload;
      } else {
        state.lastSeenRealTimeArr.push(action.payload);
      }
    },

    // region add reaction in real time
    // setReaction: (state, action) => {
    //   state.messages = state.messages.map((message) =>
    //     message.messageId === action.payload.MessageId
    //       ? {
    //           ...message,
    //           reactions: [
    //             ...(message.reactions || []),
    //             {
    //               reaction: action.payload.Reaction,
    //               reactorId: action.payload.UserId,
    //               messageId: action.payload.MessageId,
    //             },
    //           ],
    //         }
    //       : message
    //   );
    // },

    setReaction: (state, action) => {
      state.messages = state.messages.map((message) =>
        message.messageId === action.payload.MessageId
          ? {
              ...message,
              reactions: message.reactions?.some(
                (reaction) => reaction.reactorId === action.payload.UserId
              )
                ? // If reaction exists, remove it
                  message.reactions.filter(
                    (reaction) => !reaction.reactorId === action.payload.UserId
                  )
                : [
                    ...(message.reactions || []),
                    {
                      reaction: action.payload.reaction,
                      reactorId: action.payload.UserId,
                      messageId: action.payload.MessageId,
                      proifle_Pic: action.payload.proifle_Pic,
                      reactor_Name: action.payload.reactor_Name,
                      messageReactionId: action.payload.messageReactionId,
                    },
                  ],
            }
          : message
      );
    },

    receiveMessage: (state, action) => {
      const { chatId, message } = action.payload;
      const realTimeChat = {
        attachments: message?.attachments,
        createdDate: message?.createdDate,
        messageContent: message.messageContent,
        messageId: message.messageId,
        modifiedDate: message.modifiedDate,
        replyMessage: message.replyMessage,
        seenUsers: message.seenUsers,
        senderId: message.senderId,
        senderName: message.senderName,
        senderProfilePic: message.senderProfilePic,
        isHide: message?.isHide,
      };
      if (chatId == state.chat_Id) {
        state.messages?.shift();
        state.messages.push(realTimeChat);
        if (message.senderId != user_id) {
          setTimeout(() => {
            SendReadReceipt(
              user_id,
              parseInt(chatId),
              parseInt(message.messageId)
            );
          }, 1000);
        }
      }

      // Find the chat that matches the received message's chatId
      const chatIndex = (
        realTimeChat?.isHide === true ? state.hiddenChats : state.all_chats
      )?.findIndex((chat) => chat.chatId === chatId);

      const chatLists_InLeftSide =
        realTimeChat?.isHide === true ? state.hiddenChats : state.all_chats;
      if (chatIndex !== -1) {
        // Update the lastMessage and increment unreadCount
        const lastMessageContent =
          message?.messageContent && message.messageContent !== ""
            ? message.messageContent
            : message?.replyMessage
            ? "Quoted"
            : message?.attachments?.length
            ? "Attachment(s)"
            : message?.messageContent;

        chatLists_InLeftSide[chatIndex].lastMessage = {
          ...message,
          messageContent: lastMessageContent,
        };

        if (chatId !== state.chat_Id && message.senderId != user_id) {
          chatLists_InLeftSide[chatIndex].unreadCount += 1;
        }

        // Move the chat to the top of the list
        const [chat] = state.all_chats.splice(chatIndex, 1);

        if (chat.isFav) {
          state.all_chats.unshift(chat);
        } else {
          let insertIndex = state.all_chats.findIndex((c) => !c.isFav);
          if (insertIndex === -1) {
            insertIndex = state.all_chats.length;
          }
          state.all_chats.splice(insertIndex, 0, chat);
        }
      }
    },
    deleteMessage: (state, action) => {
      const { chatId, messageId } = action.payload;
      // Update the message in the active chat
      if (chatId === state.chat_Id) {
        const messageIndex = state.messages.findIndex(
          (m) => m.messageId == messageId
        );
        if (messageIndex !== -1) {
          state.messages.splice(messageIndex, 1);
        }
      }
      const chatIndex = state.all_chats.findIndex(
        (chat) => chat.chatId === chatId
      );
      if (chatIndex !== -1) {
        if (state.chat_Id === chatId) {
          if (state.messages?.length > 0) {
            const lastMessage = state.messages[state.messages?.length - 1];

            state.all_chats[chatIndex].lastMessage = {
              messageId: lastMessage.messageId,
              messageContent: lastMessage.messageContent,
              createdDate: lastMessage.createdDate,
            };
          } else {
            state.all_chats[chatIndex].lastMessage = null;
          }
        } else {
          const event = new CustomEvent("refreshUserChats");
          window.dispatchEvent(event);
        }
      }
    },
    updateEditedMessage: (state, action) => {
      const { chatId, message } = action.payload;

      // Update the message in the active chat
      if (chatId === state.chat_Id) {
        const messageIndex = state.messages.findIndex(
          (m) => m.messageId === message.messageId
        );
        if (messageIndex !== -1) {
          state.messages[messageIndex] = message; // Update the message content
        }
      }

      // Find the chat that matches the edited message's chatId
      const chatIndex = state.all_chats.findIndex(
        (chat) => chat.chatId === chatId
      );

      if (chatIndex !== -1) {
        // Update the lastMessage if the edited message is the last one in the chat
        if (
          state.all_chats[chatIndex].lastMessage?.messageId ===
          message.messageId
        ) {
          state.all_chats[chatIndex].lastMessage.messageContent =
            message.messageContent;
        }
      }
    },
    newChatRecieve: (state, action) => {
      const isDuplicate = state.all_chats.some(
        (chat) => chat.chatId === action.payload.chatId
      );
      if (!isDuplicate) {
        const chat = action.payload;
        if (chat.isFav) {
          state.all_chats.unshift(chat);
        } else {
          let insertIndex = state.all_chats.findIndex((c) => !c.isFav);
          if (insertIndex === -1) {
            insertIndex = state.all_chats.length;
          }
          state.all_chats.splice(insertIndex, 0, chat);
        }
        // state.all_chats.unshift(action.payload);
      }
    },

    receiveReadReceipt: (state, action) => {
      const { readerUser, chatId, messageId } = action.payload;

      if (chatId === state.chat_Id) {
        const messageIndex = state.messages.findIndex((m) =>
          m.seenUsers.some((item) => item.userId == readerUser.userId)
        );
        if (messageIndex !== -1) {
          const seenUserIndex = state.messages[
            messageIndex
          ].seenUsers.findIndex((item) => item.userId == readerUser.userId);
          if (seenUserIndex !== -1) {
            state.messages[messageIndex].seenUsers.splice(seenUserIndex, 1);
          }
        }

        const msgIndex = state.messages.findIndex(
          (m) => m.messageId == messageId
        );
        if (msgIndex !== -1) {
          state.messages[msgIndex].seenUsers.push(readerUser);
        }
      }
    },
    receiveTyping: (state, action) => {
      const { user_id: typingUserId, userName, chatId } = action.payload;

      if (typingUserId === user_id) return;

      if (state.chat_Id == chatId) {
        state.typingUsers = userName;
        state.typingFlag = true;
        state.typingChatId = chatId;
      }
    },
    setChat_Id: (state, action) => {
      state.chat_Id = action.payload;
    },
    setAllChats: (state, action) => {
      state.all_chats = action.payload;
    },
    setAllChatsNickName: (state, action) => {
      const { chatId, name } = action.payload;
      state.all_chats = state.all_chats.map((chat) => {
        if (chat?.chatId == chatId) {
          return {
            ...chat,
            userNickName: {
              ...chat.userNickName,
              userNickName: name,
            },
          };
        }
        return chat;
      });
    },
    resetChatState: (state) => {
      return initialState;
    },
    setTypingFlag: (state, action) => {
      state.typingFlag = action.payload;
    },
    resetUnreadCount: (state, action) => {
      const { chatId, ishide } = action.payload;
      const chatLists_InLeftSide =
        ishide == true ? state.hiddenChats : state.all_chats;
      const chatIndex = chatLists_InLeftSide?.findIndex(
        (chat) => chat.chatId === chatId
      );

      if (chatIndex !== -1) {
        chatLists_InLeftSide[chatIndex].unreadCount = 0;
      }
    },
    setOtherUser: (state, action) => {
      const { otherUserId, userOnline } = action.payload;

      state.otherUserId = otherUserId;
      state.userOnline = userOnline;
    },
    setUserOnline: (state, action) => {
      const { userId, userOnline } = action.payload;

      const chatIndex = state.all_chats.findIndex(
        (chat) => chat.otherUserId === userId
      );

      if (chatIndex !== -1) {
        state.all_chats[chatIndex].userOnline = userOnline;
      }

      if (state.otherUserId == userId) {
        state.userOnline = userOnline;
      }
    },

    setIsFav: (state, action) => {
      state.isFav = action.payload;
    },
    // for FavChat
    setIsFavChat: (state, action) => {
      state.isFavChat = action.payload;
    },

    removeChat: (state, action) => {
      const chatId = action.payload;

      const chatIndex = state.all_chats.findIndex(
        (chat) => chat.chatId === chatId
      );

      if (chatIndex !== -1) {
        state.all_chats.splice(chatIndex, 1);
        if (state.chat_Id === chatId) {
          state.chat_Id = null;
        }
      }
    },
    setChatLoginId: (state, action) => {
      state.chatLoginId = action.payload;
    },
    setChatSelectedFromContactList: (state, action) => {
      state.chatSelectedFromContactList = action.payload;
    },
    setClipboardImageURL: (state, action) => {
      state.clipboardImageURL = action.payload;
    },
    setSelectedContactName: (state, action) => {
      state.selectedContactName = action.payload;
    },
    setSelectedContactProfileImage: (state, action) => {
      state.selectedContactProfileImage = action.payload;
    },
    setConvoType: (state, action) => {
      state.convoType = action.payload;
    },
    setContactId: (state, action) => {
      state.contactId = action.payload;
    },
    setPagination: (state, action) => {
      state.pagination = action.payload;
    },
    setClearChatRender: (state, action) => {
      state.clearChatRender = action.payload;
    },
    setMultiSelectChat: (state, action) => {
      state.multiSelectChat = action.payload;
    },
    setSelectedMessages: (state, action) => {
      state.selectedMessages = action.payload;
    },
    setSingleSelectedMessages: (state, action) => {
      const index = state.selectedMessages.indexOf(action.payload?.messageId);
      if (index === -1) {
        state.selectedMessages.push(action.payload?.messageId);
      } else {
        state.selectedMessages.splice(index, 1);
      }
      const isCheck = state.senderId.some(
        (item) => action.payload?.messageId === item?.messageId
      );
      if (isCheck) {
        state.senderId = state.senderId.filter(
          (item) => action.payload?.messageId !== item?.messageId
        );
      } else {
        state.senderId.push({
          messageId: action.payload?.messageId,
          senderId: action.payload?.senderId,
        });
      }
    },

    setDraftFilesPrev: (state, action) => {
      state.draftFiles.push(action.payload);
    },
    setDraftFiles: (state, action) => {
      state.draftFiles = action.payload;
    },
    setDraftInput: (state, action) => {
      state.draftInput = action.payload;
    },
    setDraftAudioUrl: (state, action) => {
      state.draftAudioUrl = action.payload;
    },
    setDraftAudioBlob: (state, action) => {
      state.draftAudioBlob = action.payload;
    },
    setDraftMessage: (state, action) => {
      state.draftMessage = action.payload;
    },
    setDraftChatId: (state, action) => {
      state.draftChatId = action.payload;
    },
    setHitDraftApi: (state, action) => {
      state.hitDraftApi = action.payload;
    },
  },
});

export const {
  setMessagesInChat,
  setEventsInChat,
  addMessage,
  receiveMessage,
  updateEditedMessage,
  deleteMessage,
  newChatRecieve,
  receiveReadReceipt,
  receiveTyping,
  setChat_Id,
  setUser_Id,
  setAllChats,
  resetChatState,
  setTypingFlag,
  resetUnreadCount,
  setOtherUser,
  setUserOnline,
  setAction,
  setIsFav,
  setIsFavChat,
  removeChat,
  setRenderFolder,
  setEmailId,
  setFoldersId,
  setFoldersName,
  setNewCreatedfolderName,
  setDispatchFoldersId,
  setFolderSideEmailData,
  setFavSideEmailData,
  setSideEmailData,
  setDraftEmails,
  setPendingDraftEmails,
  setChatLoginId,
  setDefaultEmailShow,
  setUser_Name,
  setRenderReaction,
  setReaction,
  setChatSelectedFromContactList,
  setContactList,
  addSingleContact,
  addReactions,
  setClipboardImageURL,
  setSelectedContactName,
  setSelectedContactProfileImage,
  setAllContacts,
  addSingleAllContacts,
  setEventsRealTime,
  setOtherContactList,
  setRenderContactPanel,
  setConvoType,
  setContactId,
  addSingleMainContactList,
  setMainContactList,
  addSingleParticipant,
  setPagination,
  setClearChatRender,
  setMultiSelectChat,
  setSelectedMessages,
  setSingleSelectedMessages,
  setDraftAudioUrl,
  setDraftInput,
  setDraftFiles,
  setDraftFilesPrev,
  setLastSeenRealTime,
  setLastSeenRealTimeArr,
  setDraftMessage,
  setDraftChatId,
  setDraftAudioBlob,
  setMuteChatRealTime,
  setHitDraftApi,
  setHiddenChats,
  setMessagesUpdatedName,
  setAllChatsNickName,
} = chatSlice.actions;
export default chatSlice.reducer;
