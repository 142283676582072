import React, { useEffect, useState } from "react";
import {
  Box,
  Paper,
  Typography,
  Button,
  Alert,
  Snackbar,
  CircularProgress,
} from "@mui/material";
import loginImage from "../../../../assets/img/LoginBg.jpg";
import favicon from "../../../../assets/img/x.png";
import email from "../../../../assets/img/Message.png";
import Lock from "../../../../assets/img/Lock.png";
import eyeOn from "../../../../assets/img/Eyeon.svg";
import eyeOff from "../../../../assets/img/visibilityoff.svg";
import "./login.css";
import { TextField, InputAdornment, IconButton } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Link, useNavigate } from "react-router-dom";
import { login } from "../../../services/api/apiManager";
import { setRenderFolder } from "../../../services/store/slice/ChatSlice";
import { useDispatch, useSelector } from "react-redux";

const Login = () => {
  const token = localStorage.getItem("access_token");
  // State to manage password visibility
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);
  const [userName, setUserName] = useState("");
  const [userPassword, setUserPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const renderFolder = useSelector((state) => state.chat.renderFolder);
  const [openSnackbar, setOpenSnackbar] = useState({
    openSnack: false,
    snackMessage: "",
  });
  const navigate = useNavigate();
  // Toggle password visibility
  const handleClickShowPassword = () => {
    setShowPassword((prev) => !prev);
  };
  // Prevent default action on mouse down
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const data = {
    username: userName,
    password: userPassword,
  };
  // Key press
  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key === "Enter" && userName !== "" && userPassword !== "") {
        postAthenticationData(data);
      }
    };
    window.addEventListener("keydown", handleKeyPress);
    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, [userName, userPassword]);

  // Api integration
  const postAthenticationData = async (data) => {
    setLoading(true);
    try {
      const response = await login(data);
      if (response?.data?.status === 200 && response?.data?.result?.token) {
        // set data in localstorage
        localStorage.setItem("access_token", response?.data?.result?.token);
        localStorage.setItem(
          "refresh-token",
          response?.data?.result?.refreshToken
        );
        localStorage.setItem("user_id", response?.data?.result?.user?.userId);
        const renderFolderChangeValue = renderFolder === true ? false : true;
        dispatch(setRenderFolder(renderFolderChangeValue));
        const userRoles = response?.data?.result?.userRoles;
        localStorage.setItem(
          "user_roles",
          JSON.stringify(response?.data?.result?.userRoles)
        );
        localStorage.setItem(
          "token_expiry_time",
          response?.data?.result?.expiration
        );
        if (userRoles.some((ur) => ur.roleName.toLowerCase() === "admin")) {
          navigate("/chats");
        } else {
          navigate("/chats");
        }
        setOpenSnackbar({
          openSnack: false,
          snackMessage: "success",
        });
        setLoading(false);
      } else {
        setOpenSnackbar({
          openSnack: true,
          snackMessage: "error",
        });
        setLoading(false);
      }
    } catch (error) {
      console.warn(error);
      setLoading(false);
    }
  };
  if (token) {
    navigate("/chats");
  }
  return (
    <Box
      sx={{
        position: "relative",
        width: "100%",
        height: "100vh",
        backgroundImage: `url(${loginImage})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        objectFit: "cover",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-evenly",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            width: "30%",
            color: "white",
            height: "98vh",
            display: { md: "flex", xs: "none" },
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <Typography
            sx={{
              fontFamily: "Poppins, sans-serif",
              fontSize: "25px",
              fontWeight: "600",
            }}
          >
            Connecting Teams
          </Typography>
          <Typography
            sx={{
              fontFamily: "Poppins, sans-serif",
              fontSize: "25px",
              fontWeight: "600",
            }}
          >
            Simplifying Communication
          </Typography>
          <Typography
            sx={{
              fontFamily: "Poppins, sans-serif",
              marginTop: "10px",
              fontSize: "16px",
            }}
          >
            Our communication platform streamlines interactions with secure
            messaging, email management, audio/video calls, and collaboration
            tools. With a user-friendly interface, our system adapts to modern
            needs, ensuring reliable and effective communication.
          </Typography>
        </Box>
        <Paper
          elevation={3}
          sx={{
            //position: "absolute",
            // top: "50%",
            // left: "50%",
            //transform: "translate(-50%, -50%)",
            marginTop: { md: "0", xs: "45px" },
            padding: { xl: "45px 31px", xs: "35px 31px" },
            backgroundColor: "#e0f3f9",
            borderRadius: "30px",
            textAlign: "center",
            width: {
              xl: "26%",
              lg: "28%",
              md: "40%",
              sm: "50%",
              xs: "75%",
            },
            height: "100%",
          }}
        >
          {/* {loading ? (
          <div style={{ padding: "5.5rem 0" }} className="loading-container">
            <div className="logo-container">
              <img className="logo" src={favicon} alt="Website Logo" />
            </div>
          </div>
        ) : ( */}
          <Box pt={2.5} pb={2.5}>
            <Typography variant="h4" component="h1">
              <img
                src={favicon}
                alt="Description of the image"
                style={{
                  mixBlendMode: "multiply",
                  backgroundColor: "transparent",
                }}
              />
            </Typography>
            <Typography
              variant="h5"
              component="h1"
              style={{ fontWeight: "600" }}
            >
              <span style={{ color: "#2f2f2f", fontSize: "20px" }}>
                Welcome Back!
              </span>
            </Typography>
            <Typography
              variant="h6"
              component="h1"
              style={{ fontSize: "14px" }}
              pt={0.5}
            >
              <span style={{ color: "#90919b" }}>Login To Your Account</span>
            </Typography>
            <Box pt={3}>
              <ThemeProvider theme={theme}>
                <Box pt={2}>
                  <TextField
                    value={userName}
                    onChange={(e) => {
                      setUserName(e.target.value);
                    }}
                    variant="outlined"
                    fullWidth
                    placeholder="Username/Email"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          {/* <MailIcon /> */}
                          <img
                            src={email}
                            alt="Description of the image"
                            className="icons-width"
                          />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Box>
              </ThemeProvider>
            </Box>
            <Box pt={2.5}>
              <ThemeProvider theme={theme}>
                <Box style={{ position: "relative" }}>
                  <TextField
                    value={userPassword}
                    onChange={(e) => {
                      setUserPassword(e.target.value);
                    }}
                    sx={{
                      "& input[type='password']::-ms-reveal, & input[type='password']::-ms-clear":
                        {
                          display: "none",
                        },
                    }}
                    variant="outlined"
                    placeholder="Password"
                    type={showPassword ? "text" : "password"}
                    fullWidth
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <img
                            src={Lock}
                            alt="Description of the image"
                            className="icons-width"
                          />
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end">
                          <img
                            src={eyeOn}
                            alt="Hide password"
                            className="icons-width"
                            style={{
                              position: "absolute",
                              right: "10px",
                              top: "50%",
                              transform: "translateY(-50%)",
                              cursor: "pointer",
                              display: showPassword ? "block" : "none",
                            }}
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                          />
                          <img
                            src={eyeOff}
                            alt="Show password"
                            className="icons-width"
                            style={{
                              position: "absolute",
                              right: "10px",
                              top: "50%",
                              transform: "translateY(-50%)",
                              cursor: "pointer",
                              display: showPassword ? "none" : "block",
                            }}
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                          />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Box>
              </ThemeProvider>
            </Box>
            {/* <Box pt={2}>
              <Typography className="textrecover" sx={{ fontSize: "13px" }}>
                <Link
                  to="/recover"
                  style={{
                    fontSize: "13px",
                    textDecoration: "none",
                    color: "#095f99",
                  }}
                >
                  Recover Password
                </Link>
              </Typography>
            </Box> */}
            {/* <Box pt={4}>
            <Typography>
              <Box className="text-center" pt={3} sx={{ fontSize: "14px" }}>
                Don't have an account?{" "}
                <Link
                  to="/signup"
                  style={{
                    textDecoration: "none",
                    color: "#095f99",
                    fontWeight: "600",
                  }}
                >
                  Sign Up
                </Link>
              </Box>
            </Typography>
          </Box> */}
            <Box pt={3}>
              <Button
                disabled={userPassword !== "" && userName !== "" ? false : true}
                variant="contained"
                className="btn"
                onClick={() => {
                  postAthenticationData(data);
                }}
              >
                {loading ? (
                  <CircularProgress sx={{ color: "white" }} size="25px" />
                ) : (
                  "Log in"
                )}
              </Button>
            </Box>
          </Box>
          {/* )} */}
        </Paper>
      </Box>
      {/* snackbar */}
      <Snackbar
        open={openSnackbar?.openSnack}
        autoHideDuration={2000}
        onClose={() => setOpenSnackbar({ openSnack: false, snackMessage: "" })}
        anchorOrigin={{ vertical: "center", horizontal: "center" }}
      >
        <Alert
          onClose={() =>
            setOpenSnackbar({ openSnack: false, snackMessage: "" })
          }
          severity={
            openSnackbar?.snackMessage === "success" ? "success" : "error"
          }
          variant="filled"
          sx={{
            width: "100%",
            height: "2.5rem",
            textAlign: "center",
            fontSize: "0.9rem",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: "1.2rem",
          }}
          action={
            <>
              <span
                onClick={() =>
                  setOpenSnackbar({ openSnack: false, snackMessage: "" })
                }
                style={{
                  cursor: "pointer",
                  m: "auto 0",
                  fontSize: "13px",
                  color: "rgb(231, 231, 231)",
                }}
              >
                Close
              </span>
            </>
          }
        >
          {openSnackbar.snackMessage === "success"
            ? "Successfully login"
            : "Invalid Credentials, Please Try again"}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default Login;
const theme = createTheme({
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          "& .MuiOutlinedInput-root": {
            borderRadius: "4px",
            backgroundColor: "#f6f7fb",
            "& input": {
              padding: "6px 12px",
              fontSize: "12px",
              height: "30px",
              backgroundColor: "#f6f7fb",
            },
            "& fieldset": {
              borderColor: "#f6f7fb",
            },
            "&:hover fieldset": {
              borderColor: "#f6f7fb",
            },
            "&.Mui-focused fieldset": {
              borderColor: "#f6f7fb",
            },
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          padding: "4px 8px",
        },
      },
    },
  },
});
