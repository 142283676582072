import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  tasksInBoardView: [],
  loading: false,
  addNewTaskModel: false,
  openUpdateTaskModal: false,
  updateTaskModelTaskId: null,
  randerGetApi: false,
};

const taskandTicketSlice = createSlice({
  name: "taskAndticket",
  initialState,
  reducers: {
    setTasksInBoardView: (state, action) => {
      state.tasksInBoardView = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setAddNewTaskModel: (state, action) => {
      state.addNewTaskModel = action.payload;
    },
    setOpenUpdateModal: (state, action) => {
      state.openUpdateTaskModal = action.payload;
    },
    setUpdateTaskModelTaskId: (state, action) => {
      state.updateTaskModelTaskId = action.payload;
    },
    setRanderGetApi: (state, action) => {
      state.randerGetApi = action.payload;
    },
  },
});

export const {
  setTasksInBoardView,
  setLoading,
  setAddNewTaskModel,
  setOpenUpdateModal,
  setUpdateTaskModelTaskId,
  setRanderGetApi,
} = taskandTicketSlice.actions;
export default taskandTicketSlice.reducer;
