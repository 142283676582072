import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
} from "react";
import {
  addCallRecords,
  getTwilioUserToken,
  getUsersById,
  getVideoCallToken,
} from "../api/apiManager";
import { Device } from "twilio-client";
import {
  setCaller,
  setClearIncomingCall,
  setAudioCallStart,
  setShowChatSectionDuringCall,
  setCallRunningInBG,
  setCallerName,
  setContactImage,
  setIsReceiveMuted,
  setIsSendingRoomData,
  setDialingNumber,
  setIsOnCall,
  setCallOpen,
  setDialOpen,
  setIsMuted,
} from "../store/slice/CallSlice";
import { useDispatch, useSelector } from "react-redux";
import {
  SendCallAccept,
  startGroupCall,
} from "../signalRService/signalRService";
import { useNavigate } from "react-router-dom";
import { BgCallPopupContext } from "./BgCallPopupContext";
import { connect, createLocalAudioTrack } from "twilio-video";
import dayjs from "dayjs";
import { useSnackbar } from "notistack";
import Video from "twilio-video";
const TwilioContext = createContext();

export const TwilioProvider = ({ children }) => {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user_id = localStorage.getItem("user_id");
  const [callStatus, setCallStatus] = React.useState("");
  const [callStartTime, setCallStartTime] = React.useState(null);
  const [userOffline, setUserOffline] = React.useState(false);
  const [callAccepted, setCallAccepted] = React.useState(false);
  const [activeConnection, setActiveConnection] = React.useState(false);
  const [incomingCall, setIncomingCall] = React.useState(false);
  const [device, setDevice] = React.useState(null);
  const [groupRoom, setGroupRoom] = React.useState(null);
  const [gCallParticipants, setGCallParticipants] = React.useState([]);
  const [mainParticipant, setMainParticipant] = React.useState(null);
  const [groupName, setGroupName] = React.useState("");
  const { setIsCallActive } = BgCallPopupContext();
  const [callDuration, setCallDuration] = React.useState(0);
  const [remoteMutedData, setRemoteMutedData] = React.useState(null);
  const [previousMuteStatus, setPreviousMuteStatus] = React.useState(null);
  const [dataTrack, setDataTrack] = React.useState(null);
  const [noDeviceModal, setNoDeviceModal] = React.useState({
    isOpen: false,
    title: "",
    message: "",
    buttonText: "",
  });

  const openNoDeviceModal = ({ title, message, buttonText }) => {
    setNoDeviceModal({
      isOpen: true,
      title,
      message,
      buttonText,
    });
  };

  const closeNoDeviceModal = () => {
    setNoDeviceModal((prev) => ({ ...prev, isOpen: false }));
  };

  // Initialize the Twilio connection

  const fetchTwilioToken = async (user_id) => {
    try {
      const response = await getTwilioUserToken(user_id);
      const token = response.data.token;
      const newDevice = new Device(token, {
        codecPreferences: ["opus", "pcmu"],
        fakeLocalDTMF: true,
        enableRingingState: true,
        debug: true,
      });
      setDevice(newDevice);
      const handleError = (error) => {
        if (error.message.includes("Requested device not found")) {
          openNoDeviceModal({
            title: "Message Status",
            message: "Microphone not found, kindly connect your device.",
            buttonText: "Close",
          });
          setCallDuration(0);
        } else {
          console.error(`Twilio.Device Error: ${error.message}`);
          setCallDuration(0);
        }
      };

      const handleIncoming = (conn) => {
        const fromUserId = conn.parameters.From;
        setIncomingCall(conn);
        dispatch(setCaller(fromUserId));

        // conn.on("mute", (isRemoteMuted) => {
        //   dispatch(setCallStatus(isRemoteMuted ? "Muted" : "Connected"));
        // });

        conn.on("accept", () => {
          SendCallAccept("Connected");
          setCallAccepted(true);
          setIncomingCall(null);
          setActiveConnection(conn);
          setIsCallActive(true);
          dispatch(setAudioCallStart(true));
          navigate("/chats");
        });
      };

      const handleDisconnect = (con) => {
        if (con) {
          setCallStatus("Call Ended");
          setActiveConnection(null);
          setIncomingCall(null);
          setCallAccepted(false);
          dispatch(setClearIncomingCall(true));
          setIsCallActive(false);
          dispatch(setAudioCallStart(false));
          dispatch(setCallRunningInBG(false));
          dispatch(setShowChatSectionDuringCall(false));
          dispatch(setIsReceiveMuted([]));
          dispatch(setIsMuted(false));
          setCallDuration(0);
          setCallStatus("");
          navigate("/chats");
        }
      };
      // newDevice.on("ready", handleReady);
      newDevice.on("error", handleError);
      newDevice.on("incoming", handleIncoming);
      newDevice.on("disconnect", handleDisconnect);
    } catch (error) {
      console.error("Error fetching token!");
    }
  };

  useEffect(() => {
    if (user_id) {
      // Fetch token initially
      fetchTwilioToken(user_id);

      // Set interval for token refresh every 30 minutes
      const interval = setInterval(() => {
        fetchTwilioToken(user_id);
      }, 30 * 60 * 1000);

      return () => clearInterval(interval);
    }
  }, [user_id]);

  const handleDial = async (
    to,
    callerName,
    direction,
    contactName,
    contactImage,
    chat_Id,
    isUserOnline,
    userID
  ) => {
    dispatch(setCallerName(contactName));
    dispatch(setContactImage(contactImage));
    if (chat_Id !== null && !isUserOnline) {
      // openNoDeviceModal({
      //   title: "User Status",
      //   message: `${contactName} is offline. Please try again later.`,
      //   buttonText: "Dismiss",
      // });
      // // return;
      setUserOffline(true);
    }
    dispatch(setAudioCallStart(true));
    if (device) {
      const params = {
        To: to,
        Direction: direction,
        CallerName: callerName,
        From: userID,
      };
      const connection = device.connect(params);
      if (connection) {
        setCallDuration(0);
        setCallStatus("Calling...");
        setActiveConnection(connection);
        setIsCallActive(true);
        // let ringTimeout;
        connection.on("ringing", () => {
          setCallStatus("Ringing...");
          // ringTimeout = setTimeout(async () => {
          //   const inappPostedData = {
          //     callType: "Single",
          //     callTitle: connection?.message?.CallerName,
          //     CreatedBy: parseInt(user_id),
          //     STARTTIME:
          //       callDuration == 0
          //         ? "00:00:00.0000000"
          //         : dayjs(callStartTime).format("HH:mm:ss.") +
          //           dayjs(callStartTime)
          //             .millisecond()
          //             .toString()
          //             .padEnd(7, "0"),
          //     ENDTIME:
          //       callDuration == 0
          //         ? "00:00:00.0000000"
          //         : dayjs(new Date()).format("HH:mm:ss.") +
          //           dayjs(new Date()).millisecond().toString().padEnd(7, "0"),
          //     CALLSTATUS: callDuration == 0 ? "Busy" : "Completed",
          //     DURATION:
          //       callDuration == 0
          //         ? 0
          //         : calculateCallDuration(
          //             dayjs(new Date()).format("HH:mm:ss.") +
          //               dayjs(new Date())
          //                 .millisecond()
          //                 .toString()
          //                 .padEnd(7, "0"),
          //             dayjs(callStartTime).format("HH:mm:ss.") +
          //               dayjs(callStartTime)
          //                 .millisecond()
          //                 .toString()
          //                 .padEnd(7, "0")
          //           ),
          //     callParticipants: [
          //       {
          //         userId: parseInt(user_id),
          //         joinedAt:
          //           dayjs(callStartTime).format("HH:mm:ss.") +
          //           dayjs(callStartTime)
          //             .millisecond()
          //             .toString()
          //             .padEnd(7, "0"),
          //         callResponse: connection?.direction,
          //       },
          //       {
          //         userId:
          //           connection?.message?.To ||
          //           parseInt(connection?.parameters?.From?.split(":")[1]),
          //         joinedAt:
          //           dayjs(callStartTime).format("HH:mm:ss.") +
          //           dayjs(callStartTime)
          //             .millisecond()
          //             .toString()
          //             .padEnd(7, "0"),
          //         callResponse:
          //           connection?.direction == "OUTGOING"
          //             ? "INCOMING"
          //             : "OUTGOING",
          //       },
          //     ],
          //   };
          //   await addCallRecords(inappPostedData);
          //   connection.disconnect();
          // }, 30000);
        });

        connection.on("disconnect", () => {
          setCallStatus("Call Ended");
          setActiveConnection(null);
          setIncomingCall(null);
          setCallAccepted(false);
          dispatch(setClearIncomingCall(true));
          dispatch(setAudioCallStart(false));
          dispatch(setShowChatSectionDuringCall(false));
          dispatch(setCallRunningInBG(false));
          dispatch(setIsReceiveMuted([]));
          setCallDuration(0);
          dispatch(setIsMuted(false));
          setCallStatus("");
          navigate("/chats");
        });
        connection.on("error", (error) => {
          // clearTimeout(ringTimeout);
          setCallDuration(0);
          if (error.message.includes("Requested device not found")) {
            openNoDeviceModal({
              title: "Message Status",
              message: "Microphone not found, kindly connect your device.",
              buttonText: "Close",
            });
          } else {
            // clearTimeout(ringTimeout);
            setCallDuration(0);
            console.error(`Twilio Device Error: ${error.message}`);
          }
        });
      }
    } else {
      setCallDuration(0);
      console.error("Failed to establish connection.");
    }
  };
  useEffect(() => {
    setCallStartTime(new Date().toISOString());
    let timer;
    if (callStatus === "Connected") {
      timer = setInterval(() => {
        setCallDuration((prev) => prev + 1);
      }, 1000);
    }
    return () => {
      clearInterval(timer);
    };
  }, [callStatus]);

  // For Outbound Calling Function is here

  const handleDialForOutboundCall = (to, callerName, direction, value) => {
    if (value) {
      dispatch(setDialingNumber(value));
    }
    dispatch(setAudioCallStart(true));
    if (device) {
      const params = {
        To: to,
        CallerName: callerName,
        Direction: direction,
        From: user_id,
      };
      const call = device.connect(params);
      if (call) {
        dispatch(setDialOpen(false));
        dispatch(setCallOpen(false));
        setCallStatus("Calling...");
        setActiveConnection(call);
        setIsCallActive(true);
        call.on("ringing", () => {
          setCallStatus("Ringing...");
        });

        call.on("disconnect", () => {
          setCallStatus("Call Ended");
          setActiveConnection(null);
          setIncomingCall(null);
          setCallAccepted(false);
          dispatch(setIsOnCall(false));
          dispatch(setClearIncomingCall(true));
          dispatch(setAudioCallStart(false));
          navigate("/chats");
          setCallStatus("");
        });
      }
    }
  };

  // For Group Calling Function is here

  const handleTrackSubscribed = (track) => {
    if (track.kind === "audio") {
      const audioElement = track.attach();
      document.body.appendChild(audioElement);
    }
  };

  const handleParticipantDisconnected = useCallback(
    async (participant) => {
      if (groupRoom) {
        groupRoom.localParticipant.tracks.forEach((publication) => {
          const { track } = publication;
          if (track && typeof track.stop === "function") {
            track.stop();
          }
          if (track) {
            groupRoom.localParticipant.unpublishTrack(track);
          }
        });

        // Detach and remove tracks from remote participants
        gCallParticipants.forEach((participant) => {
          participant.tracks.forEach((publication) => {
            const { track } = publication;
            if (track && (track.kind === "audio" || track.kind === "video")) {
              track.detach().forEach((element) => element.remove());
            }
          });
        });

        groupRoom.disconnect();
        setGroupRoom(null);
        setGCallParticipants([]);
        setMainParticipant(null);
        dispatch(setIsSendingRoomData([]));
        if (participant) {
          const resp = await getUsersById(participant?.identity);
          if (resp?.status === 200) {
            const dataUserName = resp?.data?.result?.[0];
            enqueueSnackbar(
              `${
                dataUserName &&
                dataUserName?.firstName + " " + dataUserName?.lastName
              } has left the room`,
              {
                variant: "info",
              }
            );
          }
        }
      } else {
        setGroupRoom(null);
        setGCallParticipants([]);
        dispatch(setIsSendingRoomData([]));
        setMainParticipant(null);
        gCallParticipants.forEach((participant) => {
          participant.tracks.forEach((publication) => {
            const { track } = publication;
            if (track && (track.kind === "audio" || track.kind === "video")) {
              track.detach().forEach((element) => element.remove());
            }
          });
        });
        if (participant) {
          const resp = await getUsersById(participant?.identity);
          if (resp?.status === 200) {
            const dataUserName = resp?.data?.result?.[0];
            enqueueSnackbar(
              `${
                dataUserName &&
                dataUserName?.firstName + " " + dataUserName?.lastName
              } has left the room`,
              {
                variant: "info",
              }
            );
          }
        }
      }
    },
    [gCallParticipants, groupRoom]
  );

  const handleJoinAsMainParticipant = async (
    user_id,
    getGroupData,
    getGroupName
  ) => {
    try {
      const uniqueRoomName = `GroupCallRoom-${user_id}`;
      const response = await getVideoCallToken(user_id);
      const token = response.data.token;
      const room = await connect(token, {
        name: uniqueRoomName,
        audio: true,
        video: false,
      });
      setGroupRoom(room);
      setMainParticipant(user_id);
      setGroupName(getGroupName);
      if (room) {
        const allowedUserIds = getGroupData?.map((item) => {
          if (typeof item === "object" && item !== null && "userId" in item) {
            return item.userId.toString();
          }
          return item.toString();
        });

        room.participants.forEach((participant) => {
          if (allowedUserIds.includes(participant.identity)) {
            handleParticipantConnected(participant, allowedUserIds);
          }
        });

        room.on("participantConnected", (participant) => {
          if (allowedUserIds.includes(participant.identity)) {
            handleParticipantConnected(participant, allowedUserIds);
          }
        });
        room.on("participantDisconnected", (participant) => {
          handleParticipantDisconnected(participant);
        });
        startGroupCall(getGroupName, uniqueRoomName, user_id, allowedUserIds);
        return () => {
          room.disconnect();
          setGroupRoom(null);
          setGCallParticipants([]);
          setMainParticipant(null);
        };
      }
    } catch (error) {
      if (error.message.includes("Requested device not found")) {
        openNoDeviceModal({
          title: "Message Status",
          message: "Microphone not found, kindly connect your device.",
          buttonText: "Close",
        });
      } else {
        console.error(`Error connecting to the room: ${error}`);
      }
    }
  };

  // const handleParticipantConnected = (participant, allowedUserIds) => {
  //   setGCallParticipants(async (prevParticipants) => {
  //     if (
  //       !prevParticipants.some((p) => p.identity == participant.identity) &&
  //       allowedUserIds?.includes(participant.identity)
  //     ) {
  //       participant.tracks.forEach((publication) => {
  //         if (publication.isSubscribed) {
  //           handleTrackSubscribed(publication.track);
  //         }
  //       });
  //       participant.on("trackSubscribed", handleTrackSubscribed);
  //       if (participant) {
  //         const resp = await getUsersById(participant?.identity);
  //         const dataUserName = resp?.data?.result?.[0];
  //         enqueueSnackbar(
  //           `${
  //             dataUserName &&
  //             dataUserName?.firstName + " " + dataUserName?.lastName
  //           } has joined the room`,
  //           {
  //             variant: "info",
  //           }
  //         );
  //       }

  //       return [...prevParticipants, participant];
  //     }
  //     return prevParticipants;
  //   });
  // };

  const handleParticipantConnected = async (participant, allowedUserIds) => {
    if (!allowedUserIds?.includes(participant.identity)) return;

    const isAlreadyConnected = gCallParticipants.some(
      (p) => p.identity === participant.identity
    );

    if (!isAlreadyConnected) {
      // Subscribe to existing and future tracks
      participant.tracks.forEach((publication) => {
        if (publication.isSubscribed) {
          handleTrackSubscribed(publication.track);
        }
      });
      participant.on("trackSubscribed", handleTrackSubscribed);

      // Fetch user information
      try {
        const resp = await getUsersById(participant.identity);
        if (resp?.status === 200) {
          const dataUserName = resp?.data?.result?.[0];
          if (dataUserName) {
            enqueueSnackbar(
              `${dataUserName.firstName} ${dataUserName.lastName} has joined the room`,
              { variant: "info" }
            );
          }
        }
      } catch (error) {
        console.error("Error fetching user info:", error);
      }

      // Update participants state
      setGCallParticipants((prevParticipants) => [
        ...prevParticipants,
        participant,
      ]);
    }
  };

  useEffect(() => {
    if (mainParticipant && groupRoom) {
      createLocalAudioTrack().then((track) => {
        groupRoom.localParticipant.publishTrack(track);
      });
    }
  }, [mainParticipant, groupRoom]);

  useEffect(() => {
    if (groupRoom) {
      const handleParticipantDisconnectedWrapper = (participant) => {
        handleParticipantDisconnected(participant);
      };

      const handleParticipantConnectedWrapper = (participant) => {
        const allowedUserIds = gCallParticipants.map((p) => p.identity);

        handleParticipantConnected(participant, allowedUserIds);
      };

      // Attach event listeners for participant connection/disconnection
      groupRoom.on("participantConnected", handleParticipantConnectedWrapper);
      groupRoom.on("participantDisconnected", (participant) => {
        if (!participant || !participant.identity) {
          console.error("Disconnected participant is invalid:", participant);
        } else {
          handleParticipantDisconnected(participant);
        }
      });

      return () => {
        groupRoom.off(
          "participantConnected",
          handleParticipantConnectedWrapper
        );
        groupRoom.off(
          "participantDisconnected",
          handleParticipantDisconnectedWrapper
        );
      };
    }
  }, [groupRoom, gCallParticipants]);

  // For Muted Group Participants Notification

  useEffect(() => {
    const track = new Video.LocalDataTrack();
    setDataTrack(track);
    if (groupRoom) {
      groupRoom.localParticipant.publishTrack(track);
    }
    groupRoom?.on("trackSubscribed", (track) => {
      if (track.kind === "data") {
        track.on("message", async (message) => {
          const parsedMessage = JSON.parse(message);

          if (parsedMessage.type === "MUTE_NOTIFICATION") {
            const isMuteChanged = previousMuteStatus != parsedMessage.muted;
            if (isMuteChanged) {
              const resp = await getUsersById(parsedMessage?.id);
              if (resp?.status === 200) {
                const dataUserName = resp?.data?.result?.[0];
                setPreviousMuteStatus(parsedMessage.muted);
                setRemoteMutedData(parsedMessage);
                enqueueSnackbar(
                  `${
                    dataUserName &&
                    dataUserName?.firstName + " " + dataUserName?.lastName
                  } has ${parsedMessage.muted ? "muted" : "unmuted"}.`,
                  { variant: "info" }
                );
              }
            }
          }
        });
      }
    });

    return () => {
      if (groupRoom && track) {
        try {
          if (
            track instanceof Video.LocalDataTrack &&
            groupRoom.localParticipant.hasTrack(track)
          ) {
            groupRoom.localParticipant.unpublishTrack(track);
          } else {
            console.warn("Track not found in localParticipant:", track);
          }
        } catch (error) {
          console.error("Error unpublishing track: ", error);
        }
      }
    };
  }, [groupRoom, previousMuteStatus]);

  useEffect(() => {
    const track = new Video.LocalDataTrack();
    setDataTrack(track);

    if (groupRoom) {
      groupRoom.localParticipant.publishTrack(track);
    }

    if (groupRoom) {
      groupRoom.on("trackSubscribed", (track) => {
        if (track.kind === "data") {
          track.on("message", (message) => {
            const data = JSON.parse(message);

            if (data.type == "joinRequest" && data.recipient == user_id) {
              enqueueSnackbar(
                `${data.sender} has invited you to join the group call.`,
                {
                  variant: "info",
                }
              );
            }
          });
        }
      });
    }

    return () => {
      if (groupRoom && track) {
        groupRoom.localParticipant.unpublishTrack(track);
      }
    };
  }, [groupRoom, user_id]);
  return (
    <TwilioContext.Provider
      value={{
        // fetchTwilioToken,
        handleDial,
        noDeviceModal,
        closeNoDeviceModal,
        openNoDeviceModal,
        handleJoinAsMainParticipant,
        handleParticipantDisconnected,
        mainParticipant,
        gCallParticipants,
        groupRoom,
        handleDialForOutboundCall,
        userOffline,
        callDuration,
        setCallDuration,
        callAccepted,
        setCallAccepted,
        activeConnection,
        setActiveConnection,
        incomingCall,
        setIncomingCall,
        callStatus,
        setCallStatus,
        callStartTime,
        setCallStartTime,
        dataTrack,
        setDataTrack,
        remoteMutedData,
        setRemoteMutedData,
        groupName,
      }}
    >
      {children}
    </TwilioContext.Provider>
  );
};

export const useTwilio = () => useContext(TwilioContext);

const calculateCallDuration = (endTime, startTime) => {
  const durationInMilliseconds = dayjs(`1970-01-01T${endTime}`).diff(
    dayjs(`1970-01-01T${startTime}`)
  );
  return Math.floor(durationInMilliseconds / 1000);
};
