import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Box,
  Typography,
  IconButton,
  Avatar,
  Modal,
  Button,
  Divider,
  Checkbox,
  TextField,
  InputAdornment,
} from "@mui/material";
import CallScreenBackground from "../../../../assets/images/CallScreenBackground.png";
import chatIcon from "../../../../assets/icons/ChatIcon.svg";
import shareScreen from "../../../../assets/icons/shareScreen.svg";
import AddPeople from "../../../../assets/icons/AddPeople.svg";
import record from "../../../../assets/icons/record.svg";
import VideocamRoundedIcon from "@mui/icons-material/VideocamRounded";
import VideocamOffRoundedIcon from "@mui/icons-material/VideocamOffRounded";
import CallEndRoundedIcon from "@mui/icons-material/CallEndRounded";
import MicOffRoundedIcon from "@mui/icons-material/MicOffRounded";
import MicRoundedIcon from "@mui/icons-material/MicRounded";
import { useDispatch, useSelector } from "react-redux";
import {
  setCallRunningInBG,
  setAudioCallStart,
  setShowChatSectionDuringCall,
  setUserById,
  setIsReceiveMuted,
  setIsReceiveRecording,
  setIsMuted,
  setContactImage,
  setCallLogsDetailsDispatch,
  setDialingNumber,
} from "../../../services/store/slice/CallSlice";
import StopCircleIcon from "@mui/icons-material/StopCircle";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import {
  addParticipantPostAPI,
  startRecordingAPI,
  stopRecordingAPI,
  getUserContactsByParams,
  getUsersById,
  getVideoCallToken,
  addCallRecords,
} from "../../../services/api/apiManager";
import SearchIcon from "@mui/icons-material/Search";
import { settings as s } from "../../../services/Settings";
import { connect, LocalVideoTrack } from "twilio-video";
import dayjs from "dayjs";
import { BgCallPopupContext } from "../../../services/context/BgCallPopupContext";
import MenuIcon from "@mui/icons-material/Menu";
import {
  SendMute,
  SendRecording,
  SendShareScreen,
} from "../../../services/signalRService/signalRService";
import BackspaceOutlinedIcon from "@mui/icons-material/BackspaceOutlined";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import GraphicEqIcon from "@mui/icons-material/GraphicEq";
import KeyboardHideIcon from "@mui/icons-material/KeyboardHide";
import { useTwilio } from "../../../services/context/TwilioContext";
function CallScreen() {
  const {
    userOffline,
    callDuration,
    setCallDuration,
    callAccepted,
    setCallAccepted,
    activeConnection,
    setActiveConnection,
    callStatus,
    setCallStatus,
    callStartTime,
  } = useTwilio();
  const { setIsCallActive } = BgCallPopupContext();
  const dispatch = useDispatch();
  const {
    showChatSectionDuringCall,
    userById,
    callerName,
    contactImage,
    isReceiveMuted,
    isReceiveRecording,
    isShareScreen,
    isMuted,
    dialingNumber,
  } = useSelector((state) => state.call);
  const user_id = localStorage.getItem("user_id");
  const [isCamera, setIsCamera] = React.useState(false);
  const [calllRecording, setCallRecording] = useState(null);
  const [nameMuted, setNameMuted] = useState([]);
  // Screen Sharing States
  const generateRoomName = (senderId, receiverId) => {
    if (!senderId || !receiverId) {
      return;
    }
    const sortedIds = [senderId, receiverId].sort().join("-");
    return `ScreenSharingRoom-${sortedIds}`;
  };
  const [isSharing, setIsSharing] = useState(false);
  const [isScreenSharing, setIsScreenSharing] = useState(false);
  const [room, setRoom] = useState(null);
  const localMediaRef = useRef(null);
  const remoteMediaRef = useRef(null);
  const localScreenTrackRef = useRef(null);
  const localParticipant = useRef(null);
  // Dial Pad for External Call
  const [dialOpen, setDialOpen] = React.useState(false);
  const [errValidMsg, setErrValidMsg] = React.useState("");
  const clickTimeout = useRef(null);
  const inputRef = useRef(null);
  const [display, setDisplay] = useState("");
  const handleDialModalClose = () => setDialOpen(false);
  const handleDialModalOpen = () => setDialOpen(true);
  const handleBackspace = () => {
    if (!inputRef.current) return;
    setErrValidMsg("");
    const cursorPosition = inputRef.current.selectionStart;

    if (cursorPosition > 0) {
      const newValue =
        display.slice(0, cursorPosition - 1) + display.slice(cursorPosition);
      setDisplay(newValue);

      setTimeout(() => {
        inputRef.current.selectionStart = cursorPosition - 1;
        inputRef.current.selectionEnd = cursorPosition - 1;
      }, 0);
    }
  };

  const numbersArray = [
    { number: "1", letters: "" },
    { number: "2", letters: "A B C" },
    { number: "3", letters: "D E F" },
    { number: "4", letters: "G H I" },
    { number: "5", letters: "J K L" },
    { number: "6", letters: "M N O" },
    { number: "7", letters: "P Q R S" },
    { number: "8", letters: "T U V" },
    { number: "9", letters: "W X Y Z" },
    { number: "*", letters: "" },
    { number: "0", letters: "+" },
    { number: "#", letters: "" },
  ];
  const handleNumberClick = async (number) => {
    if (number) {
      if (activeConnection) {
        activeConnection.sendDigits(number);
      }
    }
    if (!inputRef.current) return;
    const cursorPosition = inputRef.current.selectionStart;
    if (number === "0") {
      if (clickTimeout.current) {
        clearTimeout(clickTimeout.current);
        clickTimeout.current = null;
        const newDisplay =
          display.slice(0, cursorPosition) +
          "+" +
          display.slice(cursorPosition);
        setDisplay(newDisplay);

        setTimeout(() => {
          inputRef.current.selectionStart = cursorPosition + 1;
          inputRef.current.selectionEnd = cursorPosition + 1;
        }, 0);
      } else {
        clickTimeout.current = setTimeout(() => {
          const newDisplay =
            display.slice(0, cursorPosition) +
            "0" +
            display.slice(cursorPosition);
          setDisplay(newDisplay);
          setTimeout(() => {
            inputRef.current.selectionStart = cursorPosition + 1;
            inputRef.current.selectionEnd = cursorPosition + 1;
          }, 0);

          clickTimeout.current = null;
        }, 200);
      }
    } else {
      const newDisplay =
        display.slice(0, cursorPosition) +
        number +
        display.slice(cursorPosition);
      setDisplay(newDisplay);

      setTimeout(() => {
        inputRef.current.selectionStart = cursorPosition + 1;
        inputRef.current.selectionEnd = cursorPosition + 1;
      }, 0);
    }
  };

  const handleInputChange = (event) => {
    if (event.target.value) {
      setErrValidMsg("");
    }
    const inputValue = event.target.value;
    const filteredValue = inputValue.replace(/[^0-9*#+]/g, "");
    if (filteredValue) {
      setDisplay(filteredValue);
    }
  };

  const handleCloseDialPad = () => {
    setDisplay("");
    setErrValidMsg("");
    handleDialModalClose();
  };
  // Get User By Id data

  const fetchToken = async (activeConnection) => {
    try {
      const userId =
        activeConnection?.parameters?.From?.split(":")[1] ||
        activeConnection?.message?.To;
      if (typeof userId === "string" && userId?.startsWith("+")) {
        dispatch(setUserById(null));
        return;
      } else {
        const response = await getUsersById(userId);
        dispatch(setUserById(response?.data?.result?.[0]));
        return;
      }
    } catch (error) {
      console.error(`Error:`, error);
    }
  };
  useEffect(() => {
    if (activeConnection) {
      fetchToken(activeConnection);
    }
  }, [activeConnection]);

  // region Add Participant
  const [addparticipantModal, setAddparticipantModal] = React.useState(false);
  const handleAddParticipantOpen = () => setAddparticipantModal(true);
  const handleAddParticipantClose = () => setAddparticipantModal(false);
  const [userContactList, setUserContactList] = React.useState([]);
  const [searchParticipant, setSearchParticipant] = React.useState("");
  const [checkedIds, setCheckedIds] = React.useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [isFetching, setIsFetching] = useState(false);
  const Rows = 20;
  const [hasMore, setHasMore] = useState(true);
  // const FilterDataOfParticipant = userContactList?.filter((i) => {
  //   const firstName = i?.firstName?.toLowerCase() || "";
  //   const lastName = i?.lastName?.toLowerCase() || "";
  //   return (
  //     firstName.includes(searchParticipant.toLowerCase()) ||
  //     lastName.includes(searchParticipant.toLowerCase())
  //   );
  // });

  const handleCheckboxChange = (userId) => {
    const stringifiedUserId = String(userId);
    setCheckedIds((prev) => {
      if (prev.includes(stringifiedUserId)) {
        return prev.filter((id) => id !== stringifiedUserId);
      } else {
        return [...prev, stringifiedUserId];
      }
    });
  };

  const fetchContacts = useCallback(
    async (isSearch = false) => {
      if (!isSearch && !hasMore) return;

      setIsFetching(true);
      try {
        const response = await getUserContactsByParams(
          user_id,
          Rows,
          pageNumber,
          false,
          false,
          searchParticipant
        );
        const newContacts = response?.data?.result;
        setUserContactList((prev) =>
          isSearch ? newContacts : [...prev, ...newContacts]
        );
        setHasMore(newContacts?.length == Rows);
      } catch (error) {
        console.error("Error fetching contacts:", error);
      } finally {
        setIsFetching(false);
      }
    },
    [user_id, Rows, pageNumber, searchParticipant, hasMore]
  );
  useEffect(() => {
    if (searchParticipant !== "") {
      setPageNumber(1);
      fetchContacts(true);
    } else {
      fetchContacts();
    }
  }, [searchParticipant, fetchContacts]);

  const handleScroll = (e) => {
    const { scrollTop, scrollHeight, clientHeight } = e.target;
    if (scrollHeight - scrollTop <= clientHeight + 50 && !isFetching) {
      setPageNumber((prev) => prev + 1);
    }
  };

  const addParticipantMember = async (checkedIds) => {
    const callSid = activeConnection?.parameters?.CallSid;
    const data = {
      callSid: callSid,
      From: user_id,
      To: checkedIds?.[0],
    };
    await addParticipantPostAPI(data);
  };

  // region Call Audio Recording
  const [isRecording, setIsRecording] = React.useState(false);
  const [recordingSid, setRecordingSid] = React.useState("");
  const onToggleRecording = async () => {
    const data = {
      callSid: activeConnection?.parameters?.CallSid,
    };
    if (isRecording) {
      const dataOfStopRecording = {
        callSid: activeConnection?.parameters?.CallSid,
        recordingSid: recordingSid,
      };
      setIsRecording(false);
      SendRecording(
        activeConnection?.message?.To === undefined
          ? parseInt(
              activeConnection?.parameters?.From.replace("client:", ""),
              10
            )
          : activeConnection?.message?.To,
        parseInt(user_id),
        data?.callSid,
        false
      );
      const res = await stopRecordingAPI(dataOfStopRecording);
      if (res?.status === 200) {
        setTimeout(() => {
          setCallRecording(res.data.recordingUrl);
        }, 2000);
      }
    } else {
      setIsRecording(true);
      SendRecording(
        activeConnection?.message?.To === undefined
          ? parseInt(
              activeConnection?.parameters?.From.replace("client:", ""),
              10
            )
          : activeConnection?.message?.To,
        parseInt(user_id),
        data?.callSid,
        true
      );
      const response = await startRecordingAPI(data);
      setRecordingSid(response?.data?.recordingSid);
    }
  };
  // recording call download function
  const handleDownload = async (callRecordingURL) => {
    try {
      const response = await fetch(callRecordingURL);
      const blob = await response.blob();
      const blobUrl = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = blobUrl;
      a.download = `call Recording ${dayjs(new Date()).format(
        "MM/DD/YYYY hh:mm:ss"
      )}.mp3`;
      document.body.appendChild(a);
      a.click();
      a.remove();
      window.URL.revokeObjectURL(blobUrl);
    } catch (error) {
      console.error("Download failed", error);
    }
  };
  useEffect(() => {
    if (calllRecording !== null) {
      handleDownload(calllRecording);
    }
  }, [calllRecording]);
  // region Muted Mic

  const onToggleMute = () => {
    if (isMuted === false) {
      dispatch(setIsMuted(true));
      SendMute(
        activeConnection?.message?.To === undefined
          ? parseInt(
              activeConnection?.parameters?.From.replace("client:", ""),
              10
            )
          : activeConnection?.message?.To,
        parseInt(user_id),
        true
      );
      activeConnection.mute(true);
    } else {
      dispatch(setIsMuted(false));
      SendMute(
        activeConnection?.message?.To === undefined
          ? parseInt(
              activeConnection?.parameters?.From.replace("client:", ""),
              10
            )
          : activeConnection?.message?.To,
        parseInt(user_id),
        false
      );
      activeConnection.mute(false);
    }
  };

  const onToggleCamera = () => {
    if (isCamera === false) {
      setIsCamera(true);
    } else {
      setIsCamera(false);
    }
  };

  // Posting AddUserCallRecordsAPIdata

  const inappPostedData = {
    // conversationId: 1,
    callType: "Single",
    callTitle: activeConnection?.message?.CallerName,
    CreatedBy: parseInt(user_id),
    STARTTIME:
      callDuration == 0
        ? "00:00:00.0000000"
        : dayjs(callStartTime).format("HH:mm:ss.") +
          dayjs(callStartTime).millisecond().toString().padEnd(7, "0"),
    ENDTIME:
      callDuration == 0
        ? "00:00:00.0000000"
        : dayjs(new Date()).format("HH:mm:ss.") +
          dayjs(new Date()).millisecond().toString().padEnd(7, "0"),
    CALLSTATUS: callDuration == 0 ? "Busy" : "Completed",
    DURATION:
      callDuration == 0
        ? 0
        : calculateCallDuration(
            dayjs(new Date()).format("HH:mm:ss.") +
              dayjs(new Date()).millisecond().toString().padEnd(7, "0"),
            dayjs(callStartTime).format("HH:mm:ss.") +
              dayjs(callStartTime).millisecond().toString().padEnd(7, "0")
          ),
    callParticipants: [
      {
        userId: parseInt(user_id),
        joinedAt:
          dayjs(callStartTime).format("HH:mm:ss.") +
          dayjs(callStartTime).millisecond().toString().padEnd(7, "0"),
        callResponse: activeConnection?.direction,
      },
      {
        userId:
          activeConnection?.message?.To ||
          parseInt(activeConnection?.parameters?.From?.split(":")[1]),
        joinedAt:
          dayjs(callStartTime).format("HH:mm:ss.") +
          dayjs(callStartTime).millisecond().toString().padEnd(7, "0"),
        callResponse:
          activeConnection?.direction == "OUTGOING" ? "INCOMING" : "OUTGOING",
      },
    ],
  };

  // second Object For ExternalNumberCalling

  const outboundPostedData = {
    callType: "Single",
    // callTitle: activeConnection?.message?.CallerName,
    callTitle: "nothing",
    CreatedBy: parseInt(user_id),
    STARTTIME:
      callDuration == 0
        ? "00:00:00.0000000"
        : dayjs(callStartTime).format("HH:mm:ss.") +
          dayjs(callStartTime).millisecond().toString().padEnd(7, "0"),
    ENDTIME:
      callDuration == 0
        ? "00:00:00.0000000"
        : dayjs(new Date()).format("HH:mm:ss.") +
          dayjs(new Date()).millisecond().toString().padEnd(7, "0"),
    CALLSTATUS: callDuration == 0 ? "Busy" : "Completed",
    DURATION:
      callDuration == 0
        ? 0
        : calculateCallDuration(
            dayjs(new Date()).format("HH:mm:ss.") +
              dayjs(new Date()).millisecond().toString().padEnd(7, "0"),
            dayjs(callStartTime).format("HH:mm:ss.") +
              dayjs(callStartTime).millisecond().toString().padEnd(7, "0")
          ),
    callParticipants: [
      {
        userId: parseInt(user_id),
        joinedAt:
          dayjs(callStartTime).format("HH:mm:ss.") +
          dayjs(callStartTime).millisecond().toString().padEnd(7, "0"),
        callResponse: activeConnection?.direction,
        ExternalPersonContactNo:
          activeConnection?.direction === "OUTGOING" &&
          typeof activeConnection?.message?.To === "string" &&
          activeConnection?.message?.To.startsWith("+")
            ? activeConnection.message.To
            : dialingNumber,
        CreatedBy: parseInt(user_id),
      },
    ],
  };

  const inboundPostedData = {
    callType: "Single",
    callTitle: "nothing",
    CreatedBy: parseInt(user_id),
    STARTTIME:
      callDuration == 0
        ? "00:00:00.0000000"
        : dayjs(callStartTime).format("HH:mm:ss.") +
          dayjs(callStartTime).millisecond().toString().padEnd(7, "0"),
    ENDTIME:
      callDuration == 0
        ? "00:00:00.0000000"
        : dayjs(new Date()).format("HH:mm:ss.") +
          dayjs(new Date()).millisecond().toString().padEnd(7, "0"),
    CALLSTATUS: callDuration == 0 ? "Busy" : "Completed",
    DURATION:
      callDuration == 0
        ? 0
        : calculateCallDuration(
            dayjs(new Date()).format("HH:mm:ss.") +
              dayjs(new Date()).millisecond().toString().padEnd(7, "0"),
            dayjs(callStartTime).format("HH:mm:ss.") +
              dayjs(callStartTime).millisecond().toString().padEnd(7, "0")
          ),
    callParticipants: [
      {
        userId: parseInt(user_id),
        joinedAt:
          dayjs(callStartTime).format("HH:mm:ss.") +
          dayjs(callStartTime).millisecond().toString().padEnd(7, "0"),
        callResponse: activeConnection?.direction,
        ExternalPersonContactNo: activeConnection?.parameters?.From,
        CreatedBy: null,
      },
    ],
  };
  //region addCallRecords
  const addCallRecordsApi = async () => {
    if (
      (activeConnection?.direction == "OUTGOING" &&
        typeof activeConnection?.message?.To === "string" &&
        activeConnection?.message?.To?.startsWith("+")) ||
      dialingNumber
    ) {
      await addCallRecords(outboundPostedData);
    } else if (
      activeConnection?.direction == "INCOMING" &&
      typeof activeConnection?.message?.To === "string" &&
      activeConnection?.parameters?.From?.startsWith("+")
    ) {
      await addCallRecords(inboundPostedData);
    } else {
      if (inappPostedData) {
        let timer = setTimeout(async () => {
          await addCallRecords(inappPostedData);
        }, 300);
        return () => clearTimeout(timer);
      }
    }
  };
  // Screen Sharing Button

  const onToggleScreenShare = () => {
    if (!isSharing) {
      startScreenShare();
    } else {
      stopScreenShare();
    }
  };

  const onEndCall = () => {
    // Disconnect from the room
    dispatch(setUserById(null));
    dispatch(setContactImage(null));
    // Stop screen sharing if active
    // if (isScreenSharing) {
    // stopScreenShare();
    // }
    if (room) {
      cleanupRoom();
    }
    if (activeConnection) {
      activeConnection.disconnect();
      addCallRecordsApi();
      setCallStatus("Call Ended");
      setActiveConnection(null);
      setCallAccepted(false);
      setCallDuration(0);
      setIsCallActive(false);
    }
    stopScreenShare();
    dispatch(setAudioCallStart(false));
    dispatch(setShowChatSectionDuringCall(false));
    dispatch(setCallRunningInBG(false));
    dispatch(setIsReceiveMuted([]));
    dispatch(setIsReceiveRecording([]));
    setCallDuration(0);
    dispatch(setDialingNumber(""));
    dispatch(setCallLogsDetailsDispatch(true));
    dispatch(setIsMuted(false));
    setCallStatus("");
  };

  useEffect(() => {
    if (callAccepted === true) {
      setCallDuration(0);
      setCallStatus("Connected");
    }
  }, [callAccepted]);

  // Format duration to MM:SS
  const formatDuration = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${String(minutes).padStart(2, "0")}:${String(secs).padStart(
      2,
      "0"
    )}`;
  };

  // region Screen Sharing

  const startScreenShare = async () => {
    if (isScreenSharing) {
      return;
    }

    try {
      const senderId = user_id;
      const receiverId =
        activeConnection?.message?.To ||
        parseInt(activeConnection?.parameters?.From.replace("client:", ""), 10);

      const uniqueRoomName = generateRoomName(senderId, receiverId);

      const stream = await navigator.mediaDevices.getDisplayMedia({
        video: {
          cursor: "always",
          displaySurface: "monitor",
        },
      });
      const screenTrack = stream.getTracks()[0];
      const twilioScreenTrack = new LocalVideoTrack(screenTrack);
      localScreenTrackRef.current = twilioScreenTrack;
      if (room && localParticipant.current) {
        room.localParticipant.publishTrack(twilioScreenTrack);
        setIsScreenSharing(true);
        setIsSharing(true);
        if (localMediaRef.current) {
          localMediaRef.current.innerHTML = "";
          localMediaRef.current.appendChild(twilioScreenTrack.attach());
        }
        const targetUserId = activeConnection?.message?.To
          ? activeConnection?.message?.To
          : parseInt(
              activeConnection?.parameters?.From.replace("client:", ""),
              10
            );
        SendShareScreen(
          targetUserId,
          parseInt(user_id),
          activeConnection?.parameters?.CallSid,
          true,
          uniqueRoomName
        );
      }
    } catch (error) {
      console.error("Failed to start screen sharing: ", error);
    }
  };

  const stopScreenShare = () => {
    if (!isScreenSharing || !room) {
      return;
    }
    if (localScreenTrackRef.current) {
      localScreenTrackRef.current.stop();
      room.localParticipant.unpublishTrack(localScreenTrackRef.current);
      localScreenTrackRef.current = null;
    }

    room.participants.forEach((participant) => {
      participant.tracks.forEach((publication) => {
        if (publication.track && publication.track.kind === "video") {
          const attachedElements = publication.track.detach();
          attachedElements.forEach((element) => element.remove());
          publication.track.stop();
        }
      });
    });

    const senderId = user_id;
    const receiverId =
      activeConnection?.message?.To ||
      parseInt(activeConnection?.parameters?.From.replace("client:", ""), 10);
    const uniqueRoomName = generateRoomName(senderId, receiverId);
    const targetUserId = activeConnection?.message?.To
      ? activeConnection?.message?.To
      : parseInt(activeConnection?.parameters?.From.replace("client:", ""), 10);

    SendShareScreen(
      targetUserId,
      parseInt(user_id),
      activeConnection?.parameters?.CallSid,
      false,
      uniqueRoomName
    );

    setIsScreenSharing(false);
    setIsSharing(false);
  };

  const addRemoteParticipantTracks = (participant) => {
    participant.tracks.forEach((publication) => {
      if (publication.isSubscribed && publication.track.kind === "video") {
        const videoElement = publication.track.attach();
        videoElement.style.width = "100%";
        videoElement.style.height = "100%";
        videoElement.style.objectFit = "contain";
        if (remoteMediaRef.current) {
          remoteMediaRef.current.innerHTML = "";
          remoteMediaRef.current.appendChild(videoElement);
        }
      }
    });

    participant.on("trackSubscribed", (track) => {
      if (track.kind === "video") {
        const videoElement = track.attach();
        videoElement.style.width = "100%";
        videoElement.style.height = "100%";
        videoElement.style.objectFit = "contain";
        if (remoteMediaRef.current) {
          remoteMediaRef.current.innerHTML = "";
          remoteMediaRef.current.appendChild(videoElement);
        }
      }
    });

    participant.on("trackUnsubscribed", (track) => {
      if (track.kind === "video" || track.kind === "audio") {
        if (remoteMediaRef.current) {
          const attachedElements = track.detach();
          attachedElements.forEach((element) => element.remove());
        }
      }
    });
  };

  const removeRemoteParticipantTracks = (participant) => {
    participant.tracks.forEach((publication) => {
      if (publication.track && publication.track.detach) {
        const attachedElements = publication.track.detach();
        attachedElements.forEach((element) => element.remove());
      }
    });
  };

  const cleanupRoom = () => {
    if (room) {
      // Disconnect all participants
      room.localParticipant.tracks.forEach((publication) => {
        if (publication.track) {
          publication.track.stop();
          publication.unpublish();
        }
      });

      room.participants.forEach((participant) => {
        participant.tracks.forEach((publication) => {
          if (publication.track) {
            const attachedElements = publication.track.detach();
            attachedElements.forEach((element) => element.remove());
            // publication.track.stop();
          }
        });
      });
      room.disconnect();
      // Clean up DOM elements
      if (localMediaRef.current) localMediaRef.current.innerHTML = "";
      if (remoteMediaRef.current) remoteMediaRef.current.innerHTML = "";

      // Reset room state
      setRoom(null);
      setIsScreenSharing(false);
      setIsSharing(false);
    }
  };

  const joinRoom = async (userId, uniqueRoomName, targetReceiverId) => {
    if (room) {
      return;
    }
    try {
      const response = await getVideoCallToken(userId, uniqueRoomName);
      const token = response.data.token;
      const connectedRoom = await connect(token, {
        room: uniqueRoomName,
        video: false,
      });

      connectedRoom.participants.forEach((participant) => {
        // addRemoteParticipantTracks(participant);
        if (participant.identity == targetReceiverId) {
          addRemoteParticipantTracks(participant);
        } else {
          participant.disconnect();
        }
      });
      connectedRoom.on("participantConnected", (participant) => {
        // addRemoteParticipantTracks(participant);
        if (participant.identity == targetReceiverId) {
          addRemoteParticipantTracks(participant);
        }
      });

      connectedRoom.on("disconnected", () => {
        if (localMediaRef.current) localMediaRef.current.innerHTML = "";
        if (remoteMediaRef.current) remoteMediaRef.current.innerHTML = "";
        setRoom(null);
        cleanupRoom();
      });

      connectedRoom.on("participantDisconnected", (participant) => {
        if (participant) {
          removeRemoteParticipantTracks(participant);
        }
      });
      setRoom(connectedRoom);
      localParticipant.current = connectedRoom.localParticipant;
    } catch (error) {
      console.error(`Unable to connect to the room: ${error.message}`);
    }
  };

  useEffect(() => {
    const senderId = user_id;
    const receiverId =
      activeConnection?.message?.To ||
      (activeConnection?.parameters?.From &&
        parseInt(
          activeConnection?.parameters?.From.replace("client:", ""),
          10
        ));
    const uniqueRoomName = generateRoomName(senderId, receiverId);
    if (user_id && uniqueRoomName && activeConnection) {
      if (
        (typeof activeConnection?.message?.To === "string" &&
          !activeConnection?.message?.To?.startsWith("+")) ||
        (typeof activeConnection?.message?.To === "string" &&
          !activeConnection?.parameters?.From?.startsWith("+"))
      ) {
        return;
      } else {
        joinRoom(
          user_id,
          uniqueRoomName,
          activeConnection?.message?.To
            ? activeConnection?.message?.To
            : activeConnection?.parameters?.From &&
                parseInt(
                  activeConnection?.parameters?.From.replace("client:", ""),
                  10
                )
        );
      }
    }
  }, [user_id, activeConnection]);

  // Notify Recording & muted

  useEffect(() => {
    const fetchToken = async (isReceiveMuted, isReceiveRecording) => {
      try {
        const response = await getUsersById(
          isReceiveMuted?.UserId || isReceiveRecording?.UserId
        );
        setNameMuted(response?.data?.result?.[0]);
      } catch (error) {
        console.log(`Error:`, error);
      }
    };
    if (isReceiveMuted || isReceiveRecording) {
      fetchToken(isReceiveMuted, isReceiveRecording);
    }
  }, [isReceiveMuted, isReceiveRecording]);

  // For user Offline

  const userOfflinePostedData = {
    callType: "Single",
    callTitle: activeConnection?.message?.CallerName
      ? activeConnection?.message?.CallerName.slice(0, 11)
      : "User",
    CreatedBy: parseInt(user_id),
    STARTTIME:
      callDuration == 0
        ? "00:00:00.0000000"
        : dayjs(callStartTime).format("HH:mm:ss.") +
          dayjs(callStartTime).millisecond().toString().padEnd(7, "0"),
    ENDTIME:
      callDuration == 0
        ? "00:00:00.0000000"
        : dayjs(new Date()).format("HH:mm:ss.") +
          dayjs(new Date()).millisecond().toString().padEnd(7, "0"),
    CALLSTATUS: callDuration == 0 ? "Busy" : "Completed",
    DURATION:
      callDuration == 0
        ? 0
        : calculateCallDuration(
            dayjs(new Date()).format("HH:mm:ss.") +
              dayjs(new Date()).millisecond().toString().padEnd(7, "0"),
            dayjs(callStartTime).format("HH:mm:ss.") +
              dayjs(callStartTime).millisecond().toString().padEnd(7, "0")
          ),
    callParticipants: [
      {
        userId: parseInt(user_id),
        joinedAt:
          dayjs(callStartTime).format("HH:mm:ss.") +
          dayjs(callStartTime).millisecond().toString().padEnd(7, "0"),
        callResponse: activeConnection?.direction,
      },
      {
        userId:
          activeConnection?.message?.To ||
          parseInt(activeConnection?.parameters?.From?.split(":")[1]),
        joinedAt:
          dayjs(callStartTime).format("HH:mm:ss.") +
          dayjs(callStartTime).millisecond().toString().padEnd(7, "0"),
        callResponse:
          activeConnection?.direction == "OUTGOING" ? "INCOMING" : "OUTGOING",
      },
    ],
  };
  const handleUserOffline = async () => {
    try {
      await addCallRecords(userOfflinePostedData);
    } catch (error) {
      console.error("Error while adding call records:", error);
    }
  };
  useEffect(() => {
    if (userOffline === true) {
      let timer = setTimeout(() => {
        handleUserOffline();
      }, 300);
      return () => clearTimeout(timer);
    }
  }, [userOffline]);

  return (
    <>
      <Box display={"flex"}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "space-between",
            height: "100vh",
            width: "100%",
            bgcolor: "#2A2A36",
            backgroundImage: `url(${CallScreenBackground})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            pl: {
              xs: "45px",
              sm: "10px",
              md: "20px",
              lg: "40px",
              xl: "60px",
            },
            pr: {
              xs: "45px",
              sm: "10px",
              md: "20px",
              lg: "40px",
              xl: "60px",
            },
          }}
        >
          <Box
            height={"30px"}
            width={"100%"}
            color={"white"}
            bgcolor={"transparent"}
            alignItems={"center"}
            marginRight={"4%"}
          >
            <MenuIcon
              sx={{ color: "wheat", cursor: "pointer" }}
              onClick={() => {
                // dispatch(setCallRunningInBG(true));
                if (showChatSectionDuringCall) {
                  dispatch(setShowChatSectionDuringCall(false));
                } else {
                  dispatch(setShowChatSectionDuringCall(true));
                }
              }}
            />
          </Box>
          {/* For Remote Screen Share */}
          <Box
            display={isShareScreen?.CallId == user_id ? "block" : "none"}
            ref={remoteMediaRef}
            sx={{
              position: "absolute",
              top: "30px",
              bottom: "20px",
              width: "93%",
              height: "84vh",
              marginBottom: 2,
              zIndex: 1000,
            }}
          >
            <video
              style={{
                width: "100%",
                height: "100%",
                objectFit: "contain",
                transform: "scale(1)",
              }}
              autoPlay
              playsInline
            />
          </Box>

          {/* For Remote Muted Box */}
          {isReceiveMuted && isReceiveMuted?.CallId == user_id && (
            <Box
              sx={{
                position: "absolute",
                top: "20px",
                right: "10px",
                width: "auto",
                padding: "10px 20px",
                backgroundColor: "black",
                color: "white",
                borderRadius: "5px",
                boxShadow: "0 2px 10px rgba(0, 0, 0, 0.5)",
                display:
                  isReceiveMuted?.isMuted === true &&
                  isReceiveMuted?.CallId == user_id
                    ? "block"
                    : "none",
                zIndex: 1000,
                transition: "transform 0.5s ease, opacity 0.5s ease",
                transform: isMuted ? "translateY(0)" : "translateY(100%)",
                opacity:
                  isReceiveMuted?.isMuted === true &&
                  isReceiveMuted?.CallId == user_id
                    ? 1
                    : 0,
              }}
            >
              {isReceiveMuted?.isMuted === true &&
                isReceiveMuted?.CallId == user_id && (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      gap: 1,
                    }}
                  >
                    <span>{`${nameMuted?.firstName} ${nameMuted?.lastName} is Muted...`}</span>
                    <MicOffRoundedIcon
                      style={{ fontSize: "20px", color: "#818090" }}
                    />
                  </Box>
                )}
            </Box>
          )}
          {/* For Local Muted Box */}
          {isMuted === true && (
            <Box
              sx={{
                position: "absolute",
                bottom: "20px",
                left: "80px",
                width: "auto",
                padding: "10px 20px",
                backgroundColor: "black",
                color: "white",
                borderRadius: "5px",
                boxShadow: "0 2px 10px rgba(0, 0, 0, 0.5)",
                display: isMuted === true ? "block" : "none",
                zIndex: 1000,
                transition: "transform 0.5s ease, opacity 0.5s ease",
                transform: isMuted ? "translateY(0)" : "translateY(100%)",
                opacity: isMuted === true ? 1 : 0,
              }}
            >
              {isMuted === true && (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    gap: 1,
                  }}
                >
                  <span>{`Mic is Muted...`}</span>
                  <MicOffRoundedIcon
                    style={{ fontSize: "20px", color: "#818090" }}
                  />
                </Box>
              )}
            </Box>
          )}
          {/* For Receiving Recording Box */}
          {isReceiveRecording && isReceiveRecording?.CallId == user_id && (
            <Box
              sx={{
                position: "absolute",
                top: "20px",
                width: "auto",
                padding: "10px 20px",
                backgroundColor: "black",
                color: "white",
                borderRadius: "5px",
                boxShadow: "0 2px 10px rgba(0, 0, 0, 0.5)",
                display:
                  isReceiveRecording?.IsRecord === true &&
                  isReceiveRecording?.CallId == user_id
                    ? "block"
                    : "none",
                zIndex: 1000,
                transition: "transform 0.5s ease, opacity 0.5s ease",
                transform: isMuted ? "translateY(0)" : "translateY(100%)",
                opacity:
                  isReceiveRecording?.IsRecord === true &&
                  isReceiveRecording?.CallId == user_id
                    ? 1
                    : 0,
              }}
            >
              {isReceiveRecording?.IsRecord === true &&
                isReceiveRecording?.CallId == user_id && (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      gap: 1,
                    }}
                  >
                    <GraphicEqIcon
                      style={{ fontSize: "20px", color: "#818090" }}
                    />
                    <span>{` Recording started by ${nameMuted?.firstName} ${nameMuted?.lastName}`}</span>
                  </Box>
                )}
            </Box>
          )}
          {/* For Sending Recording Box */}
          {isRecording && (
            <Box
              sx={{
                position: "absolute",
                top: "20px",
                width: "auto",
                padding: "10px 20px",
                backgroundColor: "black",
                color: "white",
                borderRadius: "5px",
                boxShadow: "0 2px 10px rgba(0, 0, 0, 0.5)",
                display: isRecording === true ? "block" : "none",
                zIndex: 1000,
                transition: "transform 0.5s ease, opacity 0.5s ease",
                transform: isMuted ? "translateY(0)" : "translateY(100%)",
                opacity: isRecording === true ? 1 : 0,
              }}
            >
              {isRecording === true && (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    gap: 1,
                  }}
                >
                  <GraphicEqIcon
                    style={{ fontSize: "20px", color: "#818090" }}
                  />
                  <span>{` Recording start...`}</span>
                </Box>
              )}
            </Box>
          )}
          {/* <Box
            ref={localMediaRef}
            sx={{
              position: "absolute",
              top: isScreenSharing ? "0" : "auto",
              right: 0,
              maxWidth: "300px",
              height: "50vh",
              aspectRatio: "16/9",
              backgroundColor: "black",
              overflow: "hidden",
              margin: 2,
              display: isScreenSharing ? "block" : "none",
              zIndex: 1000,
              transition: "top 0.5s ease, transform 0.5s ease",
              transform: isScreenSharing ? "translateY(0)" : "translateY(100%)",
            }}
          ></Box> */}
          {/* Caller Information */}
          <Box
            sx={{
              borderRadius: "50%",
              border: {
                xs: "15px solid rgba(108, 108, 116, 0.9)",
                sm: "22px solid rgba(108, 108, 116, 0.9)",
              },
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Box
              sx={{
                borderRadius: "50%",
                border: {
                  xs: "10px solid #9A9A9F",
                  sm: "12px solid #9A9A9F",
                },
              }}
            >
              <Avatar
                sx={{
                  fontSize: "30px",
                  fontWeight: "bold",
                  cursor: "pointer",
                  width: { xs: 80, sm: 100 },
                  height: { xs: 80, sm: 100 },
                  objectFit: "cover",
                }}
                alt="Profile"
                src={
                  userById?.profilePicPath
                    ? `${s.baseUrl}${userById?.profilePicPath}`
                    : contactImage
                    ? `${s.baseUrl}${contactImage}`
                    : null
                }
                loading="lazy"
              >
                {!userById?.profilePicPath &&
                activeConnection?.parameters &&
                typeof activeConnection?.parameters?.From == "string" &&
                activeConnection?.parameters?.From?.startsWith("+")
                  ? `${activeConnection?.message?.From?.slice(0, 2)}`
                  : activeConnection?.parameters &&
                    typeof activeConnection?.message?.To === "string" &&
                    activeConnection?.message?.To?.startsWith("+")
                  ? `${activeConnection?.message?.To?.slice(0, 2)}`
                  : callerName && callerName.charAt(0)}
              </Avatar>
            </Box>
          </Box>
          <Box
            sx={{
              textAlign: "center",
              fontFamily: "Poppins",
            }}
          >
            <Typography
              sx={{
                fontWeight: "550",
                color: "#FFFFFF",
                fontSize: {
                  xs: "12px",
                  sm: "14px",
                  md: "14px",
                  lg: "14px",
                  xl: "22px",
                },
              }}
            >
              {(activeConnection?.parameters &&
                typeof activeConnection?.parameters?.From === "string" &&
                activeConnection?.parameters?.From?.startsWith("+")) ||
              (typeof activeConnection?.message?.To === "string" &&
                activeConnection?.message?.To?.startsWith("+"))
                ? activeConnection?.parameters?.From?.startsWith("+")
                  ? activeConnection.parameters.From
                  : activeConnection.message.To
                : userById?.firstName && userById?.lastName
                ? `${userById.firstName} ${userById.lastName}`
                : callerName}
            </Typography>
            {callStatus === "Connected" && (
              <Typography
                variant="body2"
                sx={{
                  color: "#F3F3F3",
                  marginTop: "2px",
                  fontWeight: "400",
                  fontSize: {
                    xs: "12px",
                    sm: "14px",
                    md: "14px",
                    lg: "14px",
                    xl: "22px",
                  },
                }}
              >
                {formatDuration(callDuration)}
              </Typography>
            )}

            {/* {callStatus && ( */}
            <Typography
              variant="body2"
              sx={{
                color: "#B0B0B0",
                fontSize: {
                  xs: "12px",
                  sm: "15px",
                  md: "14px",
                  lg: "14px",
                  xl: "22px",
                },
                marginTop: "5px",
                fontWeight: "400",
              }}
            >
              {callStatus}
            </Typography>
            {/* )} */}
          </Box>
          {/* Action Buttons */}
          <Box
            sx={{
              mt: { xs: "2rem", sm: "5rem" },
              mb: { xs: "1rem", sm: "1.5rem" },
              width: "100%",
              display: "flex",
              flexDirection: { xs: "column", sm: "row" },
              justifyContent: "space-between",
              pl: "10px",
              pr: "10px",
            }}
          >
            {/* Left Box */}
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                color: "#B0B0B0",
                fontFamily: "Poppins",
                textAlign: "center",
                mb: { xs: "1rem", sm: "0" },
              }}
            >
              <IconButton
                color="primary"
                // onClick={onChatMenu}
                sx={{
                  "&:hover": {
                    boxShadow: "none",
                    backgroundColor: "transparent",
                  },
                  "&.Mui-focusVisible": {
                    boxShadow: "none",
                  },
                }}
              >
                <Box
                  sx={{
                    borderRadius: "50%",
                    width: 33,
                    height: 33,
                    border: "1px solid #1E1F24",
                    backgroundColor: "#1E1F24",
                    overflow: "hidden",
                    display: "none",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <img
                    src={chatIcon}
                    alt="Chat Icon"
                    style={{ width: 23, height: 23 }}
                  />
                </Box>
              </IconButton>
              <Typography
                sx={{ fontSize: { xs: "10px", sm: "12px" }, display: "none" }}
              >
                Chat
              </Typography>
            </Box>
            {/* Center Box */}
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                color: "#B0B0B0",
                fontFamily: "Poppins",
                justifyContent: { xs: "center", sm: "flex-start" },
                marginLeft: { xs: "0", sm: "13rem" },
                gap: 2,
                mb: { xs: "1rem", sm: "0" },
              }}
            >
              <IconButton
                color="primary"
                onClick={onToggleCamera}
                sx={{
                  "&:hover": {
                    boxShadow: "none",
                    backgroundColor: "transparent",
                  },
                  "&.Mui-focusVisible": {
                    boxShadow: "none",
                  },
                }}
              >
                {isCamera === false ? (
                  <Box
                    sx={{
                      borderRadius: "50%",
                      width: 33,
                      height: 33,
                      border: "1px solid #1E1F24",
                      backgroundColor: "#1E1F24",
                      overflow: "hidden",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <VideocamOffRoundedIcon
                      style={{ width: 23, height: 23, color: "#818090" }}
                    />
                  </Box>
                ) : (
                  <Box
                    sx={{
                      borderRadius: "50%",
                      width: 33,
                      height: 33,
                      border: "1px solid #1E1F24",
                      backgroundColor: "#1E1F24",
                      overflow: "hidden",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <VideocamRoundedIcon
                      style={{ width: 23, height: 23, color: "#818090" }}
                    />
                  </Box>
                )}
              </IconButton>
              <IconButton
                color="error"
                onClick={() => {
                  dispatch(setCallLogsDetailsDispatch(true));
                  onEndCall();
                }}
                sx={{
                  "&:hover": {
                    boxShadow: "none",
                    backgroundColor: "transparent",
                  },
                  "&.Mui-focusVisible": {
                    boxShadow: "none",
                  },
                }}
              >
                <Box
                  sx={{
                    borderRadius: "50%",
                    width: 33,
                    height: 33,
                    border: "1px solid #FD372A",
                    backgroundColor: "#FD372A",
                    overflow: "hidden",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <CallEndRoundedIcon
                    style={{ width: 23, height: 23, color: "#F9F9F9" }}
                  />
                </Box>
              </IconButton>
              <IconButton
                color="primary"
                onClick={onToggleMute}
                sx={{
                  "&:hover": {
                    boxShadow: "none",
                    backgroundColor: "transparent",
                  },
                  "&.Mui-focusVisible": {
                    boxShadow: "none",
                  },
                }}
              >
                {isMuted === true ? (
                  <Box
                    sx={{
                      borderRadius: "50%",
                      width: 33,
                      height: 33,
                      border: "1px solid #1E1F24",
                      backgroundColor: "#1E1F24",
                      overflow: "hidden",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <MicOffRoundedIcon
                      style={{ width: 23, height: 23, color: "#818090" }}
                    />
                  </Box>
                ) : (
                  <Box
                    sx={{
                      borderRadius: "50%",
                      width: 33,
                      height: 33,
                      border: "1px solid #1E1F24",
                      backgroundColor: "#1E1F24",
                      overflow: "hidden",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <MicRoundedIcon
                      style={{ width: 23, height: 23, color: "#818090" }}
                    />
                  </Box>
                )}
              </IconButton>
              {activeConnection?.message?.Direction == "outbound" && (
                <IconButton
                  onClick={handleDialModalOpen}
                  // color="primary"
                  sx={{
                    "&:hover": {
                      boxShadow: "none",
                      backgroundColor: "transparent",
                    },
                    "&.Mui-focusVisible": {
                      boxShadow: "none",
                    },
                  }}
                >
                  <Box
                    sx={{
                      borderRadius: "50%",
                      width: 33,
                      height: 33,
                      // border: "1px solid #FD372A",
                      backgroundColor: "#ffffff",
                      overflow: "hidden",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <KeyboardHideIcon
                      style={{ width: 23, height: 23, color: "#7D827F" }}
                    />
                  </Box>
                </IconButton>
              )}
            </Box>

            {/* Right Box */}
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                color: "#B0B0B0",
                fontFamily: "Poppins",
                alignItems: "flex-end",
                gap: 2,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  textAlign: "center",
                  justifyContent: "center",
                  color: "#B0B0B0",
                  fontFamily: "Poppins",
                }}
              >
                <IconButton
                  color="primary"
                  onClick={onToggleScreenShare}
                  sx={{
                    "&:hover": {
                      boxShadow: "none",
                      backgroundColor: "transparent",
                    },
                    "&.Mui-focusVisible": {
                      boxShadow: "none",
                    },
                  }}
                  disabled={
                    activeConnection?.message?.Direction == "outbound" ||
                    (isShareScreen?.CallId == user_id &&
                      isShareScreen?.IsStream == true)
                  }
                >
                  <Box
                    sx={{
                      borderRadius: "50%",
                      width: 33,
                      height: 33,
                      border: "1px solid #1E1F24",
                      backgroundColor: "#1E1F24",
                      overflow: "hidden",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    {isSharing ? (
                      <StopCircleIcon />
                    ) : (
                      <img
                        src={shareScreen}
                        alt="Screen Icon"
                        style={{ width: 20, height: 20 }}
                      />
                    )}
                  </Box>
                </IconButton>
                <Typography sx={{ fontSize: "12px" }}>
                  {isSharing ? "Stop" : "Screen Share"}
                </Typography>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  textAlign: "center",
                  justifyContent: "center",
                  color: "#B0B0B0",
                  fontFamily: "Poppins",
                }}
              >
                <IconButton
                  color="primary"
                  sx={{
                    "&:hover": {
                      boxShadow: "none",
                      backgroundColor: "transparent",
                    },
                    "&.Mui-focusVisible": {
                      boxShadow: "none",
                    },
                  }}
                  disabled={activeConnection?.message?.Direction == "outbound"}
                >
                  <Box
                    sx={{
                      borderRadius: "50%",
                      width: 33,
                      height: 33,
                      border: "1px solid #1E1F24",
                      backgroundColor: "#1E1F24",
                      overflow: "hidden",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    onClick={handleAddParticipantOpen}
                  >
                    <img
                      src={AddPeople}
                      alt="Add People"
                      style={{ width: 20, height: 20 }}
                    />
                  </Box>
                </IconButton>
                <Typography sx={{ fontSize: "12px" }}>Add People</Typography>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  textAlign: "center",
                  justifyContent: "center",
                  color: "#B0B0B0",
                  fontFamily: "Poppins",
                }}
              >
                {isRecording === false ? (
                  <>
                    <IconButton
                      color="primary"
                      onClick={onToggleRecording}
                      sx={{
                        "&:hover": {
                          boxShadow: "none",
                          backgroundColor: "transparent",
                        },
                        "&.Mui-focusVisible": {
                          boxShadow: "none",
                        },
                      }}
                    >
                      <Box
                        sx={{
                          borderRadius: "50%",
                          width: 33,
                          height: 33,
                          border: "1px solid #1E1F24",
                          backgroundColor: "#1E1F24",
                          overflow: "hidden",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <img
                          src={record}
                          alt="record"
                          style={{ width: 20, height: 20 }}
                        />
                      </Box>
                    </IconButton>
                    <Typography sx={{ fontSize: "12px" }}>Record</Typography>
                  </>
                ) : (
                  <>
                    <IconButton
                      color="primary"
                      onClick={onToggleRecording}
                      sx={{
                        "&:hover": {
                          boxShadow: "none",
                          backgroundColor: "transparent",
                        },
                        "&.Mui-focusVisible": {
                          boxShadow: "none",
                        },
                      }}
                    >
                      <Box
                        sx={{
                          borderRadius: "50%",
                          width: 33,
                          height: 33,
                          border: "1px solid #1E1F24",
                          backgroundColor: "#1E1F24",
                          overflow: "hidden",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <StopCircleIcon />
                      </Box>
                    </IconButton>
                    <Typography sx={{ fontSize: "12px" }}>Stop</Typography>
                  </>
                )}
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>

      {/* Calling Modal */}
      <Modal
        open={addparticipantModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                width: "100%",
                height: "auto",
                textAlign: "center",
              }}
            >
              <Typography
                variant="h6"
                color={"#085F99"}
                fontWeight={"bold"}
                fontSize={"18px"}
              >
                Add Participant on Call
              </Typography>
            </Box>
            <IconButton
              sx={{ padding: "0", color: "#085F99" }}
              onClick={() => {
                handleAddParticipantClose();
                setSearchParticipant("");
                setCheckedIds([]);
              }}
            >
              <HighlightOffIcon />
            </IconButton>
          </Box>
          <Divider orientation="horizontal" flexItem sx={{ marginY: "10px" }} />

          <Box>
            <TextField
              value={searchParticipant}
              onChange={(e) => {
                setSearchParticipant(e.target.value);
              }}
              variant="outlined"
              fullWidth
              autoComplete="off"
              placeholder="Search Participants..."
              size="small"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon sx={{ color: "#085F99" }} />
                  </InputAdornment>
                ),
              }}
              sx={{
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: "grey",
                    borderRadius: "20px",
                  },
                  "&:hover fieldset": {
                    borderColor: "#085F99",
                    borderRadius: "20px",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "#085F99",
                    borderRadius: "20px",
                  },
                },
              }}
            />
            <Box
              sx={{
                padding: "10px 20px",
                height: "40vh",
                overflowY: "auto",
                mt: 2,
              }}
              onScroll={handleScroll}
            >
              {userContactList?.map((i) => {
                return (
                  <>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          gap: 2,
                          alignItems: "center",
                        }}
                      >
                        <Avatar
                          sx={{
                            fontSize: "14px",
                            fontWeight: "bold",
                            height: "30px",
                            width: "30px",
                            cursor: "pointer",
                          }}
                          alt="Profile"
                          src={
                            i?.profilePic
                              ? `${s.baseUrl}${i.profilePic}`
                              : undefined
                          }
                          loading="lazy"
                        >
                          {!i?.profilePic &&
                            i?.firstName &&
                            i?.firstName?.charAt(0) &&
                            i?.lastName &&
                            i?.lastName?.charAt(0)}
                        </Avatar>

                        <Typography>
                          {i?.firstName + " " + i?.lastName}
                        </Typography>
                      </Box>
                      <Checkbox
                        icon={<RadioButtonUncheckedIcon />}
                        checkedIcon={<CheckCircleIcon />}
                        checked={checkedIds.includes(String(i?.userid))}
                        onChange={() => handleCheckboxChange(String(i?.userid))}
                        sx={{
                          "& .MuiSvgIcon-root": {
                            fontSize: 25,
                            color: "#085F99",
                          },
                        }}
                      />
                    </Box>
                    <Divider
                      orientation="horizontal"
                      flexItem
                      sx={{ margin: "10px 0px" }}
                    />
                  </>
                );
              })}
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Button
                variant="contained"
                size="small"
                onClick={() => {
                  addParticipantMember(checkedIds);
                  handleAddParticipantClose();
                }}
              >
                Add
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>

      {/* Dialpad Model  */}

      <Modal
        open={dialOpen}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <IconButton
              sx={{ padding: "0", color: "#085F99" }}
              onClick={handleCloseDialPad}
            >
              <KeyboardBackspaceIcon />
            </IconButton>
            <Typography
              variant="h6"
              color={"#085F99"}
              fontWeight={"bold"}
              fontSize={"18px"}
            >
              Dial Number
            </Typography>
            <IconButton
              sx={{ padding: "0", color: "#085F99" }}
              onClick={handleCloseDialPad}
            >
              <HighlightOffIcon />
            </IconButton>
          </Box>
          {/* Show Contact Number */}
          {errValidMsg && (
            <Typography
              sx={{
                color: "red",
                fontWeight: "550",
                fontSize: "16px",
                textAlign: "center",
              }}
            >
              {errValidMsg}
            </Typography>
          )}
          <Box padding={"10px"}>
            <TextField
              variant="standard"
              placeholder=""
              value={display}
              onChange={handleInputChange}
              inputRef={inputRef}
              sx={{
                width: "100%",
                borderRadius: "20px",
                "& .MuiOutlinedInput-root": {
                  borderColor: "#BCBCBC",
                },
                "& .MuiInput-underline:before": {
                  borderBottomColor: "#BCBCBC",
                },
                "& .MuiInput-underline:after": {
                  borderBottomColor: "#BCBCBC",
                },
                "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
                  borderBottomColor: "#BCBCBC",
                },

                "& input::placeholder": {
                  color: "gray",
                  opacity: 1,
                },
              }}
            />
          </Box>
          {/* Numbers */}
          <Box sx={{ textAlign: "center" }}>
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "repeat(3, 1fr)",
                gap: "10px",
                padding: "0 10px 0 70px",
              }}
            >
              {numbersArray?.map(({ number, letters }, index) => (
                <Box
                  key={index}
                  onClick={() => handleNumberClick(number)}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    bgcolor: "#e0f3ff",
                    borderRadius: "50%",
                    width: "70px",
                    height: "70px",
                    cursor: "pointer",
                    boxShadow: 2,
                    "&:hover": {
                      bgcolor: number === number ? "#90c0ff" : "#c0e0ff",
                    },
                    outline: "none",
                    userSelect: "none",
                    "&:focus": { outline: "none" },
                  }}
                >
                  <Typography
                    color={"black"}
                    fontWeight={"bold"}
                    fontSize={"24px"}
                  >
                    {number}
                  </Typography>
                  {letters && (
                    <Typography color={"black"} fontSize={"10px"}>
                      {letters}
                    </Typography>
                  )}
                </Box>
              ))}
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-around",
                alignItems: "center",
                padding: "14px 75px 0 100px",
              }}
            >
              <Box
                width={"100%"}
                paddingLeft={"20px"}
                display={"flex"}
                justifyContent={"center"}
              >
                {/* <IconButton
                  sx={{
                    border: "1px solid #DBDBDB",
                    background: "#4ebc4e",
                    color: "white",
                    borderRadius: "50%",
                    width: "60px",
                    height: "60px",
                    cursor: "pointer",
                    boxShadow: 2,
                    fontSize: "35px",
                    "&:hover": { bgcolor: "#4ebc4e" },
                  }}
                  onClick={() => {
                    if (!display.startsWith("+")) {
                      setErrValidMsg(
                        "Please enter a valid number like +1234567..."
                      );
                      return;
                    } else {
                      handleDial(display.toString(), username, "outbound");
                    }
                  }}
                >
                  <CallIcon fontSize="20px" />
                </IconButton> */}
              </Box>
              <IconButton onClick={handleBackspace}>
                <BackspaceOutlinedIcon sx={{ color: "black" }} />
              </IconButton>
            </Box>
          </Box>
        </Box>
      </Modal>
    </>
  );
}

export default CallScreen;
// Call Modal Style
const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: "90%", sm: "80%", md: "60%", lg: "35%", xl: "35%" },
  bgcolor: "background.paper",
  borderRadius: 2,
  border: "2px solid #FFFFFF",
  p: 2,
};

const calculateCallDuration = (endTime, startTime) => {
  const durationInMilliseconds = dayjs(`1970-01-01T${endTime}`).diff(
    dayjs(`1970-01-01T${startTime}`)
  );
  return Math.floor(durationInMilliseconds / 1000);
};
