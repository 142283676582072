import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  realTimeEmailData: null,
  renderNewEmail: false,
  unreadEmail: null,
  renderEmailList: false,
  emailsLength: [],
  openDraftModal: false,
  emptyEmailInputs: false,
  handleSentEmail: false,
  renderDraft: false,
  foldersList: "",
  isDraftEmail: false,
  draftId: "",
  emptyEmails: false,
  realtimeEmailRecieve: [],
  unreadEmailCount: null,
};
const emailSlice = createSlice({
  name: "email",
  initialState,
  reducers: {
    setRealTimeEmailData: (state, action) => {
      state.realTimeEmailData = action.payload;
    },
    setRenderNewEmail: (state, action) => {
      state.renderNewEmail = action.payload;
    },
    setUnreadEmail: (state, action) => {
      state.unreadEmail = action.payload;
    },
    setRenderEmailList: (state, action) => {
      state.renderEmailList = action.payload;
    },
    setEmailsLength: (state, action) => {
      state.emailsLength = action.payload;
    },
    setOpenDraftModal: (state, action) => {
      state.openDraftModal = action.payload;
    },
    setEmptyEmailInputs: (state, action) => {
      state.emptyEmailInputs = action.payload;
    },
    setHandleSentEmail: (state, action) => {
      state.handleSentEmail = action.payload;
    },
    setRenderDraft: (state, action) => {
      state.renderDraft = action.payload;
    },
    setFolderList: (state, action) => {
      state.foldersList = action.payload;
    },
    setIsDraftEmail: (state, action) => {
      state.isDraftEmail = action.payload;
    },
    setDraftId: (state, action) => {
      state.draftId = action.payload;
    },
    setEmptyEmails: (state, action) => {
      state.emptyEmails = action.payload;
    },
    setRealtimeEmailRecieve: (state, action) => {
      state.realtimeEmailRecieve = [
        ...state.realtimeEmailRecieve,
        action.payload,
      ];
    },
    setUnreadEmailCounts: (state, action) => {
      state.unreadEmailCount = action.payload;
    },
  },
});

export const {
  setRealTimeEmailData,
  setRenderNewEmail,
  setUnreadEmail,
  setRenderEmailList,
  setEmailsLength,
  setOpenDraftModal,
  setEmptyEmailInputs,
  setHandleSentEmail,
  setRenderDraft,
  setFolderList,
  setIsDraftEmail,
  setDraftId,
  setEmptyEmails,
  setRealtimeEmailRecieve,
  setUnreadEmailCounts,
} = emailSlice.actions;

export default emailSlice.reducer;
