import { createContext, useEffect, useState, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { refresh_Token } from "../api/apiManager";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const location = useLocation();
  const tokenExpiryTime = localStorage.getItem("token_expiry_time");
  const [accessToken, setAccessToken] = useState(
    localStorage.getItem("access_token") || ""
  );
  const getRefreshToken = async (yourrefreshToken) => {
    try {
      const response = await refresh_Token(yourrefreshToken);
      if (response?.data?.status === 200) {
        const newToken = response?.data?.result?.token;
        const newExpiryTime = response?.data?.result?.expiration;

        // Save updated token and expiry time
        localStorage.setItem("access_token", newToken);
        localStorage.setItem("token_expiry_time", newExpiryTime);
        setAccessToken(newToken);
      }
    } catch (error) {
      console.warn(error);
    }
  };

  const checkTokenExpiry = async () => {
    const refreshToken = localStorage.getItem("refresh-token");
    const tokenExpiryTime = localStorage.getItem("token_expiry_time");
    const currentTime = new Date();

    if (refreshToken && tokenExpiryTime) {
      if (new Date(tokenExpiryTime) <= currentTime) {
        console.log("Token expired. Refreshing...");
        await getRefreshToken(refreshToken);
      } else {
        console.log("Dark-Token is still valid!");
      }
    }
  };
  const handleVisibilityChange = async () => {
    if (!document.hidden) {
      await checkTokenExpiry();
    }
  };

  useEffect(() => {
    const refreshInterval = 8 * 60 * 1000; // 8 minutes interval
    const refreshToken = localStorage.getItem("refresh-token");

    checkTokenExpiry();

    // Set interval for regular token refresh
    const interval = setInterval(() => {
      checkTokenExpiry();
    }, refreshInterval);

    // Handle visibility change for sleep/lock detection
    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      clearInterval(interval);
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);

  return (
    <AuthContext.Provider value={{ accessToken }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
