import React, { useEffect } from "react";
import { Snackbar, Alert, Box, Avatar, Typography } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { hideNotification } from "../../../services/store/slice/NotificationSlice";
import {
  setChat_Id,
  setConvoType,
  setSelectedContactName,
  setSelectedContactProfileImage,
} from "../../../services/store/slice/ChatSlice";
import { settings as s } from "../../../services/Settings";
import addNotification from "react-push-notification";
import avatarImg from "../../../../assets/img/Avatar.png";
import groupImg from "../../../../assets/img/Group.png";
import { SendReadReceipt } from "../../../services/signalRService/signalRService";

const Notification = () => {
  const userId = localStorage.getItem("user_id");
  const dispatch = useDispatch();
  const navigate = useNavigate(); // Hook for navigation
  const { open, message, chatId } = useSelector((state) => state.notification);
  const chat_Id = useSelector((state) => state.chat.chat_Id);

  const handleClose = () => {
    dispatch(hideNotification());
  };

  const handleClick = () => {
    dispatch(hideNotification());
    if (chatId !== null) {
      // localStorage.setItem("chatIdLS", chatId)
      // dispatch(setChat_Id(chatId));
      navigate(`/chats`);
    } else {
      navigate("/chats");
    }
  };
  console.log("check msg=>", message);

  // handle Notification Fun
  const handleNotifyFun = (message) => {
    addNotification({
      title: message?.admin
        ? `${message?.admin?.firstName} ${message?.admin?.lastName} added you to "${message?.name}"`
        : message?.chat?.conversationType === "Group"
        ? `${message?.senderName} in "${message?.chat?.name}"`
        : message?.senderName,
      message:
        message?.attachments?.length > 0
          ? "Attachment"
          : message?.messageContent,
      theme: "dark",
      position: "bottom-left",
      duration: 10000,
      icon: (() => {
        // handle Add Participant Notification
        if (message?.conversationType === "Group") {
          if (message?.picPath) {
            return `${s.baseUrl}${message?.picPath}`;
          }
          return groupImg;
        }

        // Handle group picture -- group conversation
        if (message?.chat?.conversationType === "Group") {
          if (message?.chat?.picPath) {
            return `${s.baseUrl}${message?.chat?.picPath}`;
          }
          return groupImg; // Default to group image if no chat image
        }

        // For sender's profile picture -- single conversation
        if (message?.chat?.conversationType === "Single") {
          if (message?.senderProfilePic) {
            return `${s.baseUrl}${message?.senderProfilePic}`;
          }
          return avatarImg;
        }
      })(),
      native: true,
      onClick: () => {
        if (message?.chat?.conversationType === "Single") {
          dispatch(setChat_Id(chatId));
          dispatch(setSelectedContactName(message?.senderName));
          dispatch(setSelectedContactProfileImage(message?.senderProfilePic));
          dispatch(setConvoType("Single"));
          localStorage.setItem("picPath", message?.senderProfilePic);
          localStorage.setItem("chatId", chatId);
          localStorage.setItem("convoType", "Single");
          localStorage.setItem("selectedContactName", message?.senderName);
          navigate(`/chats`);
        } else if (message?.conversationType === "Group") {
          dispatch(setChat_Id(message?.chatId));
          dispatch(setSelectedContactName(message?.name));
          dispatch(setSelectedContactProfileImage(message?.picPath));
          dispatch(setConvoType("Group"));
          localStorage.setItem("picPath", message?.picPath);
          localStorage.setItem("chatId", message?.chatId);
          localStorage.setItem("convoType", "Group");
          localStorage.setItem("selectedContactName", message?.name);
          navigate(`/chats`);
        } else {
          dispatch(setChat_Id(message?.chat?.chatId));
          dispatch(setSelectedContactName(message?.chat?.name));
          dispatch(setSelectedContactProfileImage(message?.chat?.picPath));
          dispatch(setConvoType("Group"));
          localStorage.setItem("picPath", message?.chat?.picPath);
          localStorage.setItem("chatId", message?.chat?.chatId);
          localStorage.setItem("convoType", "Group");
          localStorage.setItem("selectedContactName", message?.chat?.name);
          navigate(`/chats`);
        }
      },
    });
  };

  const getChatAlert = () => {
    if (open && chat_Id != chatId) {
      handleNotifyFun(message || []);
      dispatch(hideNotification());
    }
  };

  useEffect(() => {
    getChatAlert();
  }, [message?.senderId, chatId, userId, chat_Id]);
  return (
    <Snackbar
      // open={open}
      open={false}
      autoHideDuration={4000}
      onClose={handleClose}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
    >
      <Alert
        onClose={handleClose}
        severity={"info"}
        variant="filled"
        icon={false}
        sx={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          bgcolor: "gray",
          cursor: "pointer",
        }}
      >
        <Box display={"flex"} alignItems={"center"} onClick={handleClick}>
          <Avatar
            src={`${s?.baseUrl}/${message?.senderProfilePic}`}
            alt={message[0]?.senderName}
            sx={{
              marginRight: 2,
              width: { md: "35px", xs: "35px" },
              height: { md: "35px", xs: "35px" },
            }}
          />

          <Box>
            <Typography sx={{ fontSize: "12px" }}>
              {message?.senderName}
            </Typography>
            <Typography sx={{ fontSize: "12px" }}>
              {message?.messageContent?.length > 12
                ? message?.messageContent?.slice(0, 12) + "..."
                : message?.messageContent}
            </Typography>
          </Box>
        </Box>
      </Alert>
    </Snackbar>
  );
};

export default Notification;
